/**
 * Configuration
 */
// Meta tag
const staticMeta = {
  // Open Graph
  OG_TYPE: 'website',

  // Twitter card
  TWITTER_CARD: 'summary_large_image',
  TWITTER_SITE: '@SET_Thailand',
  TWITTER_CREATOR: '@SET_Thailand'
}

// Lazy content
const defaultObserverOption = {
  rootMargin: '0px',
  threshold: 0
}

// Device screen sizes
const deviceSizes = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400
}
// Image sizes
const imageSizes = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1240,
  xxl: 1920
}

const knowledgeTypes = {
  articleDetail: 'ArticleDetail',
  eBookDetail: 'EBookDetail',
  videoDetail: 'VideoDetail',
  podcastDetail: 'PodcastDetail',
  manageInfographic: 'ManageInfographic',
  eLearning: 'ELearning'
}

// Specific page path list
const specificPagePath = [
  // 'home2',
  'error/404',
  'error/500',
  'error/503',
  'error/oldversion',
  'example',
  'event-tracking-report',
  'redirect',
  'authorization',
  'example/advertisement',
  'example/cover-page',
  'example/dropdown',
  'example/tables',
  'example/tabs',
  'preview-form',
  'profile/detail',
  'profile/following-bookmark',
  'search',
  'recently-viewed',
  'technical-chart',
  'get-quote',
  'derivatives/comparison',
  'derivatives/market-data/block-trade',
  'derivatives/market-data/historical-data',
  'derivatives/market-data/investor-type',
  'derivatives/market-data/trading-quotation-by-series',
  'derivatives/overview',
  'derivatives/quote/',
  'derivatives/quote/analyst-consensus',
  'derivatives/quote/research',
  'derivatives/quote/historical-trading',
  'derivatives/quote/overview',
  'derivatives/quote/trading-calendar',
  'derivatives/trading-calendar',
  'economic-data/foreign-exchange-rate',
  'economic-data/interest-rate/deposit',
  'economic-data/interest-rate/loan',
  'economic-data/inflation',
  'economic-data/metal-prices',
  'economic-data/gold-prices',
  'economic-data/oil-prices',
  'equities/company-snapshot',
  'equities/dr',
  // 'equities/dr/knowledge',
  'equities/dr/overview',
  'equities/dr/quote/',
  'equities/dr/quote/factsheet',
  'equities/dr/quote/historical-trading',
  'equities/dr/quote/news',
  'equities/dr/quote/overview',
  'equities/dr/quote/research',
  'equities/dr/quote/rights-benefits',
  'equities/drx',
  // 'equities/drx/knowledge',
  'equities/drx/market-data',
  'equities/drx/overview',
  'equities/drx/quote/',
  'equities/drx/quote/historical-trading',
  'equities/drx/quote/news',
  'equities/drx/quote/overview',
  'equities/drx/quote/research',
  'equities/drx/quote/rights-benefits',
  'equities/drx/screening',
  'equities/dw',
  'equities/dw/calculator',
  'equities/dw/dw-foreign',
  // 'equities/dw/dw-foreign/knowledge',
  'equities/dw/dw-foreign/overview',
  'equities/dw/overview',
  'equities/dw/quote/',
  'equities/dw/quote/factsheet',
  'equities/dw/quote/historical-trading',
  'equities/dw/quote/news',
  'equities/dw/quote/overview',
  'equities/dw/quote/research',
  'equities/dw/screening',
  'equities/dw/statistics-report',
  'equities/etf',
  'equities/etf/overview',
  'equities/etf/quote/',
  'equities/etf/quote/etf-profile/asset-allocation',
  'equities/etf/quote/etf-profile/fund-information',
  'equities/etf/quote/etf-profile/major-shareholders',
  'equities/etf/quote/historical-trading',
  'equities/etf/quote/historical-performance',
  'equities/etf/quote/news',
  'equities/etf/quote/overview',
  'equities/etf/quote/research',
  'equities/etf/quote/rights-benefits',
  'equities/etf/screening',
  'equities/etf/thematic',
  'equities/ipo-corner/ipo-performance/financial',
  'equities/ipo-corner/ipo-performance/financial-advisor-statistics',
  'equities/ipo-corner/ipo-performance/highlight',
  'equities/ipo-corner/ipo-performance/market-statistics',
  'equities/ipo-corner/ipo-performance/price-performance',
  'equities/ipo-corner/ipo-performance/profile',
  'equities/ipo-corner/upcoming-ipo/approved-application',
  'equities/ipo-corner/upcoming-ipo/effective-filing',
  'equities/market-data',
  'equities/market-data/biglot',
  'equities/market-data/historical-report',
  'equities/market-data/historical-report/five-days',
  'equities/market-data/historical-report/investor-type',
  'equities/market-data/nvdr',
  'equities/market-data/nvdr/value',
  'equities/market-data/nvdr/volume',
  'equities/market-data/overview',
  'equities/market-data/shortsell',
  'equities/market-summary',
  'equities/market-summary/overview',
  // 'equities/market-summary/overview2',
  'equities/market-summary/top-ranking/overview',
  'equities/market-summary/top-ranking/most-active-value',
  'equities/market-summary/top-ranking/most-active-volume',
  'equities/market-summary/top-ranking/popular-quote',
  'equities/market-summary/top-ranking/top-dividend-yield',
  'equities/market-summary/top-ranking/top-eps',
  'equities/market-summary/top-ranking/top-gainer',
  'equities/market-summary/top-ranking/top-loser',
  'equities/market-summary/top-ranking/top-net-profit-margin',
  'equities/market-summary/top-ranking/top-pe',
  'equities/market-summary/top-ranking/top-roa',
  'equities/market-summary/top-ranking/top-roe',
  'equities/quote/',
  'equities/quote/analyst-consensus',
  'equities/quote/company-profile',
  'equities/quote/company-profile/board-of-directors',
  'equities/quote/company-profile/major-shareholders',
  'equities/quote/company-profile/profile',
  'equities/quote/company-profile/warrant-info',
  'equities/quote/esg-information',
  'equities/quote/financial-statement',
  'equities/quote/financial-statement/five-years',
  'equities/quote/financial-statement/full',
  'equities/quote/financial-statement/latest',
  'equities/quote/historical-trading',
  'equities/quote/news',
  'equities/quote/overview',
  'equities/quote/ranking',
  'equities/quote/rights-benefits',
  'equities/stock-calendar',
  'equities/stock-comparison',
  'equities/stock-screening',
  'equities/stock-screening/customize',
  'equities/stock-screening/dividend',
  'equities/stock-screening/dividend/customize',
  'equities/stock-screening/dividend/guideline',
  'equities/stock-screening/fundamental',
  'equities/stock-screening/fundamental/customize',
  'equities/stock-screening/fundamental/guideline',
  'equities/stock-screening/growth',
  'equities/stock-screening/growth/customize',
  'equities/stock-screening/growth/guideline',
  'mutualfund',
  'mutualfund/overview',
  'mutualfund/screening',
  'mutualfund/thematic',
  'mutualfund/comparison',
  'mutualfund/quote/',
  'mutualfund/quote/asset-allocation',
  'mutualfund/quote/fee',
  'mutualfund/quote/overview',
  'mutualfund/quote/performance',
  'mutualfund/quote/research',
  'mutualfund/ipo-search',
  'news-and-articles/articles',
  'news-and-articles/articles/main',
  'news-and-articles/news',
  'news-and-articles/news/main',
  'research/analyst-research',
  'research/analyst-research/main',
  'research/iaa-consensus',
  'research/iaa-consensus/main',
  'research/iaa-tfex-consensus',
  'research/iaa-tfex-consensus/main',
  'research/businessanalysis',
  'equities/esg-investment/esg-index',
  'equities/esg-investment/esg-rating',
  'member/my-feed',
  'member/favourite',
  'member/virtual-portfolio',
  'member/backtesting',
  'member/my-profile'
  // 'member/my-profile/edit-profile'
]

export {
  staticMeta,
  defaultObserverOption,
  specificPagePath,
  imageSizes,
  deviceSizes,
  knowledgeTypes
}
