import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4b385702 = () => interopDefault(import('../pages/authorization.vue' /* webpackChunkName: "pages/authorization" */))
const _e6e8cede = () => interopDefault(import('../pages/error/index.vue' /* webpackChunkName: "pages/error/index" */))
const _5c093add = () => interopDefault(import('../pages/event-tracking-report.vue' /* webpackChunkName: "pages/event-tracking-report" */))
const _7ff9c08e = () => interopDefault(import('../pages/get-quote/index.vue' /* webpackChunkName: "pages/get-quote/index" */))
const _6346d9c1 = () => interopDefault(import('../pages/health-check.vue' /* webpackChunkName: "pages/health-check" */))
const _7260f649 = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _693c6ff4 = () => interopDefault(import('../pages/mutualfund/index.vue' /* webpackChunkName: "pages/mutualfund/index" */))
const _da05e3dc = () => interopDefault(import('../pages/recently-viewed/index.vue' /* webpackChunkName: "pages/recently-viewed/index" */))
const _039c8c74 = () => interopDefault(import('../pages/redirect.vue' /* webpackChunkName: "pages/redirect" */))
const _6cfe81d6 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _51ef8212 = () => interopDefault(import('../pages/technical-chart/index.vue' /* webpackChunkName: "pages/technical-chart/index" */))
const _227fb096 = () => interopDefault(import('../pages/derivatives/comparison.vue' /* webpackChunkName: "pages/derivatives/comparison" */))
const _663c3fde = () => interopDefault(import('../pages/derivatives/market-data.vue' /* webpackChunkName: "pages/derivatives/market-data" */))
const _df92061a = () => interopDefault(import('../pages/derivatives/market-data/block-trade.vue' /* webpackChunkName: "pages/derivatives/market-data/block-trade" */))
const _e6c4f8c8 = () => interopDefault(import('../pages/derivatives/market-data/historical-data.vue' /* webpackChunkName: "pages/derivatives/market-data/historical-data" */))
const _2e8e9cde = () => interopDefault(import('../pages/derivatives/market-data/investor-type.vue' /* webpackChunkName: "pages/derivatives/market-data/investor-type" */))
const _43b8b862 = () => interopDefault(import('../pages/derivatives/market-data/trading-quotation-by-series.vue' /* webpackChunkName: "pages/derivatives/market-data/trading-quotation-by-series" */))
const _03669346 = () => interopDefault(import('../pages/derivatives/overview.vue' /* webpackChunkName: "pages/derivatives/overview" */))
const _6cf0a292 = () => interopDefault(import('../pages/derivatives/trading-calendar.vue' /* webpackChunkName: "pages/derivatives/trading-calendar" */))
const _1b7f4109 = () => interopDefault(import('../pages/equities/company-snapshot/index.vue' /* webpackChunkName: "pages/equities/company-snapshot/index" */))
const _33b8c983 = () => interopDefault(import('../pages/equities/dr/index.vue' /* webpackChunkName: "pages/equities/dr/index" */))
const _38091c2b = () => interopDefault(import('../pages/equities/drx/index.vue' /* webpackChunkName: "pages/equities/drx/index" */))
const _1c304748 = () => interopDefault(import('../pages/equities/dw/index.vue' /* webpackChunkName: "pages/equities/dw/index" */))
const _844a4850 = () => interopDefault(import('../pages/equities/etf/index.vue' /* webpackChunkName: "pages/equities/etf/index" */))
const _b7f54c48 = () => interopDefault(import('../pages/equities/market-data/index.vue' /* webpackChunkName: "pages/equities/market-data/index" */))
const _7e61276c = () => interopDefault(import('../pages/equities/market-summary/index.vue' /* webpackChunkName: "pages/equities/market-summary/index" */))
const _882bad6c = () => interopDefault(import('../pages/equities/stock-calendar/index.vue' /* webpackChunkName: "pages/equities/stock-calendar/index" */))
const _51b2ffd6 = () => interopDefault(import('../pages/equities/stock-comparison/index.vue' /* webpackChunkName: "pages/equities/stock-comparison/index" */))
const _199bca4f = () => interopDefault(import('../pages/equities/stock-screening/index.js' /* webpackChunkName: "pages/equities/stock-screening/index" */))
const _4bf81892 = () => interopDefault(import('../pages/error/404.vue' /* webpackChunkName: "pages/error/404" */))
const _0eb1d0f4 = () => interopDefault(import('../pages/error/500.vue' /* webpackChunkName: "pages/error/500" */))
const _0edc1777 = () => interopDefault(import('../pages/error/503.vue' /* webpackChunkName: "pages/error/503" */))
const _3d809dbc = () => interopDefault(import('../pages/error/oldversion.vue' /* webpackChunkName: "pages/error/oldversion" */))
const _3b2642be = () => interopDefault(import('../pages/member/backtesting.vue' /* webpackChunkName: "pages/member/backtesting" */))
const _e1268944 = () => interopDefault(import('../pages/member/favourite.vue' /* webpackChunkName: "pages/member/favourite" */))
const _ab2bcfd8 = () => interopDefault(import('../pages/member/my-feed.vue' /* webpackChunkName: "pages/member/my-feed" */))
const _4266ec23 = () => interopDefault(import('../pages/member/my-profile.vue' /* webpackChunkName: "pages/member/my-profile" */))
const _7c17ac3b = () => interopDefault(import('../pages/member/virtual-portfolio.vue' /* webpackChunkName: "pages/member/virtual-portfolio" */))
const _bcae23f6 = () => interopDefault(import('../pages/mutualfund/comparison.vue' /* webpackChunkName: "pages/mutualfund/comparison" */))
const _0fd92ae9 = () => interopDefault(import('../pages/mutualfund/ipo-search.vue' /* webpackChunkName: "pages/mutualfund/ipo-search" */))
const _5d4e6075 = () => interopDefault(import('../pages/mutualfund/overview.vue' /* webpackChunkName: "pages/mutualfund/overview" */))
const _db51a1ac = () => interopDefault(import('../pages/mutualfund/screening.vue' /* webpackChunkName: "pages/mutualfund/screening" */))
const _401413a5 = () => interopDefault(import('../pages/mutualfund/thematic.vue' /* webpackChunkName: "pages/mutualfund/thematic" */))
const _c55a98e8 = () => interopDefault(import('../pages/news-and-articles/articles/index.vue' /* webpackChunkName: "pages/news-and-articles/articles/index" */))
const _4cdb82a2 = () => interopDefault(import('../pages/news-and-articles/news/index.vue' /* webpackChunkName: "pages/news-and-articles/news/index" */))
const _7a27c183 = () => interopDefault(import('../pages/research/analyst-research/index.vue' /* webpackChunkName: "pages/research/analyst-research/index" */))
const _319c4fd4 = () => interopDefault(import('../pages/research/businessanalysis.vue' /* webpackChunkName: "pages/research/businessanalysis" */))
const _12f18d2c = () => interopDefault(import('../pages/research/iaa-consensus/index.vue' /* webpackChunkName: "pages/research/iaa-consensus/index" */))
const _52c06190 = () => interopDefault(import('../pages/research/iaa-tfex-consensus/index.vue' /* webpackChunkName: "pages/research/iaa-tfex-consensus/index" */))
const _463b534a = () => interopDefault(import('../pages/equities/dr/overview/index.vue' /* webpackChunkName: "pages/equities/dr/overview/index" */))
const _105994b4 = () => interopDefault(import('../pages/equities/drx/market-data.vue' /* webpackChunkName: "pages/equities/drx/market-data" */))
const _fcf143a0 = () => interopDefault(import('../pages/equities/drx/overview.vue' /* webpackChunkName: "pages/equities/drx/overview" */))
const _15842e62 = () => interopDefault(import('../pages/equities/drx/screening.vue' /* webpackChunkName: "pages/equities/drx/screening" */))
const _8d1ffb08 = () => interopDefault(import('../pages/equities/dw/calculator.vue' /* webpackChunkName: "pages/equities/dw/calculator" */))
const _14042097 = () => interopDefault(import('../pages/equities/dw/dw-foreign/index.vue' /* webpackChunkName: "pages/equities/dw/dw-foreign/index" */))
const _28ccfc1a = () => interopDefault(import('../pages/equities/dw/overview.vue' /* webpackChunkName: "pages/equities/dw/overview" */))
const _651f8528 = () => interopDefault(import('../pages/equities/dw/screening.vue' /* webpackChunkName: "pages/equities/dw/screening" */))
const _731c66d8 = () => interopDefault(import('../pages/equities/dw/statistics-report.vue' /* webpackChunkName: "pages/equities/dw/statistics-report" */))
const _08fb8bf0 = () => interopDefault(import('../pages/equities/esg-investment/esg-index.vue' /* webpackChunkName: "pages/equities/esg-investment/esg-index" */))
const _11a3b31a = () => interopDefault(import('../pages/equities/esg-investment/esg-rating/index.vue' /* webpackChunkName: "pages/equities/esg-investment/esg-rating/index" */))
const _3537e363 = () => interopDefault(import('../pages/equities/etf/overview.vue' /* webpackChunkName: "pages/equities/etf/overview" */))
const _90c3ec08 = () => interopDefault(import('../pages/equities/etf/screening.vue' /* webpackChunkName: "pages/equities/etf/screening" */))
const _17fd9693 = () => interopDefault(import('../pages/equities/etf/thematic.vue' /* webpackChunkName: "pages/equities/etf/thematic" */))
const _30ca4192 = () => interopDefault(import('../pages/equities/market-data/biglot.vue' /* webpackChunkName: "pages/equities/market-data/biglot" */))
const _10573721 = () => interopDefault(import('../pages/equities/market-data/historical-report.vue' /* webpackChunkName: "pages/equities/market-data/historical-report" */))
const _edc874b8 = () => interopDefault(import('../pages/equities/market-data/historical-report/five-days.vue' /* webpackChunkName: "pages/equities/market-data/historical-report/five-days" */))
const _5b1a22e1 = () => interopDefault(import('../pages/equities/market-data/historical-report/investor-type.vue' /* webpackChunkName: "pages/equities/market-data/historical-report/investor-type" */))
const _fa271b88 = () => interopDefault(import('../pages/equities/market-data/nvdr.vue' /* webpackChunkName: "pages/equities/market-data/nvdr" */))
const _b6e14644 = () => interopDefault(import('../pages/equities/market-data/nvdr/value.vue' /* webpackChunkName: "pages/equities/market-data/nvdr/value" */))
const _19a76dfd = () => interopDefault(import('../pages/equities/market-data/nvdr/volume.vue' /* webpackChunkName: "pages/equities/market-data/nvdr/volume" */))
const _3fd31242 = () => interopDefault(import('../pages/equities/market-data/overview.vue' /* webpackChunkName: "pages/equities/market-data/overview" */))
const _18c2ffd8 = () => interopDefault(import('../pages/equities/market-data/shortsell.vue' /* webpackChunkName: "pages/equities/market-data/shortsell" */))
const _3e9034e7 = () => interopDefault(import('../pages/member/my-profile1/edit-profile.vue' /* webpackChunkName: "pages/member/my-profile1/edit-profile" */))
const _465ea49c = () => interopDefault(import('../pages/news-and-articles/articles/main/index.vue' /* webpackChunkName: "pages/news-and-articles/articles/main/index" */))
const _02fbdc5c = () => interopDefault(import('../pages/news-and-articles/news/main/index.vue' /* webpackChunkName: "pages/news-and-articles/news/main/index" */))
const _dcce7bca = () => interopDefault(import('../pages/research/analyst-research/main/index.vue' /* webpackChunkName: "pages/research/analyst-research/main/index" */))
const _d2ce4fdc = () => interopDefault(import('../pages/research/iaa-consensus/main/index.vue' /* webpackChunkName: "pages/research/iaa-consensus/main/index" */))
const _7593222e = () => interopDefault(import('../pages/research/iaa-tfex-consensus/main/index.vue' /* webpackChunkName: "pages/research/iaa-tfex-consensus/main/index" */))
const _3ca21b78 = () => interopDefault(import('../pages/equities/dw/dw-foreign/overview.vue' /* webpackChunkName: "pages/equities/dw/dw-foreign/overview" */))
const _7bab2343 = () => interopDefault(import('../pages/equities/dr/quote/_slug/factsheet.js' /* webpackChunkName: "pages/equities/dr/quote/_slug/factsheet" */))
const _f62c7a44 = () => interopDefault(import('../pages/equities/dw/quote/_slug/factsheet.js' /* webpackChunkName: "pages/equities/dw/quote/_slug/factsheet" */))
const _6ffb04d7 = () => interopDefault(import('../pages/equities/dr/quote/_slug/_.js' /* webpackChunkName: "pages/equities/dr/quote/_slug/_" */))
const _3dcfe52f = () => interopDefault(import('../pages/equities/drx/quote/_slug/_.js' /* webpackChunkName: "pages/equities/drx/quote/_slug/_" */))
const _46d46f72 = () => interopDefault(import('../pages/equities/dw/quote/_slug/_.js' /* webpackChunkName: "pages/equities/dw/quote/_slug/_" */))
const _22f6ce3c = () => interopDefault(import('../pages/equities/etf/quote/_slug/_.js' /* webpackChunkName: "pages/equities/etf/quote/_slug/_" */))
const _24154877 = () => interopDefault(import('../pages/equities/ipo-corner/ipo-performance/_.js' /* webpackChunkName: "pages/equities/ipo-corner/ipo-performance/_" */))
const _cc92ef16 = () => interopDefault(import('../pages/equities/ipo-corner/upcoming-ipo/_.js' /* webpackChunkName: "pages/equities/ipo-corner/upcoming-ipo/_" */))
const _4b24e15a = () => interopDefault(import('../pages/news-and-articles/news/_slug.vue' /* webpackChunkName: "pages/news-and-articles/news/_slug" */))
const _7871203b = () => interopDefault(import('../pages/research/analyst-research/_slug.vue' /* webpackChunkName: "pages/research/analyst-research/_slug" */))
const _113aebe4 = () => interopDefault(import('../pages/research/iaa-consensus/_slug.vue' /* webpackChunkName: "pages/research/iaa-consensus/_slug" */))
const _5109c048 = () => interopDefault(import('../pages/research/iaa-tfex-consensus/_slug.vue' /* webpackChunkName: "pages/research/iaa-tfex-consensus/_slug" */))
const _37dd11ec = () => interopDefault(import('../pages/derivatives/quote/_slug/_.vue' /* webpackChunkName: "pages/derivatives/quote/_slug/_" */))
const _2db81e85 = () => interopDefault(import('../pages/equities/quote/_slug/_.vue' /* webpackChunkName: "pages/equities/quote/_slug/_" */))
const _03db8618 = () => interopDefault(import('../pages/mutualfund/quote/_slug/_.js' /* webpackChunkName: "pages/mutualfund/quote/_slug/_" */))
const _4fc3a557 = () => interopDefault(import('../pages/equities/market-summary/_.vue' /* webpackChunkName: "pages/equities/market-summary/_" */))
const _054d1ebc = () => interopDefault(import('../pages/equities/stock-screening/_.js' /* webpackChunkName: "pages/equities/stock-screening/_" */))
const _100365e4 = () => interopDefault(import('../pages/preview-form/_slug.vue' /* webpackChunkName: "pages/preview-form/_slug" */))
const _52b41077 = () => interopDefault(import('../pages/economic-data/_.js' /* webpackChunkName: "pages/economic-data/_" */))
const _77e72944 = () => interopDefault(import('../pages/preview/_.vue' /* webpackChunkName: "pages/preview/_" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _2dfb1658,
    name: "index___en"
  }, {
    path: "/th",
    component: _2dfb1658,
    name: "index___th"
  }, {
    path: "/en/authorization",
    component: _4b385702,
    name: "authorization___en"
  }, {
    path: "/en/error",
    component: _e6e8cede,
    name: "error___en"
  }, {
    path: "/en/event-tracking-report",
    component: _5c093add,
    name: "event-tracking-report___en"
  }, {
    path: "/en/get-quote",
    component: _7ff9c08e,
    name: "get-quote___en"
  }, {
    path: "/en/health-check",
    component: _6346d9c1,
    name: "health-check___en"
  }, {
    path: "/en/home",
    component: _7260f649,
    name: "home___en"
  }, {
    path: "/en/mutualfund",
    component: _693c6ff4,
    name: "mutualfund___en"
  }, {
    path: "/en/recently-viewed",
    component: _da05e3dc,
    name: "recently-viewed___en"
  }, {
    path: "/en/redirect",
    component: _039c8c74,
    name: "redirect___en"
  }, {
    path: "/en/search",
    component: _6cfe81d6,
    name: "search___en"
  }, {
    path: "/en/technical-chart",
    component: _51ef8212,
    name: "technical-chart___en"
  }, {
    path: "/th/authorization",
    component: _4b385702,
    name: "authorization___th"
  }, {
    path: "/th/error",
    component: _e6e8cede,
    name: "error___th"
  }, {
    path: "/th/event-tracking-report",
    component: _5c093add,
    name: "event-tracking-report___th"
  }, {
    path: "/th/get-quote",
    component: _7ff9c08e,
    name: "get-quote___th"
  }, {
    path: "/th/health-check",
    component: _6346d9c1,
    name: "health-check___th"
  }, {
    path: "/th/home",
    component: _7260f649,
    name: "home___th"
  }, {
    path: "/th/mutualfund",
    component: _693c6ff4,
    name: "mutualfund___th"
  }, {
    path: "/th/recently-viewed",
    component: _da05e3dc,
    name: "recently-viewed___th"
  }, {
    path: "/th/redirect",
    component: _039c8c74,
    name: "redirect___th"
  }, {
    path: "/th/search",
    component: _6cfe81d6,
    name: "search___th"
  }, {
    path: "/th/technical-chart",
    component: _51ef8212,
    name: "technical-chart___th"
  }, {
    path: "/en/derivatives/comparison",
    component: _227fb096,
    name: "derivatives-comparison___en"
  }, {
    path: "/en/derivatives/market-data",
    component: _663c3fde,
    name: "derivatives-market-data___en",
    children: [{
      path: "block-trade",
      component: _df92061a,
      name: "derivatives-market-data-block-trade___en"
    }, {
      path: "historical-data",
      component: _e6c4f8c8,
      name: "derivatives-market-data-historical-data___en"
    }, {
      path: "investor-type",
      component: _2e8e9cde,
      name: "derivatives-market-data-investor-type___en"
    }, {
      path: "trading-quotation-by-series",
      component: _43b8b862,
      name: "derivatives-market-data-trading-quotation-by-series___en"
    }]
  }, {
    path: "/en/derivatives/overview",
    component: _03669346,
    name: "derivatives-overview___en"
  }, {
    path: "/en/derivatives/trading-calendar",
    component: _6cf0a292,
    name: "derivatives-trading-calendar___en"
  }, {
    path: "/en/equities/company-snapshot",
    component: _1b7f4109,
    name: "equities-company-snapshot___en"
  }, {
    path: "/en/equities/dr",
    component: _33b8c983,
    name: "equities-dr___en"
  }, {
    path: "/en/equities/drx",
    component: _38091c2b,
    name: "equities-drx___en"
  }, {
    path: "/en/equities/dw",
    component: _1c304748,
    name: "equities-dw___en"
  }, {
    path: "/en/equities/etf",
    component: _844a4850,
    name: "equities-etf___en"
  }, {
    path: "/en/equities/market-data",
    component: _b7f54c48,
    name: "equities-market-data___en"
  }, {
    path: "/en/equities/market-summary",
    component: _7e61276c,
    name: "equities-market-summary___en"
  }, {
    path: "/en/equities/stock-calendar",
    component: _882bad6c,
    name: "equities-stock-calendar___en"
  }, {
    path: "/en/equities/stock-comparison",
    component: _51b2ffd6,
    name: "equities-stock-comparison___en"
  }, {
    path: "/en/equities/stock-screening",
    component: _199bca4f,
    name: "equities-stock-screening___en"
  }, {
    path: "/en/error/404",
    component: _4bf81892,
    name: "error-404___en"
  }, {
    path: "/en/error/500",
    component: _0eb1d0f4,
    name: "error-500___en"
  }, {
    path: "/en/error/503",
    component: _0edc1777,
    name: "error-503___en"
  }, {
    path: "/en/error/oldversion",
    component: _3d809dbc,
    name: "error-oldversion___en"
  }, {
    path: "/en/member/backtesting",
    component: _3b2642be,
    name: "member-backtesting___en"
  }, {
    path: "/en/member/favourite",
    component: _e1268944,
    name: "member-favourite___en"
  }, {
    path: "/en/member/my-feed",
    component: _ab2bcfd8,
    name: "member-my-feed___en"
  }, {
    path: "/en/member/my-profile",
    component: _4266ec23,
    name: "member-my-profile___en"
  }, {
    path: "/en/member/virtual-portfolio",
    component: _7c17ac3b,
    name: "member-virtual-portfolio___en"
  }, {
    path: "/en/mutualfund/comparison",
    component: _bcae23f6,
    name: "mutualfund-comparison___en"
  }, {
    path: "/en/mutualfund/ipo-search",
    component: _0fd92ae9,
    name: "mutualfund-ipo-search___en"
  }, {
    path: "/en/mutualfund/overview",
    component: _5d4e6075,
    name: "mutualfund-overview___en"
  }, {
    path: "/en/mutualfund/screening",
    component: _db51a1ac,
    name: "mutualfund-screening___en"
  }, {
    path: "/en/mutualfund/thematic",
    component: _401413a5,
    name: "mutualfund-thematic___en"
  }, {
    path: "/en/news-and-articles/articles",
    component: _c55a98e8,
    name: "news-and-articles-articles___en"
  }, {
    path: "/en/news-and-articles/news",
    component: _4cdb82a2,
    name: "news-and-articles-news___en"
  }, {
    path: "/en/research/analyst-research",
    component: _7a27c183,
    name: "research-analyst-research___en"
  }, {
    path: "/en/research/businessanalysis",
    component: _319c4fd4,
    name: "research-businessanalysis___en"
  }, {
    path: "/en/research/iaa-consensus",
    component: _12f18d2c,
    name: "research-iaa-consensus___en"
  }, {
    path: "/en/research/iaa-tfex-consensus",
    component: _52c06190,
    name: "research-iaa-tfex-consensus___en"
  }, {
    path: "/th/derivatives/comparison",
    component: _227fb096,
    name: "derivatives-comparison___th"
  }, {
    path: "/th/derivatives/market-data",
    component: _663c3fde,
    name: "derivatives-market-data___th",
    children: [{
      path: "block-trade",
      component: _df92061a,
      name: "derivatives-market-data-block-trade___th"
    }, {
      path: "historical-data",
      component: _e6c4f8c8,
      name: "derivatives-market-data-historical-data___th"
    }, {
      path: "investor-type",
      component: _2e8e9cde,
      name: "derivatives-market-data-investor-type___th"
    }, {
      path: "trading-quotation-by-series",
      component: _43b8b862,
      name: "derivatives-market-data-trading-quotation-by-series___th"
    }]
  }, {
    path: "/th/derivatives/overview",
    component: _03669346,
    name: "derivatives-overview___th"
  }, {
    path: "/th/derivatives/trading-calendar",
    component: _6cf0a292,
    name: "derivatives-trading-calendar___th"
  }, {
    path: "/th/equities/company-snapshot",
    component: _1b7f4109,
    name: "equities-company-snapshot___th"
  }, {
    path: "/th/equities/dr",
    component: _33b8c983,
    name: "equities-dr___th"
  }, {
    path: "/th/equities/drx",
    component: _38091c2b,
    name: "equities-drx___th"
  }, {
    path: "/th/equities/dw",
    component: _1c304748,
    name: "equities-dw___th"
  }, {
    path: "/th/equities/etf",
    component: _844a4850,
    name: "equities-etf___th"
  }, {
    path: "/th/equities/market-data",
    component: _b7f54c48,
    name: "equities-market-data___th"
  }, {
    path: "/th/equities/market-summary",
    component: _7e61276c,
    name: "equities-market-summary___th"
  }, {
    path: "/th/equities/stock-calendar",
    component: _882bad6c,
    name: "equities-stock-calendar___th"
  }, {
    path: "/th/equities/stock-comparison",
    component: _51b2ffd6,
    name: "equities-stock-comparison___th"
  }, {
    path: "/th/equities/stock-screening",
    component: _199bca4f,
    name: "equities-stock-screening___th"
  }, {
    path: "/th/error/404",
    component: _4bf81892,
    name: "error-404___th"
  }, {
    path: "/th/error/500",
    component: _0eb1d0f4,
    name: "error-500___th"
  }, {
    path: "/th/error/503",
    component: _0edc1777,
    name: "error-503___th"
  }, {
    path: "/th/error/oldversion",
    component: _3d809dbc,
    name: "error-oldversion___th"
  }, {
    path: "/th/member/backtesting",
    component: _3b2642be,
    name: "member-backtesting___th"
  }, {
    path: "/th/member/favourite",
    component: _e1268944,
    name: "member-favourite___th"
  }, {
    path: "/th/member/my-feed",
    component: _ab2bcfd8,
    name: "member-my-feed___th"
  }, {
    path: "/th/member/my-profile",
    component: _4266ec23,
    name: "member-my-profile___th"
  }, {
    path: "/th/member/virtual-portfolio",
    component: _7c17ac3b,
    name: "member-virtual-portfolio___th"
  }, {
    path: "/th/mutualfund/comparison",
    component: _bcae23f6,
    name: "mutualfund-comparison___th"
  }, {
    path: "/th/mutualfund/ipo-search",
    component: _0fd92ae9,
    name: "mutualfund-ipo-search___th"
  }, {
    path: "/th/mutualfund/overview",
    component: _5d4e6075,
    name: "mutualfund-overview___th"
  }, {
    path: "/th/mutualfund/screening",
    component: _db51a1ac,
    name: "mutualfund-screening___th"
  }, {
    path: "/th/mutualfund/thematic",
    component: _401413a5,
    name: "mutualfund-thematic___th"
  }, {
    path: "/th/news-and-articles/articles",
    component: _c55a98e8,
    name: "news-and-articles-articles___th"
  }, {
    path: "/th/news-and-articles/news",
    component: _4cdb82a2,
    name: "news-and-articles-news___th"
  }, {
    path: "/th/research/analyst-research",
    component: _7a27c183,
    name: "research-analyst-research___th"
  }, {
    path: "/th/research/businessanalysis",
    component: _319c4fd4,
    name: "research-businessanalysis___th"
  }, {
    path: "/th/research/iaa-consensus",
    component: _12f18d2c,
    name: "research-iaa-consensus___th"
  }, {
    path: "/th/research/iaa-tfex-consensus",
    component: _52c06190,
    name: "research-iaa-tfex-consensus___th"
  }, {
    path: "/en/equities/dr/overview",
    component: _463b534a,
    name: "equities-dr-overview___en"
  }, {
    path: "/en/equities/drx/market-data",
    component: _105994b4,
    name: "equities-drx-market-data___en"
  }, {
    path: "/en/equities/drx/overview",
    component: _fcf143a0,
    name: "equities-drx-overview___en"
  }, {
    path: "/en/equities/drx/screening",
    component: _15842e62,
    name: "equities-drx-screening___en"
  }, {
    path: "/en/equities/dw/calculator",
    component: _8d1ffb08,
    name: "equities-dw-calculator___en"
  }, {
    path: "/en/equities/dw/dw-foreign",
    component: _14042097,
    name: "equities-dw-dw-foreign___en"
  }, {
    path: "/en/equities/dw/overview",
    component: _28ccfc1a,
    name: "equities-dw-overview___en"
  }, {
    path: "/en/equities/dw/screening",
    component: _651f8528,
    name: "equities-dw-screening___en"
  }, {
    path: "/en/equities/dw/statistics-report",
    component: _731c66d8,
    name: "equities-dw-statistics-report___en"
  }, {
    path: "/en/equities/esg-investment/esg-index",
    component: _08fb8bf0,
    name: "equities-esg-investment-esg___en"
  }, {
    path: "/en/equities/esg-investment/esg-rating",
    component: _11a3b31a,
    name: "equities-esg-investment-esg-rating___en"
  }, {
    path: "/en/equities/etf/overview",
    component: _3537e363,
    name: "equities-etf-overview___en"
  }, {
    path: "/en/equities/etf/screening",
    component: _90c3ec08,
    name: "equities-etf-screening___en"
  }, {
    path: "/en/equities/etf/thematic",
    component: _17fd9693,
    name: "equities-etf-thematic___en"
  }, {
    path: "/en/equities/market-data/biglot",
    component: _30ca4192,
    name: "equities-market-data-biglot___en"
  }, {
    path: "/en/equities/market-data/historical-report",
    component: _10573721,
    name: "equities-market-data-historical-report___en",
    children: [{
      path: "five-days",
      component: _edc874b8,
      name: "equities-market-data-historical-report-five-days___en"
    }, {
      path: "investor-type",
      component: _5b1a22e1,
      name: "equities-market-data-historical-report-investor-type___en"
    }]
  }, {
    path: "/en/equities/market-data/nvdr",
    component: _fa271b88,
    name: "equities-market-data-nvdr___en",
    children: [{
      path: "value",
      component: _b6e14644,
      name: "equities-market-data-nvdr-value___en"
    }, {
      path: "volume",
      component: _19a76dfd,
      name: "equities-market-data-nvdr-volume___en"
    }]
  }, {
    path: "/en/equities/market-data/overview",
    component: _3fd31242,
    name: "equities-market-data-overview___en"
  }, {
    path: "/en/equities/market-data/shortsell",
    component: _18c2ffd8,
    name: "equities-market-data-shortsell___en"
  }, {
    path: "/en/member/my-profile1/edit-profile",
    component: _3e9034e7,
    name: "member-my-profile1-edit-profile___en"
  }, {
    path: "/en/news-and-articles/articles/main",
    component: _465ea49c,
    name: "news-and-articles-articles-main___en"
  }, {
    path: "/en/news-and-articles/news/main",
    component: _02fbdc5c,
    name: "news-and-articles-news-main___en"
  }, {
    path: "/en/research/analyst-research/main",
    component: _dcce7bca,
    name: "research-analyst-research-main___en"
  }, {
    path: "/en/research/iaa-consensus/main",
    component: _d2ce4fdc,
    name: "research-iaa-consensus-main___en"
  }, {
    path: "/en/research/iaa-tfex-consensus/main",
    component: _7593222e,
    name: "research-iaa-tfex-consensus-main___en"
  }, {
    path: "/th/equities/dr/overview",
    component: _463b534a,
    name: "equities-dr-overview___th"
  }, {
    path: "/th/equities/drx/market-data",
    component: _105994b4,
    name: "equities-drx-market-data___th"
  }, {
    path: "/th/equities/drx/overview",
    component: _fcf143a0,
    name: "equities-drx-overview___th"
  }, {
    path: "/th/equities/drx/screening",
    component: _15842e62,
    name: "equities-drx-screening___th"
  }, {
    path: "/th/equities/dw/calculator",
    component: _8d1ffb08,
    name: "equities-dw-calculator___th"
  }, {
    path: "/th/equities/dw/dw-foreign",
    component: _14042097,
    name: "equities-dw-dw-foreign___th"
  }, {
    path: "/th/equities/dw/overview",
    component: _28ccfc1a,
    name: "equities-dw-overview___th"
  }, {
    path: "/th/equities/dw/screening",
    component: _651f8528,
    name: "equities-dw-screening___th"
  }, {
    path: "/th/equities/dw/statistics-report",
    component: _731c66d8,
    name: "equities-dw-statistics-report___th"
  }, {
    path: "/th/equities/esg-investment/esg-index",
    component: _08fb8bf0,
    name: "equities-esg-investment-esg___th"
  }, {
    path: "/th/equities/esg-investment/esg-rating",
    component: _11a3b31a,
    name: "equities-esg-investment-esg-rating___th"
  }, {
    path: "/th/equities/etf/overview",
    component: _3537e363,
    name: "equities-etf-overview___th"
  }, {
    path: "/th/equities/etf/screening",
    component: _90c3ec08,
    name: "equities-etf-screening___th"
  }, {
    path: "/th/equities/etf/thematic",
    component: _17fd9693,
    name: "equities-etf-thematic___th"
  }, {
    path: "/th/equities/market-data/biglot",
    component: _30ca4192,
    name: "equities-market-data-biglot___th"
  }, {
    path: "/th/equities/market-data/historical-report",
    component: _10573721,
    name: "equities-market-data-historical-report___th",
    children: [{
      path: "five-days",
      component: _edc874b8,
      name: "equities-market-data-historical-report-five-days___th"
    }, {
      path: "investor-type",
      component: _5b1a22e1,
      name: "equities-market-data-historical-report-investor-type___th"
    }]
  }, {
    path: "/th/equities/market-data/nvdr",
    component: _fa271b88,
    name: "equities-market-data-nvdr___th",
    children: [{
      path: "value",
      component: _b6e14644,
      name: "equities-market-data-nvdr-value___th"
    }, {
      path: "volume",
      component: _19a76dfd,
      name: "equities-market-data-nvdr-volume___th"
    }]
  }, {
    path: "/th/equities/market-data/overview",
    component: _3fd31242,
    name: "equities-market-data-overview___th"
  }, {
    path: "/th/equities/market-data/shortsell",
    component: _18c2ffd8,
    name: "equities-market-data-shortsell___th"
  }, {
    path: "/th/member/my-profile1/edit-profile",
    component: _3e9034e7,
    name: "member-my-profile1-edit-profile___th"
  }, {
    path: "/th/news-and-articles/articles/main",
    component: _465ea49c,
    name: "news-and-articles-articles-main___th"
  }, {
    path: "/th/news-and-articles/news/main",
    component: _02fbdc5c,
    name: "news-and-articles-news-main___th"
  }, {
    path: "/th/research/analyst-research/main",
    component: _dcce7bca,
    name: "research-analyst-research-main___th"
  }, {
    path: "/th/research/iaa-consensus/main",
    component: _d2ce4fdc,
    name: "research-iaa-consensus-main___th"
  }, {
    path: "/th/research/iaa-tfex-consensus/main",
    component: _7593222e,
    name: "research-iaa-tfex-consensus-main___th"
  }, {
    path: "/en/equities/dw/dw-foreign/overview",
    component: _3ca21b78,
    name: "equities-dw-dw-foreign-overview___en"
  }, {
    path: "/th/equities/dw/dw-foreign/overview",
    component: _3ca21b78,
    name: "equities-dw-dw-foreign-overview___th"
  }, {
    path: "/en/equities/dr/quote/:slug?/factsheet",
    component: _7bab2343,
    name: "equities-dr-quote-slug-factsheet___en"
  }, {
    path: "/en/equities/dw/quote/:slug?/factsheet",
    component: _f62c7a44,
    name: "equities-dw-quote-slug-factsheet___en"
  }, {
    path: "/th/equities/dr/quote/:slug?/factsheet",
    component: _7bab2343,
    name: "equities-dr-quote-slug-factsheet___th"
  }, {
    path: "/th/equities/dw/quote/:slug?/factsheet",
    component: _f62c7a44,
    name: "equities-dw-quote-slug-factsheet___th"
  }, {
    path: "/en/equities/dr/quote/:slug?/*",
    component: _6ffb04d7,
    name: "equities-dr-quote-slug-all___en"
  }, {
    path: "/th/equities/dr/quote/:slug?/*",
    component: _6ffb04d7,
    name: "equities-dr-quote-slug-all___th"
  }, {
    path: "/en/equities/drx/quote/:slug?/*",
    component: _3dcfe52f,
    name: "equities-drx-quote-slug-all___en"
  }, {
    path: "/th/equities/drx/quote/:slug?/*",
    component: _3dcfe52f,
    name: "equities-drx-quote-slug-all___th"
  }, {
    path: "/en/equities/dw/quote/:slug?/*",
    component: _46d46f72,
    name: "equities-dw-quote-slug-all___en"
  }, {
    path: "/th/equities/dw/quote/:slug?/*",
    component: _46d46f72,
    name: "equities-dw-quote-slug-all___th"
  }, {
    path: "/en/equities/etf/quote/:slug?/*",
    component: _22f6ce3c,
    name: "equities-etf-quote-slug-all___en"
  }, {
    path: "/th/equities/etf/quote/:slug?/*",
    component: _22f6ce3c,
    name: "equities-etf-quote-slug-all___th"
  }, {
    path: "/en/equities/ipo-corner/ipo-performance/*",
    component: _24154877,
    name: "equities-ipo-corner-ipo-performance-all___en"
  }, {
    path: "/th/equities/ipo-corner/ipo-performance/*",
    component: _24154877,
    name: "equities-ipo-corner-ipo-performance-all___th"
  }, {
    path: "/en/equities/ipo-corner/upcoming-ipo/*",
    component: _cc92ef16,
    name: "equities-ipo-corner-upcoming-ipo-all___en"
  }, {
    path: "/th/equities/ipo-corner/upcoming-ipo/*",
    component: _cc92ef16,
    name: "equities-ipo-corner-upcoming-ipo-all___th"
  }, {
    path: "/en/news-and-articles/news/:slug?",
    component: _4b24e15a,
    name: "news-and-articles-news-slug___en"
  }, {
    path: "/en/research/analyst-research/:slug?",
    component: _7871203b,
    name: "research-analyst-research-slug___en"
  }, {
    path: "/en/research/iaa-consensus/:slug?",
    component: _113aebe4,
    name: "research-iaa-consensus-slug___en"
  }, {
    path: "/en/research/iaa-tfex-consensus/:slug?",
    component: _5109c048,
    name: "research-iaa-tfex-consensus-slug___en"
  }, {
    path: "/th/news-and-articles/news/:slug?",
    component: _4b24e15a,
    name: "news-and-articles-news-slug___th"
  }, {
    path: "/th/research/analyst-research/:slug?",
    component: _7871203b,
    name: "research-analyst-research-slug___th"
  }, {
    path: "/th/research/iaa-consensus/:slug?",
    component: _113aebe4,
    name: "research-iaa-consensus-slug___th"
  }, {
    path: "/th/research/iaa-tfex-consensus/:slug?",
    component: _5109c048,
    name: "research-iaa-tfex-consensus-slug___th"
  }, {
    path: "/en/derivatives/quote/:slug?/*",
    component: _37dd11ec,
    name: "derivatives-quote-slug-all___en"
  }, {
    path: "/th/derivatives/quote/:slug?/*",
    component: _37dd11ec,
    name: "derivatives-quote-slug-all___th"
  }, {
    path: "/en/equities/quote/:slug?/*",
    component: _2db81e85,
    name: "equities-quote-slug-all___en"
  }, {
    path: "/th/equities/quote/:slug?/*",
    component: _2db81e85,
    name: "equities-quote-slug-all___th"
  }, {
    path: "/en/mutualfund/quote/:slug?/*",
    component: _03db8618,
    name: "mutualfund-quote-slug-all___en"
  }, {
    path: "/th/mutualfund/quote/:slug?/*",
    component: _03db8618,
    name: "mutualfund-quote-slug-all___th"
  }, {
    path: "/en/equities/market-summary/*",
    component: _4fc3a557,
    name: "equities-market-summary-all___en"
  }, {
    path: "/th/equities/market-summary/*",
    component: _4fc3a557,
    name: "equities-market-summary-all___th"
  }, {
    path: "/en/equities/stock-screening/*",
    component: _054d1ebc,
    name: "equities-stock-screening-all___en"
  }, {
    path: "/th/equities/stock-screening/*",
    component: _054d1ebc,
    name: "equities-stock-screening-all___th"
  }, {
    path: "/en/preview-form/:slug?",
    component: _100365e4,
    name: "preview-form-slug___en"
  }, {
    path: "/th/preview-form/:slug?",
    component: _100365e4,
    name: "preview-form-slug___th"
  }, {
    path: "/en/economic-data/*",
    component: _52b41077,
    name: "economic-data-all___en"
  }, {
    path: "/th/economic-data/*",
    component: _52b41077,
    name: "economic-data-all___th"
  }, {
    path: "/en/preview/*",
    component: _77e72944,
    name: "preview-all___en"
  }, {
    path: "/th/preview/*",
    component: _77e72944,
    name: "preview-all___th"
  }, {
    path: "/en/*",
    component: _2efaf336,
    name: "all___en"
  }, {
    path: "/th/*",
    component: _2efaf336,
    name: "all___th"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
