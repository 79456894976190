import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  Menu: () => import('../../components/Menu/Menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c)),
  MenuItem: () => import('../../components/Menu/MenuItem.vue' /* webpackChunkName: "components/menu-item" */).then(c => wrapFunctional(c.default || c)),
  TabMenu: () => import('../../components/Menu/TabMenu/TabMenu.vue' /* webpackChunkName: "components/tab-menu" */).then(c => wrapFunctional(c.default || c)),
  TabMenuStatic: () => import('../../components/Menu/TabMenu/TabMenuStatic.vue' /* webpackChunkName: "components/tab-menu-static" */).then(c => wrapFunctional(c.default || c)),
  TabMenuSticky: () => import('../../components/Menu/TabMenu/TabMenuSticky.vue' /* webpackChunkName: "components/tab-menu-sticky" */).then(c => wrapFunctional(c.default || c)),
  CoverTabs: () => import('../../components/NavTabs/CoverTabs.vue' /* webpackChunkName: "components/cover-tabs" */).then(c => wrapFunctional(c.default || c)),
  Tab: () => import('../../components/NavTabs/Tab.vue' /* webpackChunkName: "components/tab" */).then(c => wrapFunctional(c.default || c)),
  TabContent: () => import('../../components/NavTabs/TabContent.vue' /* webpackChunkName: "components/tab-content" */).then(c => wrapFunctional(c.default || c)),
  TabPane: () => import('../../components/NavTabs/TabPane.vue' /* webpackChunkName: "components/tab-pane" */).then(c => wrapFunctional(c.default || c)),
  Tabs: () => import('../../components/NavTabs/Tabs.vue' /* webpackChunkName: "components/tabs" */).then(c => wrapFunctional(c.default || c)),
  Welcome: () => import('../../components/Pages/Welcome.vue' /* webpackChunkName: "components/welcome" */).then(c => wrapFunctional(c.default || c)),
  EconomicData: () => import('../../components/Pages/EconomicData/EconomicData.vue' /* webpackChunkName: "components/economic-data" */).then(c => wrapFunctional(c.default || c)),
  EconomicDataForeignExchangeRate: () => import('../../components/Pages/EconomicData/ForeignExchangeRate.vue' /* webpackChunkName: "components/economic-data-foreign-exchange-rate" */).then(c => wrapFunctional(c.default || c)),
  EconomicDataGoldPrices: () => import('../../components/Pages/EconomicData/GoldPrices.vue' /* webpackChunkName: "components/economic-data-gold-prices" */).then(c => wrapFunctional(c.default || c)),
  EconomicDataInflation: () => import('../../components/Pages/EconomicData/Inflation.vue' /* webpackChunkName: "components/economic-data-inflation" */).then(c => wrapFunctional(c.default || c)),
  EconomicDataMetalPrices: () => import('../../components/Pages/EconomicData/MetalPrices.vue' /* webpackChunkName: "components/economic-data-metal-prices" */).then(c => wrapFunctional(c.default || c)),
  EconomicDataOilPrices: () => import('../../components/Pages/EconomicData/OilPrices.vue' /* webpackChunkName: "components/economic-data-oil-prices" */).then(c => wrapFunctional(c.default || c)),
  EconomicDataTabs: () => import('../../components/Pages/EconomicData/Tabs.vue' /* webpackChunkName: "components/economic-data-tabs" */).then(c => wrapFunctional(c.default || c)),
  Error404: () => import('../../components/Pages/Error/404.vue' /* webpackChunkName: "components/error404" */).then(c => wrapFunctional(c.default || c)),
  ErrorParallel404: () => import('../../components/Pages/Error/Parallel404.vue' /* webpackChunkName: "components/error-parallel404" */).then(c => wrapFunctional(c.default || c)),
  EquityIndicativePrice: () => import('../../components/Pages/Equity/IndicativePrice.vue' /* webpackChunkName: "components/equity-indicative-price" */).then(c => wrapFunctional(c.default || c)),
  FactsheetContent: () => import('../../components/Pages/Factsheet/Content.vue' /* webpackChunkName: "components/factsheet-content" */).then(c => wrapFunctional(c.default || c)),
  FactsheetCover: () => import('../../components/Pages/Factsheet/Cover.vue' /* webpackChunkName: "components/factsheet-cover" */).then(c => wrapFunctional(c.default || c)),
  Factsheet: () => import('../../components/Pages/Factsheet/Factsheet.vue' /* webpackChunkName: "components/factsheet" */).then(c => wrapFunctional(c.default || c)),
  GetQuoteProductTabs: () => import('../../components/Pages/GetQuote/ProductTabs.vue' /* webpackChunkName: "components/get-quote-product-tabs" */).then(c => wrapFunctional(c.default || c)),
  GetQuoteDataTable: () => import('../../components/Pages/GetQuote/QuoteDataTable.vue' /* webpackChunkName: "components/get-quote-data-table" */).then(c => wrapFunctional(c.default || c)),
  GetQuoteSearchBox: () => import('../../components/Pages/GetQuote/SearchBox.vue' /* webpackChunkName: "components/get-quote-search-box" */).then(c => wrapFunctional(c.default || c)),
  GetQuote: () => import('../../components/Pages/GetQuote/index.vue' /* webpackChunkName: "components/get-quote" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryTabs: () => import('../../components/Pages/MarketSummary/Tabs.vue' /* webpackChunkName: "components/market-summary-tabs" */).then(c => wrapFunctional(c.default || c)),
  MarketSummary: () => import('../../components/Pages/MarketSummary/index.vue' /* webpackChunkName: "components/market-summary" */).then(c => wrapFunctional(c.default || c)),
  MutualFundAssetAllocation: () => import('../../components/Pages/MutualFund/AssetAllocation.vue' /* webpackChunkName: "components/mutual-fund-asset-allocation" */).then(c => wrapFunctional(c.default || c)),
  MutualFundCover: () => import('../../components/Pages/MutualFund/Cover.vue' /* webpackChunkName: "components/mutual-fund-cover" */).then(c => wrapFunctional(c.default || c)),
  MutualFundFee: () => import('../../components/Pages/MutualFund/Fee.vue' /* webpackChunkName: "components/mutual-fund-fee" */).then(c => wrapFunctional(c.default || c)),
  MutualFund: () => import('../../components/Pages/MutualFund/MutualFund.vue' /* webpackChunkName: "components/mutual-fund" */).then(c => wrapFunctional(c.default || c)),
  MutualFundOverview: () => import('../../components/Pages/MutualFund/Overview.vue' /* webpackChunkName: "components/mutual-fund-overview" */).then(c => wrapFunctional(c.default || c)),
  MutualFundPerformance: () => import('../../components/Pages/MutualFund/Performance.vue' /* webpackChunkName: "components/mutual-fund-performance" */).then(c => wrapFunctional(c.default || c)),
  MutualFundResearch: () => import('../../components/Pages/MutualFund/Research.vue' /* webpackChunkName: "components/mutual-fund-research" */).then(c => wrapFunctional(c.default || c)),
  HomeContent: () => import('../../components/Pages/Home/Content.vue' /* webpackChunkName: "components/home-content" */).then(c => wrapFunctional(c.default || c)),
  HomeCoverImage: () => import('../../components/Pages/Home/CoverImage.vue' /* webpackChunkName: "components/home-cover-image" */).then(c => wrapFunctional(c.default || c)),
  HomeFTSESET: () => import('../../components/Pages/Home/FTSESET.vue' /* webpackChunkName: "components/home-f-t-s-e-s-e-t" */).then(c => wrapFunctional(c.default || c)),
  Home: () => import('../../components/Pages/Home/Home.vue' /* webpackChunkName: "components/home" */).then(c => wrapFunctional(c.default || c)),
  HomeIndexSet: () => import('../../components/Pages/Home/IndexSet.vue' /* webpackChunkName: "components/home-index-set" */).then(c => wrapFunctional(c.default || c)),
  HomeInvestorsTypeSummary: () => import('../../components/Pages/Home/InvestorsTypeSummary.vue' /* webpackChunkName: "components/home-investors-type-summary" */).then(c => wrapFunctional(c.default || c)),
  HomeMarketSummaryIndex: () => import('../../components/Pages/Home/MarketSummaryIndex.vue' /* webpackChunkName: "components/home-market-summary-index" */).then(c => wrapFunctional(c.default || c)),
  HomeMostActiveVolume: () => import('../../components/Pages/Home/MostActiveVolume.vue' /* webpackChunkName: "components/home-most-active-volume" */).then(c => wrapFunctional(c.default || c)),
  HomeStatisticalData: () => import('../../components/Pages/Home/StatisticalData.vue' /* webpackChunkName: "components/home-statistical-data" */).then(c => wrapFunctional(c.default || c)),
  HomeTriIndex: () => import('../../components/Pages/Home/TriIndex.vue' /* webpackChunkName: "components/home-tri-index" */).then(c => wrapFunctional(c.default || c)),
  RecentlyViewedTabAll: () => import('../../components/Pages/RecentlyViewed/TabAll.vue' /* webpackChunkName: "components/recently-viewed-tab-all" */).then(c => wrapFunctional(c.default || c)),
  RecentlyViewedTabDR: () => import('../../components/Pages/RecentlyViewed/TabDR.vue' /* webpackChunkName: "components/recently-viewed-tab-d-r" */).then(c => wrapFunctional(c.default || c)),
  RecentlyViewedTabDRx: () => import('../../components/Pages/RecentlyViewed/TabDRx.vue' /* webpackChunkName: "components/recently-viewed-tab-d-rx" */).then(c => wrapFunctional(c.default || c)),
  RecentlyViewedTabDW: () => import('../../components/Pages/RecentlyViewed/TabDW.vue' /* webpackChunkName: "components/recently-viewed-tab-d-w" */).then(c => wrapFunctional(c.default || c)),
  RecentlyViewedTabETF: () => import('../../components/Pages/RecentlyViewed/TabETF.vue' /* webpackChunkName: "components/recently-viewed-tab-e-t-f" */).then(c => wrapFunctional(c.default || c)),
  RecentlyViewedTabFund: () => import('../../components/Pages/RecentlyViewed/TabFund.vue' /* webpackChunkName: "components/recently-viewed-tab-fund" */).then(c => wrapFunctional(c.default || c)),
  RecentlyViewedTabFuture: () => import('../../components/Pages/RecentlyViewed/TabFuture.vue' /* webpackChunkName: "components/recently-viewed-tab-future" */).then(c => wrapFunctional(c.default || c)),
  RecentlyViewedTabOption: () => import('../../components/Pages/RecentlyViewed/TabOption.vue' /* webpackChunkName: "components/recently-viewed-tab-option" */).then(c => wrapFunctional(c.default || c)),
  RecentlyViewedTabStock: () => import('../../components/Pages/RecentlyViewed/TabStock.vue' /* webpackChunkName: "components/recently-viewed-tab-stock" */).then(c => wrapFunctional(c.default || c)),
  RecentlyViewedTabUnittrust: () => import('../../components/Pages/RecentlyViewed/TabUnittrust.vue' /* webpackChunkName: "components/recently-viewed-tab-unittrust" */).then(c => wrapFunctional(c.default || c)),
  RecentlyViewed: () => import('../../components/Pages/RecentlyViewed/index.vue' /* webpackChunkName: "components/recently-viewed" */).then(c => wrapFunctional(c.default || c)),
  TechnicalChartPlaceholder: () => import('../../components/Pages/TechnicalChart/Placeholder.vue' /* webpackChunkName: "components/technical-chart-placeholder" */).then(c => wrapFunctional(c.default || c)),
  SearchInputBox: () => import('../../components/Pages/search/InputBox.vue' /* webpackChunkName: "components/search-input-box" */).then(c => wrapFunctional(c.default || c)),
  SearchNoData: () => import('../../components/Pages/search/NoData.vue' /* webpackChunkName: "components/search-no-data" */).then(c => wrapFunctional(c.default || c)),
  SearchTabAnalysis: () => import('../../components/Pages/search/TabAnalysis.vue' /* webpackChunkName: "components/search-tab-analysis" */).then(c => wrapFunctional(c.default || c)),
  SearchTabArticle: () => import('../../components/Pages/search/TabArticle.vue' /* webpackChunkName: "components/search-tab-article" */).then(c => wrapFunctional(c.default || c)),
  SearchTabContent: () => import('../../components/Pages/search/TabContent.vue' /* webpackChunkName: "components/search-tab-content" */).then(c => wrapFunctional(c.default || c)),
  SearchTabNews: () => import('../../components/Pages/search/TabNews.vue' /* webpackChunkName: "components/search-tab-news" */).then(c => wrapFunctional(c.default || c)),
  SearchTabOverview: () => import('../../components/Pages/search/TabOverview.vue' /* webpackChunkName: "components/search-tab-overview" */).then(c => wrapFunctional(c.default || c)),
  SearchTabStock: () => import('../../components/Pages/search/TabStock.vue' /* webpackChunkName: "components/search-tab-stock" */).then(c => wrapFunctional(c.default || c)),
  Search: () => import('../../components/Pages/search/index.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c)),
  EconomicDataInterestRateDeposit: () => import('../../components/Pages/EconomicData/InterestRate/Deposit.vue' /* webpackChunkName: "components/economic-data-interest-rate-deposit" */).then(c => wrapFunctional(c.default || c)),
  EconomicDataInterestRate: () => import('../../components/Pages/EconomicData/InterestRate/InterestRate.vue' /* webpackChunkName: "components/economic-data-interest-rate" */).then(c => wrapFunctional(c.default || c)),
  EconomicDataInterestRateLoan: () => import('../../components/Pages/EconomicData/InterestRate/Loan.vue' /* webpackChunkName: "components/economic-data-interest-rate-loan" */).then(c => wrapFunctional(c.default || c)),
  EconomicDataInterestRateTabs: () => import('../../components/Pages/EconomicData/InterestRate/Tabs.vue' /* webpackChunkName: "components/economic-data-interest-rate-tabs" */).then(c => wrapFunctional(c.default || c)),
  DerivativeAgriculture: () => import('../../components/Pages/Derivative/Agriculture/Agriculture.vue' /* webpackChunkName: "components/derivative-agriculture" */).then(c => wrapFunctional(c.default || c)),
  DerivativeAgricultureAnalystConcensus: () => import('../../components/Pages/Derivative/Agriculture/AnalystConcensus.vue' /* webpackChunkName: "components/derivative-agriculture-analyst-concensus" */).then(c => wrapFunctional(c.default || c)),
  DerivativeAgricultureCover: () => import('../../components/Pages/Derivative/Agriculture/Cover.vue' /* webpackChunkName: "components/derivative-agriculture-cover" */).then(c => wrapFunctional(c.default || c)),
  DerivativeAgricultureHistorical: () => import('../../components/Pages/Derivative/Agriculture/Historical.vue' /* webpackChunkName: "components/derivative-agriculture-historical" */).then(c => wrapFunctional(c.default || c)),
  DerivativeAgricultureModalUnderlyingInstrument: () => import('../../components/Pages/Derivative/Agriculture/ModalUnderlyingInstrument.vue' /* webpackChunkName: "components/derivative-agriculture-modal-underlying-instrument" */).then(c => wrapFunctional(c.default || c)),
  DerivativeAgricultureOverview: () => import('../../components/Pages/Derivative/Agriculture/Overview.vue' /* webpackChunkName: "components/derivative-agriculture-overview" */).then(c => wrapFunctional(c.default || c)),
  DerivativeAgricultureTradingCalendar: () => import('../../components/Pages/Derivative/Agriculture/TradingCalendar.vue' /* webpackChunkName: "components/derivative-agriculture-trading-calendar" */).then(c => wrapFunctional(c.default || c)),
  DerivativeCurrencyAnalystConcensus: () => import('../../components/Pages/Derivative/Currency/AnalystConcensus.vue' /* webpackChunkName: "components/derivative-currency-analyst-concensus" */).then(c => wrapFunctional(c.default || c)),
  DerivativeCurrencyCover: () => import('../../components/Pages/Derivative/Currency/Cover.vue' /* webpackChunkName: "components/derivative-currency-cover" */).then(c => wrapFunctional(c.default || c)),
  DerivativeCurrency: () => import('../../components/Pages/Derivative/Currency/Currency.vue' /* webpackChunkName: "components/derivative-currency" */).then(c => wrapFunctional(c.default || c)),
  DerivativeCurrencyHistorical: () => import('../../components/Pages/Derivative/Currency/Historical.vue' /* webpackChunkName: "components/derivative-currency-historical" */).then(c => wrapFunctional(c.default || c)),
  DerivativeCurrencyModalUnderlyingInstrument: () => import('../../components/Pages/Derivative/Currency/ModalUnderlyingInstrument.vue' /* webpackChunkName: "components/derivative-currency-modal-underlying-instrument" */).then(c => wrapFunctional(c.default || c)),
  DerivativeCurrencyOverview: () => import('../../components/Pages/Derivative/Currency/Overview.vue' /* webpackChunkName: "components/derivative-currency-overview" */).then(c => wrapFunctional(c.default || c)),
  DerivativeCurrencyTradingCalendar: () => import('../../components/Pages/Derivative/Currency/TradingCalendar.vue' /* webpackChunkName: "components/derivative-currency-trading-calendar" */).then(c => wrapFunctional(c.default || c)),
  DerivativeInterestRateAnalystConcensus: () => import('../../components/Pages/Derivative/InterestRate/AnalystConcensus.vue' /* webpackChunkName: "components/derivative-interest-rate-analyst-concensus" */).then(c => wrapFunctional(c.default || c)),
  DerivativeInterestRateCover: () => import('../../components/Pages/Derivative/InterestRate/Cover.vue' /* webpackChunkName: "components/derivative-interest-rate-cover" */).then(c => wrapFunctional(c.default || c)),
  DerivativeInterestRateHistorical: () => import('../../components/Pages/Derivative/InterestRate/Historical.vue' /* webpackChunkName: "components/derivative-interest-rate-historical" */).then(c => wrapFunctional(c.default || c)),
  DerivativeInterestRate: () => import('../../components/Pages/Derivative/InterestRate/InterestRate.vue' /* webpackChunkName: "components/derivative-interest-rate" */).then(c => wrapFunctional(c.default || c)),
  DerivativeInterestRateModalUnderlyingInstrument: () => import('../../components/Pages/Derivative/InterestRate/ModalUnderlyingInstrument.vue' /* webpackChunkName: "components/derivative-interest-rate-modal-underlying-instrument" */).then(c => wrapFunctional(c.default || c)),
  DerivativeInterestRateOverview: () => import('../../components/Pages/Derivative/InterestRate/Overview.vue' /* webpackChunkName: "components/derivative-interest-rate-overview" */).then(c => wrapFunctional(c.default || c)),
  DerivativeInterestRateTradingCalendar: () => import('../../components/Pages/Derivative/InterestRate/TradingCalendar.vue' /* webpackChunkName: "components/derivative-interest-rate-trading-calendar" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewCover: () => import('../../components/Pages/Derivative/Overview/Cover.vue' /* webpackChunkName: "components/derivative-overview-cover" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewTabs: () => import('../../components/Pages/Derivative/Overview/Tabs.vue' /* webpackChunkName: "components/derivative-overview-tabs" */).then(c => wrapFunctional(c.default || c)),
  DerivativePreciousMetalAnalystConcensus: () => import('../../components/Pages/Derivative/PreciousMetal/AnalystConcensus.vue' /* webpackChunkName: "components/derivative-precious-metal-analyst-concensus" */).then(c => wrapFunctional(c.default || c)),
  DerivativePreciousMetalCover: () => import('../../components/Pages/Derivative/PreciousMetal/Cover.vue' /* webpackChunkName: "components/derivative-precious-metal-cover" */).then(c => wrapFunctional(c.default || c)),
  DerivativePreciousMetalHistorical: () => import('../../components/Pages/Derivative/PreciousMetal/Historical.vue' /* webpackChunkName: "components/derivative-precious-metal-historical" */).then(c => wrapFunctional(c.default || c)),
  DerivativePreciousMetalModalUnderlyingInstrument: () => import('../../components/Pages/Derivative/PreciousMetal/ModalUnderlyingInstrument.vue' /* webpackChunkName: "components/derivative-precious-metal-modal-underlying-instrument" */).then(c => wrapFunctional(c.default || c)),
  DerivativePreciousMetalOverview: () => import('../../components/Pages/Derivative/PreciousMetal/Overview.vue' /* webpackChunkName: "components/derivative-precious-metal-overview" */).then(c => wrapFunctional(c.default || c)),
  DerivativePreciousMetal: () => import('../../components/Pages/Derivative/PreciousMetal/PreciousMetal.vue' /* webpackChunkName: "components/derivative-precious-metal" */).then(c => wrapFunctional(c.default || c)),
  DerivativePreciousMetalTradingCalendar: () => import('../../components/Pages/Derivative/PreciousMetal/TradingCalendar.vue' /* webpackChunkName: "components/derivative-precious-metal-trading-calendar" */).then(c => wrapFunctional(c.default || c)),
  EquityCompanySnapshotSearchCard: () => import('../../components/Pages/Equity/CompanySnapshot/SearchCard.vue' /* webpackChunkName: "components/equity-company-snapshot-search-card" */).then(c => wrapFunctional(c.default || c)),
  EquityCompanySnapshotTable: () => import('../../components/Pages/Equity/CompanySnapshot/Table.vue' /* webpackChunkName: "components/equity-company-snapshot-table" */).then(c => wrapFunctional(c.default || c)),
  EquityCompanySnapshot: () => import('../../components/Pages/Equity/CompanySnapshot/index.vue' /* webpackChunkName: "components/equity-company-snapshot" */).then(c => wrapFunctional(c.default || c)),
  EquityDRBenefit: () => import('../../components/Pages/Equity/DR/Benefit.vue' /* webpackChunkName: "components/equity-d-r-benefit" */).then(c => wrapFunctional(c.default || c)),
  EquityDRCover: () => import('../../components/Pages/Equity/DR/Cover.vue' /* webpackChunkName: "components/equity-d-r-cover" */).then(c => wrapFunctional(c.default || c)),
  EquityDR: () => import('../../components/Pages/Equity/DR/DR.vue' /* webpackChunkName: "components/equity-d-r" */).then(c => wrapFunctional(c.default || c)),
  EquityDRHistorical: () => import('../../components/Pages/Equity/DR/Historical.vue' /* webpackChunkName: "components/equity-d-r-historical" */).then(c => wrapFunctional(c.default || c)),
  EquityDRNews: () => import('../../components/Pages/Equity/DR/News.vue' /* webpackChunkName: "components/equity-d-r-news" */).then(c => wrapFunctional(c.default || c)),
  EquityDROverview: () => import('../../components/Pages/Equity/DR/Overview.vue' /* webpackChunkName: "components/equity-d-r-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityDRResearch: () => import('../../components/Pages/Equity/DR/Research.vue' /* webpackChunkName: "components/equity-d-r-research" */).then(c => wrapFunctional(c.default || c)),
  EquityDRTradingViewWidget: () => import('../../components/Pages/Equity/DR/TradingViewWidget.vue' /* webpackChunkName: "components/equity-d-r-trading-view-widget" */).then(c => wrapFunctional(c.default || c)),
  EquityDRXBenefit: () => import('../../components/Pages/Equity/DRX/Benefit.vue' /* webpackChunkName: "components/equity-d-r-x-benefit" */).then(c => wrapFunctional(c.default || c)),
  EquityDRXCover: () => import('../../components/Pages/Equity/DRX/Cover.vue' /* webpackChunkName: "components/equity-d-r-x-cover" */).then(c => wrapFunctional(c.default || c)),
  EquityDRX: () => import('../../components/Pages/Equity/DRX/DRX.vue' /* webpackChunkName: "components/equity-d-r-x" */).then(c => wrapFunctional(c.default || c)),
  EquityDRXHistorical: () => import('../../components/Pages/Equity/DRX/Historical.vue' /* webpackChunkName: "components/equity-d-r-x-historical" */).then(c => wrapFunctional(c.default || c)),
  EquityDRXNews: () => import('../../components/Pages/Equity/DRX/News.vue' /* webpackChunkName: "components/equity-d-r-x-news" */).then(c => wrapFunctional(c.default || c)),
  EquityDRXOverview: () => import('../../components/Pages/Equity/DRX/Overview.vue' /* webpackChunkName: "components/equity-d-r-x-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityDRXResearch: () => import('../../components/Pages/Equity/DRX/Research.vue' /* webpackChunkName: "components/equity-d-r-x-research" */).then(c => wrapFunctional(c.default || c)),
  EquityDWCover: () => import('../../components/Pages/Equity/DW/Cover.vue' /* webpackChunkName: "components/equity-d-w-cover" */).then(c => wrapFunctional(c.default || c)),
  EquityDW: () => import('../../components/Pages/Equity/DW/DW.vue' /* webpackChunkName: "components/equity-d-w" */).then(c => wrapFunctional(c.default || c)),
  EquityDWHistorical: () => import('../../components/Pages/Equity/DW/Historical.vue' /* webpackChunkName: "components/equity-d-w-historical" */).then(c => wrapFunctional(c.default || c)),
  EquityDWNews: () => import('../../components/Pages/Equity/DW/News.vue' /* webpackChunkName: "components/equity-d-w-news" */).then(c => wrapFunctional(c.default || c)),
  EquityDWOverview: () => import('../../components/Pages/Equity/DW/Overview.vue' /* webpackChunkName: "components/equity-d-w-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityDWResearch: () => import('../../components/Pages/Equity/DW/Research.vue' /* webpackChunkName: "components/equity-d-w-research" */).then(c => wrapFunctional(c.default || c)),
  EquityETFBenefit: () => import('../../components/Pages/Equity/ETF/Benefit.vue' /* webpackChunkName: "components/equity-e-t-f-benefit" */).then(c => wrapFunctional(c.default || c)),
  EquityETFCover: () => import('../../components/Pages/Equity/ETF/Cover.vue' /* webpackChunkName: "components/equity-e-t-f-cover" */).then(c => wrapFunctional(c.default || c)),
  EquityETF: () => import('../../components/Pages/Equity/ETF/ETF.vue' /* webpackChunkName: "components/equity-e-t-f" */).then(c => wrapFunctional(c.default || c)),
  EquityETFHistorical: () => import('../../components/Pages/Equity/ETF/Historical.vue' /* webpackChunkName: "components/equity-e-t-f-historical" */).then(c => wrapFunctional(c.default || c)),
  EquityETFNews: () => import('../../components/Pages/Equity/ETF/News.vue' /* webpackChunkName: "components/equity-e-t-f-news" */).then(c => wrapFunctional(c.default || c)),
  EquityETFOverview: () => import('../../components/Pages/Equity/ETF/Overview.vue' /* webpackChunkName: "components/equity-e-t-f-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityETFResearch: () => import('../../components/Pages/Equity/ETF/Research.vue' /* webpackChunkName: "components/equity-e-t-f-research" */).then(c => wrapFunctional(c.default || c)),
  EquityEsgInvestmentCover: () => import('../../components/Pages/Equity/EsgInvestment/Cover.vue' /* webpackChunkName: "components/equity-esg-investment-cover" */).then(c => wrapFunctional(c.default || c)),
  EquityEsgIndex: () => import('../../components/Pages/Equity/EsgInvestment/EsgIndex.vue' /* webpackChunkName: "components/equity-esg-index" */).then(c => wrapFunctional(c.default || c)),
  EquityEsgRating: () => import('../../components/Pages/Equity/EsgInvestment/EsgRating.vue' /* webpackChunkName: "components/equity-esg-rating" */).then(c => wrapFunctional(c.default || c)),
  EquityEsgInvestmentMenu: () => import('../../components/Pages/Equity/EsgInvestment/Menu.vue' /* webpackChunkName: "components/equity-esg-investment-menu" */).then(c => wrapFunctional(c.default || c)),
  EquityIPOCover: () => import('../../components/Pages/Equity/IPO/Cover.vue' /* webpackChunkName: "components/equity-i-p-o-cover" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataBigLot: () => import('../../components/Pages/Equity/MarketData/BigLot.vue' /* webpackChunkName: "components/equity-market-data-big-lot" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataShortSell: () => import('../../components/Pages/Equity/MarketData/ShortSell.vue' /* webpackChunkName: "components/equity-market-data-short-sell" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataStockComparison: () => import('../../components/Pages/Equity/MarketData/StockComparison.vue' /* webpackChunkName: "components/equity-market-data-stock-comparison" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCalendarBenefitsCollapse: () => import('../../components/Pages/Equity/StockCalendar/BenefitsCollapse.vue' /* webpackChunkName: "components/equity-stock-calendar-benefits-collapse" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCalendarXBadge: () => import('../../components/Pages/Equity/StockCalendar/XBadge.vue' /* webpackChunkName: "components/equity-stock-calendar-x-badge" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCalendar: () => import('../../components/Pages/Equity/StockCalendar/index.vue' /* webpackChunkName: "components/equity-stock-calendar" */).then(c => wrapFunctional(c.default || c)),
  EquityStockComparisonChartPricePerformance: () => import('../../components/Pages/Equity/StockComparison/ChartPricePerformance.vue' /* webpackChunkName: "components/equity-stock-comparison-chart-price-performance" */).then(c => wrapFunctional(c.default || c)),
  EquityStockComparisonDropdownSearch: () => import('../../components/Pages/Equity/StockComparison/DropdownSearch.vue' /* webpackChunkName: "components/equity-stock-comparison-dropdown-search" */).then(c => wrapFunctional(c.default || c)),
  EquityStockComparison: () => import('../../components/Pages/Equity/StockComparison/index.vue' /* webpackChunkName: "components/equity-stock-comparison" */).then(c => wrapFunctional(c.default || c)),
  EquityStockScreeningByDividendYield: () => import('../../components/Pages/Equity/StockScreening/ByDividendYield.vue' /* webpackChunkName: "components/equity-stock-screening-by-dividend-yield" */).then(c => wrapFunctional(c.default || c)),
  EquityStockScreeningByFundamental: () => import('../../components/Pages/Equity/StockScreening/ByFundamental.vue' /* webpackChunkName: "components/equity-stock-screening-by-fundamental" */).then(c => wrapFunctional(c.default || c)),
  EquityStockScreeningByGrowth: () => import('../../components/Pages/Equity/StockScreening/ByGrowth.vue' /* webpackChunkName: "components/equity-stock-screening-by-growth" */).then(c => wrapFunctional(c.default || c)),
  EquityStockScreeningCustomize: () => import('../../components/Pages/Equity/StockScreening/Customize.vue' /* webpackChunkName: "components/equity-stock-screening-customize" */).then(c => wrapFunctional(c.default || c)),
  EquityStockScreening: () => import('../../components/Pages/Equity/StockScreening/Index.vue' /* webpackChunkName: "components/equity-stock-screening" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryOverView: () => import('../../components/Pages/MarketSummary/OverView/index.vue' /* webpackChunkName: "components/market-summary-over-view" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryRanking: () => import('../../components/Pages/MarketSummary/Ranking/index.vue' /* webpackChunkName: "components/market-summary-ranking" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryRankingTable: () => import('../../components/Pages/MarketSummary/Ranking/table.vue' /* webpackChunkName: "components/market-summary-ranking-table" */).then(c => wrapFunctional(c.default || c)),
  MemberBacktesting: () => import('../../components/Pages/Member/Backtesting/Backtesting.vue' /* webpackChunkName: "components/member-backtesting" */).then(c => wrapFunctional(c.default || c)),
  MemberBacktestingCover: () => import('../../components/Pages/Member/Backtesting/Cover.vue' /* webpackChunkName: "components/member-backtesting-cover" */).then(c => wrapFunctional(c.default || c)),
  MemberBacktestingMain: () => import('../../components/Pages/Member/Backtesting/Main.vue' /* webpackChunkName: "components/member-backtesting-main" */).then(c => wrapFunctional(c.default || c)),
  MemberFavouriteAddFavourite: () => import('../../components/Pages/Member/Favourite/AddFavourite.vue' /* webpackChunkName: "components/member-favourite-add-favourite" */).then(c => wrapFunctional(c.default || c)),
  MemberFavouriteCount: () => import('../../components/Pages/Member/Favourite/Count.vue' /* webpackChunkName: "components/member-favourite-count" */).then(c => wrapFunctional(c.default || c)),
  MemberFavourite: () => import('../../components/Pages/Member/Favourite/Favourite.vue' /* webpackChunkName: "components/member-favourite" */).then(c => wrapFunctional(c.default || c)),
  MemberMyFeedAnalysis: () => import('../../components/Pages/Member/MyFeed/Analysis.vue' /* webpackChunkName: "components/member-my-feed-analysis" */).then(c => wrapFunctional(c.default || c)),
  MemberMyFeedArticles: () => import('../../components/Pages/Member/MyFeed/Articles.vue' /* webpackChunkName: "components/member-my-feed-articles" */).then(c => wrapFunctional(c.default || c)),
  MemberMyFeedBenefit: () => import('../../components/Pages/Member/MyFeed/Benefit.vue' /* webpackChunkName: "components/member-my-feed-benefit" */).then(c => wrapFunctional(c.default || c)),
  MemberMyFeedEmpty: () => import('../../components/Pages/Member/MyFeed/Empty.vue' /* webpackChunkName: "components/member-my-feed-empty" */).then(c => wrapFunctional(c.default || c)),
  MemberMyFeed: () => import('../../components/Pages/Member/MyFeed/MyFeed.vue' /* webpackChunkName: "components/member-my-feed" */).then(c => wrapFunctional(c.default || c)),
  MemberMyFeedNews: () => import('../../components/Pages/Member/MyFeed/News.vue' /* webpackChunkName: "components/member-my-feed-news" */).then(c => wrapFunctional(c.default || c)),
  MemberMyFeedOpportunityDay: () => import('../../components/Pages/Member/MyFeed/OpportunityDay.vue' /* webpackChunkName: "components/member-my-feed-opportunity-day" */).then(c => wrapFunctional(c.default || c)),
  MemberMyFeedSecurities: () => import('../../components/Pages/Member/MyFeed/Securities.vue' /* webpackChunkName: "components/member-my-feed-securities" */).then(c => wrapFunctional(c.default || c)),
  MemberMyFeedUpcomingIPO: () => import('../../components/Pages/Member/MyFeed/UpcomingIPO.vue' /* webpackChunkName: "components/member-my-feed-upcoming-i-p-o" */).then(c => wrapFunctional(c.default || c)),
  MemberMyProfileEditProfile: () => import('../../components/Pages/Member/MyProfile/EditProfile.vue' /* webpackChunkName: "components/member-my-profile-edit-profile" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolio: () => import('../../components/Pages/Member/VirtualPortfolio/VirtualPortfolio.vue' /* webpackChunkName: "components/member-virtual-portfolio" */).then(c => wrapFunctional(c.default || c)),
  NewsAndArticlesArticlesContent: () => import('../../components/Pages/NewsAndArticles/Articles/Content.vue' /* webpackChunkName: "components/news-and-articles-articles-content" */).then(c => wrapFunctional(c.default || c)),
  NewsAndArticlesArticlesCover: () => import('../../components/Pages/NewsAndArticles/Articles/Cover.vue' /* webpackChunkName: "components/news-and-articles-articles-cover" */).then(c => wrapFunctional(c.default || c)),
  NewsAndArticlesArticlesLastUpdate: () => import('../../components/Pages/NewsAndArticles/Articles/LastUpdate.vue' /* webpackChunkName: "components/news-and-articles-articles-last-update" */).then(c => wrapFunctional(c.default || c)),
  NewsAndArticlesArticlesMain: () => import('../../components/Pages/NewsAndArticles/Articles/Main.vue' /* webpackChunkName: "components/news-and-articles-articles-main" */).then(c => wrapFunctional(c.default || c)),
  NewsAndArticlesArticlesPopular: () => import('../../components/Pages/NewsAndArticles/Articles/Popular.vue' /* webpackChunkName: "components/news-and-articles-articles-popular" */).then(c => wrapFunctional(c.default || c)),
  NewsAndArticlesArticlesSearchCard: () => import('../../components/Pages/NewsAndArticles/Articles/SearchCard.vue' /* webpackChunkName: "components/news-and-articles-articles-search-card" */).then(c => wrapFunctional(c.default || c)),
  NewsAndArticlesNewsCover: () => import('../../components/Pages/NewsAndArticles/News/Cover.vue' /* webpackChunkName: "components/news-and-articles-news-cover" */).then(c => wrapFunctional(c.default || c)),
  NewsAndArticlesNewsDetail: () => import('../../components/Pages/NewsAndArticles/News/Detail.vue' /* webpackChunkName: "components/news-and-articles-news-detail" */).then(c => wrapFunctional(c.default || c)),
  NewsAndArticlesNewsLastUpdate: () => import('../../components/Pages/NewsAndArticles/News/LastUpdate.vue' /* webpackChunkName: "components/news-and-articles-news-last-update" */).then(c => wrapFunctional(c.default || c)),
  NewsAndArticlesNewsMain: () => import('../../components/Pages/NewsAndArticles/News/Main.vue' /* webpackChunkName: "components/news-and-articles-news-main" */).then(c => wrapFunctional(c.default || c)),
  NewsAndArticlesNewsPopular: () => import('../../components/Pages/NewsAndArticles/News/Popular.vue' /* webpackChunkName: "components/news-and-articles-news-popular" */).then(c => wrapFunctional(c.default || c)),
  NewsAndArticlesNewsSearchCard: () => import('../../components/Pages/NewsAndArticles/News/SearchCard.vue' /* webpackChunkName: "components/news-and-articles-news-search-card" */).then(c => wrapFunctional(c.default || c)),
  MutualFundIPOSearchCover: () => import('../../components/Pages/MutualFund/IPOSearch/Cover.vue' /* webpackChunkName: "components/mutual-fund-i-p-o-search-cover" */).then(c => wrapFunctional(c.default || c)),
  MutualFundIPOSearchFilter: () => import('../../components/Pages/MutualFund/IPOSearch/Filter.vue' /* webpackChunkName: "components/mutual-fund-i-p-o-search-filter" */).then(c => wrapFunctional(c.default || c)),
  MutualFundIPOSearch: () => import('../../components/Pages/MutualFund/IPOSearch/Index.vue' /* webpackChunkName: "components/mutual-fund-i-p-o-search" */).then(c => wrapFunctional(c.default || c)),
  MutualFundIPOSearchTable: () => import('../../components/Pages/MutualFund/IPOSearch/Table.vue' /* webpackChunkName: "components/mutual-fund-i-p-o-search-table" */).then(c => wrapFunctional(c.default || c)),
  MutualFundIPOSearchTableAccordion: () => import('../../components/Pages/MutualFund/IPOSearch/TableAccordion.vue' /* webpackChunkName: "components/mutual-fund-i-p-o-search-table-accordion" */).then(c => wrapFunctional(c.default || c)),
  MutualFundIPOSearchTitle: () => import('../../components/Pages/MutualFund/IPOSearch/Title.vue' /* webpackChunkName: "components/mutual-fund-i-p-o-search-title" */).then(c => wrapFunctional(c.default || c)),
  HomeAnalyst: () => import('../../components/Pages/Home/Analyst/Analyst.vue' /* webpackChunkName: "components/home-analyst" */).then(c => wrapFunctional(c.default || c)),
  HomeAnalystArticle: () => import('../../components/Pages/Home/Analyst/AnalystArticle.vue' /* webpackChunkName: "components/home-analyst-article" */).then(c => wrapFunctional(c.default || c)),
  HomeAnalystByIAA: () => import('../../components/Pages/Home/Analyst/AnalystByIAA.vue' /* webpackChunkName: "components/home-analyst-by-i-a-a" */).then(c => wrapFunctional(c.default || c)),
  HomeAnalystCardArticle: () => import('../../components/Pages/Home/Analyst/CardArticle.vue' /* webpackChunkName: "components/home-analyst-card-article" */).then(c => wrapFunctional(c.default || c)),
  HomeGlobalForeginExchanges: () => import('../../components/Pages/Home/Global/ForeginExchanges.vue' /* webpackChunkName: "components/home-global-foregin-exchanges" */).then(c => wrapFunctional(c.default || c)),
  HomeGlobalFtseAsean: () => import('../../components/Pages/Home/Global/FtseAsean.vue' /* webpackChunkName: "components/home-global-ftse-asean" */).then(c => wrapFunctional(c.default || c)),
  HomeGlobal: () => import('../../components/Pages/Home/Global/Global.vue' /* webpackChunkName: "components/home-global" */).then(c => wrapFunctional(c.default || c)),
  HomeGlobalGmsExchanges: () => import('../../components/Pages/Home/Global/GmsExchanges.vue' /* webpackChunkName: "components/home-global-gms-exchanges" */).then(c => wrapFunctional(c.default || c)),
  HomeGlobalInternationalCooperation: () => import('../../components/Pages/Home/Global/InternationalCooperation.vue' /* webpackChunkName: "components/home-global-international-cooperation" */).then(c => wrapFunctional(c.default || c)),
  HomeListedCompany: () => import('../../components/Pages/Home/ListedCompany/ListedCompany.vue' /* webpackChunkName: "components/home-listed-company" */).then(c => wrapFunctional(c.default || c)),
  HomeListedCompanyFirstTradingDay: () => import('../../components/Pages/Home/ListedCompany/firstTradingDay.vue' /* webpackChunkName: "components/home-listed-company-first-trading-day" */).then(c => wrapFunctional(c.default || c)),
  HomeListedCompanyOpportunityDay: () => import('../../components/Pages/Home/ListedCompany/opportunityDay.vue' /* webpackChunkName: "components/home-listed-company-opportunity-day" */).then(c => wrapFunctional(c.default || c)),
  HomeListedCompanyUpcomingIPO: () => import('../../components/Pages/Home/ListedCompany/upcomingIPO.vue' /* webpackChunkName: "components/home-listed-company-upcoming-i-p-o" */).then(c => wrapFunctional(c.default || c)),
  HomeMarketMovement: () => import('../../components/Pages/Home/MarketMovement/MarketMovement.vue' /* webpackChunkName: "components/home-market-movement" */).then(c => wrapFunctional(c.default || c)),
  HomeMarketMovementSlide: () => import('../../components/Pages/Home/MarketMovement/MarketMovementSlide.vue' /* webpackChunkName: "components/home-market-movement-slide" */).then(c => wrapFunctional(c.default || c)),
  HomeNewsAndAnalystCardNews: () => import('../../components/Pages/Home/NewsAndAnalyst/CardNews.vue' /* webpackChunkName: "components/home-news-and-analyst-card-news" */).then(c => wrapFunctional(c.default || c)),
  HomeNewsAndAnalystAnalyst: () => import('../../components/Pages/Home/NewsAndAnalyst/NewsAndAnalystAnalyst.vue' /* webpackChunkName: "components/home-news-and-analyst-analyst" */).then(c => wrapFunctional(c.default || c)),
  HomeNewsAndArticle: () => import('../../components/Pages/Home/NewsAndAnalyst/NewsAndArticle.vue' /* webpackChunkName: "components/home-news-and-article" */).then(c => wrapFunctional(c.default || c)),
  HomeNewsAndEconomic: () => import('../../components/Pages/Home/NewsAndAnalyst/NewsAndEconomic.vue' /* webpackChunkName: "components/home-news-and-economic" */).then(c => wrapFunctional(c.default || c)),
  HomeNewsOriginal: () => import('../../components/Pages/Home/NewsAndAnalyst/NewsOriginal.vue' /* webpackChunkName: "components/home-news-original" */).then(c => wrapFunctional(c.default || c)),
  HomeQuote: () => import('../../components/Pages/Home/Quote/Quote.vue' /* webpackChunkName: "components/home-quote" */).then(c => wrapFunctional(c.default || c)),
  HomeQuoteButton: () => import('../../components/Pages/Home/Quote/QuoteButton.vue' /* webpackChunkName: "components/home-quote-button" */).then(c => wrapFunctional(c.default || c)),
  HomeQuoteFooter: () => import('../../components/Pages/Home/Quote/QuoteFooter.vue' /* webpackChunkName: "components/home-quote-footer" */).then(c => wrapFunctional(c.default || c)),
  HomeQuoteTabFavorite: () => import('../../components/Pages/Home/Quote/QuoteTabFavorite.vue' /* webpackChunkName: "components/home-quote-tab-favorite" */).then(c => wrapFunctional(c.default || c)),
  HomeQuoteTabRecent: () => import('../../components/Pages/Home/Quote/QuoteTabRecent.vue' /* webpackChunkName: "components/home-quote-tab-recent" */).then(c => wrapFunctional(c.default || c)),
  HomeQuoteTabVirtualPortfolio: () => import('../../components/Pages/Home/Quote/QuoteTabVirtualPortfolio.vue' /* webpackChunkName: "components/home-quote-tab-virtual-portfolio" */).then(c => wrapFunctional(c.default || c)),
  HomeStockComAndScreen: () => import('../../components/Pages/Home/Stock/StockComAndScreen.vue' /* webpackChunkName: "components/home-stock-com-and-screen" */).then(c => wrapFunctional(c.default || c)),
  HomeStockComparison: () => import('../../components/Pages/Home/Stock/StockComparison.vue' /* webpackChunkName: "components/home-stock-comparison" */).then(c => wrapFunctional(c.default || c)),
  HomeStockScreening: () => import('../../components/Pages/Home/Stock/StockScreening.vue' /* webpackChunkName: "components/home-stock-screening" */).then(c => wrapFunctional(c.default || c)),
  HomeTfexInvestorsTypeSummary: () => import('../../components/Pages/Home/Tfex/InvestorsTypeSummary.vue' /* webpackChunkName: "components/home-tfex-investors-type-summary" */).then(c => wrapFunctional(c.default || c)),
  HomeTfex: () => import('../../components/Pages/Home/Tfex/Tfex.vue' /* webpackChunkName: "components/home-tfex" */).then(c => wrapFunctional(c.default || c)),
  ResearchAnalystResearchCover: () => import('../../components/Pages/Research/AnalystResearch/Cover.vue' /* webpackChunkName: "components/research-analyst-research-cover" */).then(c => wrapFunctional(c.default || c)),
  ResearchAnalystResearchDetail: () => import('../../components/Pages/Research/AnalystResearch/Detail.vue' /* webpackChunkName: "components/research-analyst-research-detail" */).then(c => wrapFunctional(c.default || c)),
  ResearchAnalystResearchLastUpdate: () => import('../../components/Pages/Research/AnalystResearch/LastUpdate.vue' /* webpackChunkName: "components/research-analyst-research-last-update" */).then(c => wrapFunctional(c.default || c)),
  ResearchAnalystResearchMain: () => import('../../components/Pages/Research/AnalystResearch/Main.vue' /* webpackChunkName: "components/research-analyst-research-main" */).then(c => wrapFunctional(c.default || c)),
  ResearchAnalystResearchPopular: () => import('../../components/Pages/Research/AnalystResearch/Popular.vue' /* webpackChunkName: "components/research-analyst-research-popular" */).then(c => wrapFunctional(c.default || c)),
  ResearchAnalystResearchSearchCard: () => import('../../components/Pages/Research/AnalystResearch/SearchCard.vue' /* webpackChunkName: "components/research-analyst-research-search-card" */).then(c => wrapFunctional(c.default || c)),
  ResearchBusinessAnalysis: () => import('../../components/Pages/Research/BusinessAnalysis/BusinessAnalysis.vue' /* webpackChunkName: "components/research-business-analysis" */).then(c => wrapFunctional(c.default || c)),
  ResearchBusinessAnalysisCover: () => import('../../components/Pages/Research/BusinessAnalysis/Cover.vue' /* webpackChunkName: "components/research-business-analysis-cover" */).then(c => wrapFunctional(c.default || c)),
  ResearchBusinessAnalysisDataTable: () => import('../../components/Pages/Research/BusinessAnalysis/DataTable.vue' /* webpackChunkName: "components/research-business-analysis-data-table" */).then(c => wrapFunctional(c.default || c)),
  ResearchBusinessAnalysisFileItem: () => import('../../components/Pages/Research/BusinessAnalysis/FileItem.vue' /* webpackChunkName: "components/research-business-analysis-file-item" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAAConsensusCover: () => import('../../components/Pages/Research/IAAConsensus/Cover.vue' /* webpackChunkName: "components/research-i-a-a-consensus-cover" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAAConsensusDetail: () => import('../../components/Pages/Research/IAAConsensus/Detail.vue' /* webpackChunkName: "components/research-i-a-a-consensus-detail" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAAConsensus: () => import('../../components/Pages/Research/IAAConsensus/IAAConsensus.vue' /* webpackChunkName: "components/research-i-a-a-consensus" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAATfexConsensusCover: () => import('../../components/Pages/Research/IAATfexConsensus/Cover.vue' /* webpackChunkName: "components/research-i-a-a-tfex-consensus-cover" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAATfexConsensusDetail: () => import('../../components/Pages/Research/IAATfexConsensus/Detail.vue' /* webpackChunkName: "components/research-i-a-a-tfex-consensus-detail" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAATfexConsensusDisclaimer: () => import('../../components/Pages/Research/IAATfexConsensus/Disclaimer.vue' /* webpackChunkName: "components/research-i-a-a-tfex-consensus-disclaimer" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAATfexConsensusFilter: () => import('../../components/Pages/Research/IAATfexConsensus/Filter.vue' /* webpackChunkName: "components/research-i-a-a-tfex-consensus-filter" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAATfexConsensus: () => import('../../components/Pages/Research/IAATfexConsensus/IAATfexConsensus.vue' /* webpackChunkName: "components/research-i-a-a-tfex-consensus" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAATfexConsensusTable: () => import('../../components/Pages/Research/IAATfexConsensus/Table.vue' /* webpackChunkName: "components/research-i-a-a-tfex-consensus-table" */).then(c => wrapFunctional(c.default || c)),
  SearchMiscAmountTag: () => import('../../components/Pages/search/Misc/AmountTag.vue' /* webpackChunkName: "components/search-misc-amount-tag" */).then(c => wrapFunctional(c.default || c)),
  SearchMiscPriceTag: () => import('../../components/Pages/search/Misc/PriceTag.vue' /* webpackChunkName: "components/search-misc-price-tag" */).then(c => wrapFunctional(c.default || c)),
  SearchMiscSecurityTypeTag: () => import('../../components/Pages/search/Misc/SecurityTypeTag.vue' /* webpackChunkName: "components/search-misc-security-type-tag" */).then(c => wrapFunctional(c.default || c)),
  SearchDropdownAnalysis: () => import('../../components/Pages/search/Dropdown/Analysis.vue' /* webpackChunkName: "components/search-dropdown-analysis" */).then(c => wrapFunctional(c.default || c)),
  SearchDropdownArticle: () => import('../../components/Pages/search/Dropdown/Article.vue' /* webpackChunkName: "components/search-dropdown-article" */).then(c => wrapFunctional(c.default || c)),
  SearchDropdownContent: () => import('../../components/Pages/search/Dropdown/Content.vue' /* webpackChunkName: "components/search-dropdown-content" */).then(c => wrapFunctional(c.default || c)),
  SearchDropdownMarket: () => import('../../components/Pages/search/Dropdown/Market.vue' /* webpackChunkName: "components/search-dropdown-market" */).then(c => wrapFunctional(c.default || c)),
  SearchDropdownSecurities: () => import('../../components/Pages/search/Dropdown/Securities.vue' /* webpackChunkName: "components/search-dropdown-securities" */).then(c => wrapFunctional(c.default || c)),
  DerivativeEquityIndexFuturesAnalystConsensus: () => import('../../components/Pages/Derivative/Equity/IndexFutures/AnalystConsensus.vue' /* webpackChunkName: "components/derivative-equity-index-futures-analyst-consensus" */).then(c => wrapFunctional(c.default || c)),
  DerivativeEquityIndexFuturesCover: () => import('../../components/Pages/Derivative/Equity/IndexFutures/Cover.vue' /* webpackChunkName: "components/derivative-equity-index-futures-cover" */).then(c => wrapFunctional(c.default || c)),
  DerivativeEquityIndexFuturesHistorical: () => import('../../components/Pages/Derivative/Equity/IndexFutures/Historical.vue' /* webpackChunkName: "components/derivative-equity-index-futures-historical" */).then(c => wrapFunctional(c.default || c)),
  DerivativeEquityIndexFutures: () => import('../../components/Pages/Derivative/Equity/IndexFutures/IndexFutures.vue' /* webpackChunkName: "components/derivative-equity-index-futures" */).then(c => wrapFunctional(c.default || c)),
  DerivativeEquityIndexFuturesOverview: () => import('../../components/Pages/Derivative/Equity/IndexFutures/Overview.vue' /* webpackChunkName: "components/derivative-equity-index-futures-overview" */).then(c => wrapFunctional(c.default || c)),
  DerivativeEquityIndexFuturesTradingCalendar: () => import('../../components/Pages/Derivative/Equity/IndexFutures/TradingCalendar.vue' /* webpackChunkName: "components/derivative-equity-index-futures-trading-calendar" */).then(c => wrapFunctional(c.default || c)),
  DerivativeEquityIndexOptionsAnalystConcensus: () => import('../../components/Pages/Derivative/Equity/IndexOptions/AnalystConcensus.vue' /* webpackChunkName: "components/derivative-equity-index-options-analyst-concensus" */).then(c => wrapFunctional(c.default || c)),
  DerivativeEquityIndexOptionsCover: () => import('../../components/Pages/Derivative/Equity/IndexOptions/Cover.vue' /* webpackChunkName: "components/derivative-equity-index-options-cover" */).then(c => wrapFunctional(c.default || c)),
  DerivativeEquityIndexOptionsHistorical: () => import('../../components/Pages/Derivative/Equity/IndexOptions/Historical.vue' /* webpackChunkName: "components/derivative-equity-index-options-historical" */).then(c => wrapFunctional(c.default || c)),
  DerivativeEquityIndexOptions: () => import('../../components/Pages/Derivative/Equity/IndexOptions/IndexOptions.vue' /* webpackChunkName: "components/derivative-equity-index-options" */).then(c => wrapFunctional(c.default || c)),
  DerivativeEquityIndexOptionsOverview: () => import('../../components/Pages/Derivative/Equity/IndexOptions/Overview.vue' /* webpackChunkName: "components/derivative-equity-index-options-overview" */).then(c => wrapFunctional(c.default || c)),
  DerivativeEquityIndexOptionsTradingCalendar: () => import('../../components/Pages/Derivative/Equity/IndexOptions/TradingCalendar.vue' /* webpackChunkName: "components/derivative-equity-index-options-trading-calendar" */).then(c => wrapFunctional(c.default || c)),
  DerivativeEquitySingleStockFuturesAnalystConcensus: () => import('../../components/Pages/Derivative/Equity/SingleStockFutures/AnalystConcensus.vue' /* webpackChunkName: "components/derivative-equity-single-stock-futures-analyst-concensus" */).then(c => wrapFunctional(c.default || c)),
  DerivativeEquitySingleStockFuturesCover: () => import('../../components/Pages/Derivative/Equity/SingleStockFutures/Cover.vue' /* webpackChunkName: "components/derivative-equity-single-stock-futures-cover" */).then(c => wrapFunctional(c.default || c)),
  DerivativeEquitySingleStockFuturesHistorical: () => import('../../components/Pages/Derivative/Equity/SingleStockFutures/Historical.vue' /* webpackChunkName: "components/derivative-equity-single-stock-futures-historical" */).then(c => wrapFunctional(c.default || c)),
  DerivativeEquitySingleStockFuturesOverview: () => import('../../components/Pages/Derivative/Equity/SingleStockFutures/Overview.vue' /* webpackChunkName: "components/derivative-equity-single-stock-futures-overview" */).then(c => wrapFunctional(c.default || c)),
  DerivativeEquitySingleStockFutures: () => import('../../components/Pages/Derivative/Equity/SingleStockFutures/SingleStockFutures.vue' /* webpackChunkName: "components/derivative-equity-single-stock-futures" */).then(c => wrapFunctional(c.default || c)),
  DerivativeEquitySingleStockFuturesTradingCalendar: () => import('../../components/Pages/Derivative/Equity/SingleStockFutures/TradingCalendar.vue' /* webpackChunkName: "components/derivative-equity-single-stock-futures-trading-calendar" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewComparisonChart: () => import('../../components/Pages/Derivative/Overview/Comparison/Chart.vue' /* webpackChunkName: "components/derivative-overview-comparison-chart" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewComparisonFilter: () => import('../../components/Pages/Derivative/Overview/Comparison/Filter.vue' /* webpackChunkName: "components/derivative-overview-comparison-filter" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewComparison: () => import('../../components/Pages/Derivative/Overview/Comparison/index.vue' /* webpackChunkName: "components/derivative-overview-comparison" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewIndexAccordionSummary: () => import('../../components/Pages/Derivative/Overview/Index/AccordionSummary.vue' /* webpackChunkName: "components/derivative-overview-index-accordion-summary" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewIndexAnalysts: () => import('../../components/Pages/Derivative/Overview/Index/Analysts.vue' /* webpackChunkName: "components/derivative-overview-index-analysts" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewIndexInformation: () => import('../../components/Pages/Derivative/Overview/Index/Information.vue' /* webpackChunkName: "components/derivative-overview-index-information" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewIndexMarketSummary: () => import('../../components/Pages/Derivative/Overview/Index/MarketSummary.vue' /* webpackChunkName: "components/derivative-overview-index-market-summary" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewIndexTopRanking: () => import('../../components/Pages/Derivative/Overview/Index/TopRanking.vue' /* webpackChunkName: "components/derivative-overview-index-top-ranking" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewIndex: () => import('../../components/Pages/Derivative/Overview/Index/index.vue' /* webpackChunkName: "components/derivative-overview-index" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewMarketData: () => import('../../components/Pages/Derivative/Overview/MarketData/index.vue' /* webpackChunkName: "components/derivative-overview-market-data" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewTradingCalendar: () => import('../../components/Pages/Derivative/Overview/TradingCalendar/index.vue' /* webpackChunkName: "components/derivative-overview-trading-calendar" */).then(c => wrapFunctional(c.default || c)),
  EquityDWMiscDWDateLowHigh: () => import('../../components/Pages/Equity/DW/Misc/DWDateLowHigh.vue' /* webpackChunkName: "components/equity-d-w-misc-d-w-date-low-high" */).then(c => wrapFunctional(c.default || c)),
  EquityDWMiscDWPrice: () => import('../../components/Pages/Equity/DW/Misc/DWPrice.vue' /* webpackChunkName: "components/equity-d-w-misc-d-w-price" */).then(c => wrapFunctional(c.default || c)),
  EquityDWMiscDWStatusTrading: () => import('../../components/Pages/Equity/DW/Misc/DWStatusTrading.vue' /* webpackChunkName: "components/equity-d-w-misc-d-w-status-trading" */).then(c => wrapFunctional(c.default || c)),
  EquityDWMiscDWTagStatusType: () => import('../../components/Pages/Equity/DW/Misc/DWTagStatusType.vue' /* webpackChunkName: "components/equity-d-w-misc-d-w-tag-status-type" */).then(c => wrapFunctional(c.default || c)),
  EquityDWMiscDWTagType: () => import('../../components/Pages/Equity/DW/Misc/DWTagType.vue' /* webpackChunkName: "components/equity-d-w-misc-d-w-tag-type" */).then(c => wrapFunctional(c.default || c)),
  EquityDWOverviewDWIndicators: () => import('../../components/Pages/Equity/DW/Overview/DWIndicators.vue' /* webpackChunkName: "components/equity-d-w-overview-d-w-indicators" */).then(c => wrapFunctional(c.default || c)),
  EquityDWOverviewDWInfo: () => import('../../components/Pages/Equity/DW/Overview/DWInfo.vue' /* webpackChunkName: "components/equity-d-w-overview-d-w-info" */).then(c => wrapFunctional(c.default || c)),
  EquityDWOverviewDWOtherUnderlying: () => import('../../components/Pages/Equity/DW/Overview/DWOtherUnderlying.vue' /* webpackChunkName: "components/equity-d-w-overview-d-w-other-underlying" */).then(c => wrapFunctional(c.default || c)),
  EquityDWOverviewDWRelatedDocument: () => import('../../components/Pages/Equity/DW/Overview/DWRelatedDocument.vue' /* webpackChunkName: "components/equity-d-w-overview-d-w-related-document" */).then(c => wrapFunctional(c.default || c)),
  EquityDWOverviewDWTradingInfo: () => import('../../components/Pages/Equity/DW/Overview/DWTradingInfo.vue' /* webpackChunkName: "components/equity-d-w-overview-d-w-trading-info" */).then(c => wrapFunctional(c.default || c)),
  EquityDWOverviewDWUnderlying: () => import('../../components/Pages/Equity/DW/Overview/DWUnderlying.vue' /* webpackChunkName: "components/equity-d-w-overview-d-w-underlying" */).then(c => wrapFunctional(c.default || c)),
  EquityETFETFInformation: () => import('../../components/Pages/Equity/ETF/ETFInformation/ETFInformation.vue' /* webpackChunkName: "components/equity-e-t-f-e-t-f-information" */).then(c => wrapFunctional(c.default || c)),
  EquityETFETFInformationTab: () => import('../../components/Pages/Equity/ETF/ETFInformation/ETFInformationTab.vue' /* webpackChunkName: "components/equity-e-t-f-e-t-f-information-tab" */).then(c => wrapFunctional(c.default || c)),
  EquityETFETFInformationFactsheet: () => import('../../components/Pages/Equity/ETF/ETFInformation/Factsheet.vue' /* webpackChunkName: "components/equity-e-t-f-e-t-f-information-factsheet" */).then(c => wrapFunctional(c.default || c)),
  EquityETFETFInformationInvestmentAssets: () => import('../../components/Pages/Equity/ETF/ETFInformation/InvestmentAssets.vue' /* webpackChunkName: "components/equity-e-t-f-e-t-f-information-investment-assets" */).then(c => wrapFunctional(c.default || c)),
  EquityETFETFInformationMajorShareholder: () => import('../../components/Pages/Equity/ETF/ETFInformation/MajorShareholder.vue' /* webpackChunkName: "components/equity-e-t-f-e-t-f-information-major-shareholder" */).then(c => wrapFunctional(c.default || c)),
  EquityETFETFInformationOverview: () => import('../../components/Pages/Equity/ETF/ETFInformation/Overview.vue' /* webpackChunkName: "components/equity-e-t-f-e-t-f-information-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityETFPastPerformanceNetAssetValue: () => import('../../components/Pages/Equity/ETF/PastPerformance/NetAssetValue.vue' /* webpackChunkName: "components/equity-e-t-f-past-performance-net-asset-value" */).then(c => wrapFunctional(c.default || c)),
  EquityETFPastPerformance: () => import('../../components/Pages/Equity/ETF/PastPerformance/PastPerformance.vue' /* webpackChunkName: "components/equity-e-t-f-past-performance" */).then(c => wrapFunctional(c.default || c)),
  EquityIPOIPOPerformance: () => import('../../components/Pages/Equity/IPO/IPOPerformance/IPOPerformance.vue' /* webpackChunkName: "components/equity-i-p-o-i-p-o-performance" */).then(c => wrapFunctional(c.default || c)),
  EquityIPOUpcomingIPO: () => import('../../components/Pages/Equity/IPO/UpcomingIPO/UpcomingIPO.vue' /* webpackChunkName: "components/equity-i-p-o-upcoming-i-p-o" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDR: () => import('../../components/Pages/Equity/Market/DR/index.vue' /* webpackChunkName: "components/equity-market-d-r" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDRTab: () => import('../../components/Pages/Equity/Market/DR/tab.vue' /* webpackChunkName: "components/equity-market-d-r-tab" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDRXKnowLedge: () => import('../../components/Pages/Equity/Market/DRX/KnowLedge.vue' /* webpackChunkName: "components/equity-market-d-r-x-know-ledge" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDRXScreening: () => import('../../components/Pages/Equity/Market/DRX/Screening.vue' /* webpackChunkName: "components/equity-market-d-r-x-screening" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDRXTradingInformation: () => import('../../components/Pages/Equity/Market/DRX/TradingInformation.vue' /* webpackChunkName: "components/equity-market-d-r-x-trading-information" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDRX: () => import('../../components/Pages/Equity/Market/DRX/index.vue' /* webpackChunkName: "components/equity-market-d-r-x" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWCalculator: () => import('../../components/Pages/Equity/Market/DW/Calculator.vue' /* webpackChunkName: "components/equity-market-d-w-calculator" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWScreening: () => import('../../components/Pages/Equity/Market/DW/Screening.vue' /* webpackChunkName: "components/equity-market-d-w-screening" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDW: () => import('../../components/Pages/Equity/Market/DW/index.vue' /* webpackChunkName: "components/equity-market-d-w" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketETFScreening: () => import('../../components/Pages/Equity/Market/ETF/Screening.vue' /* webpackChunkName: "components/equity-market-e-t-f-screening" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketETFThematic: () => import('../../components/Pages/Equity/Market/ETF/Thematic.vue' /* webpackChunkName: "components/equity-market-e-t-f-thematic" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketETF: () => import('../../components/Pages/Equity/Market/ETF/index.vue' /* webpackChunkName: "components/equity-market-e-t-f" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataNvdr: () => import('../../components/Pages/Equity/MarketData/Nvdr/Nvdr.vue' /* webpackChunkName: "components/equity-market-data-nvdr" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataNvdrValue: () => import('../../components/Pages/Equity/MarketData/Nvdr/Value.vue' /* webpackChunkName: "components/equity-market-data-nvdr-value" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataNvdrVolume: () => import('../../components/Pages/Equity/MarketData/Nvdr/Volume.vue' /* webpackChunkName: "components/equity-market-data-nvdr-volume" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataHistoricalReportFiveDays: () => import('../../components/Pages/Equity/MarketData/HistoricalReport/FiveDays.vue' /* webpackChunkName: "components/equity-market-data-historical-report-five-days" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataHistoricalReport: () => import('../../components/Pages/Equity/MarketData/HistoricalReport/HistoricalReport.vue' /* webpackChunkName: "components/equity-market-data-historical-report" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataHistoricalReportInvestorType: () => import('../../components/Pages/Equity/MarketData/HistoricalReport/InvestorType.vue' /* webpackChunkName: "components/equity-market-data-historical-report-investor-type" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataOverviewFilterTab: () => import('../../components/Pages/Equity/MarketData/Overview/FilterTab.vue' /* webpackChunkName: "components/equity-market-data-overview-filter-tab" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataOverview: () => import('../../components/Pages/Equity/MarketData/Overview/Index.vue' /* webpackChunkName: "components/equity-market-data-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataOverviewLoading: () => import('../../components/Pages/Equity/MarketData/Overview/Loading.vue' /* webpackChunkName: "components/equity-market-data-overview-loading" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataOverviewMAI: () => import('../../components/Pages/Equity/MarketData/Overview/MAI.vue' /* webpackChunkName: "components/equity-market-data-overview-m-a-i" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataOverviewSET: () => import('../../components/Pages/Equity/MarketData/Overview/SET.vue' /* webpackChunkName: "components/equity-market-data-overview-s-e-t" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataOverviewTitleTable: () => import('../../components/Pages/Equity/MarketData/Overview/TitleTable.vue' /* webpackChunkName: "components/equity-market-data-overview-title-table" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonAnalystConcensus: () => import('../../components/Pages/Equity/Stock/Common/AnalystConcensus.vue' /* webpackChunkName: "components/equity-stock-common-analyst-concensus" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonBenefit: () => import('../../components/Pages/Equity/Stock/Common/Benefit.vue' /* webpackChunkName: "components/equity-stock-common-benefit" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommon: () => import('../../components/Pages/Equity/Stock/Common/Common.vue' /* webpackChunkName: "components/equity-stock-common" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonCover: () => import('../../components/Pages/Equity/Stock/Common/Cover.vue' /* webpackChunkName: "components/equity-stock-common-cover" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonESG: () => import('../../components/Pages/Equity/Stock/Common/ESG.vue' /* webpackChunkName: "components/equity-stock-common-e-s-g" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonHistorical: () => import('../../components/Pages/Equity/Stock/Common/Historical.vue' /* webpackChunkName: "components/equity-stock-common-historical" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonNews: () => import('../../components/Pages/Equity/Stock/Common/News.vue' /* webpackChunkName: "components/equity-stock-common-news" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonOverview: () => import('../../components/Pages/Equity/Stock/Common/Overview.vue' /* webpackChunkName: "components/equity-stock-common-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonSectorComparison: () => import('../../components/Pages/Equity/Stock/Common/SectorComparison.vue' /* webpackChunkName: "components/equity-stock-common-sector-comparison" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonForeignBenefit: () => import('../../components/Pages/Equity/Stock/CommonForeign/Benefit.vue' /* webpackChunkName: "components/equity-stock-common-foreign-benefit" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonForeign: () => import('../../components/Pages/Equity/Stock/CommonForeign/CommonForeign.vue' /* webpackChunkName: "components/equity-stock-common-foreign" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonForeignCover: () => import('../../components/Pages/Equity/Stock/CommonForeign/Cover.vue' /* webpackChunkName: "components/equity-stock-common-foreign-cover" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonForeignHistorical: () => import('../../components/Pages/Equity/Stock/CommonForeign/Historical.vue' /* webpackChunkName: "components/equity-stock-common-foreign-historical" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonForeignNews: () => import('../../components/Pages/Equity/Stock/CommonForeign/News.vue' /* webpackChunkName: "components/equity-stock-common-foreign-news" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonForeignOverview: () => import('../../components/Pages/Equity/Stock/CommonForeign/Overview.vue' /* webpackChunkName: "components/equity-stock-common-foreign-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityStockPreferredBenefit: () => import('../../components/Pages/Equity/Stock/Preferred/Benefit.vue' /* webpackChunkName: "components/equity-stock-preferred-benefit" */).then(c => wrapFunctional(c.default || c)),
  EquityStockPreferredCover: () => import('../../components/Pages/Equity/Stock/Preferred/Cover.vue' /* webpackChunkName: "components/equity-stock-preferred-cover" */).then(c => wrapFunctional(c.default || c)),
  EquityStockPreferredHistorical: () => import('../../components/Pages/Equity/Stock/Preferred/Historical.vue' /* webpackChunkName: "components/equity-stock-preferred-historical" */).then(c => wrapFunctional(c.default || c)),
  EquityStockPreferredNews: () => import('../../components/Pages/Equity/Stock/Preferred/News.vue' /* webpackChunkName: "components/equity-stock-preferred-news" */).then(c => wrapFunctional(c.default || c)),
  EquityStockPreferredOverview: () => import('../../components/Pages/Equity/Stock/Preferred/Overview.vue' /* webpackChunkName: "components/equity-stock-preferred-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityStockPreferred: () => import('../../components/Pages/Equity/Stock/Preferred/Preferred.vue' /* webpackChunkName: "components/equity-stock-preferred" */).then(c => wrapFunctional(c.default || c)),
  EquityStockPreferredForeignBenefit: () => import('../../components/Pages/Equity/Stock/PreferredForeign/Benefit.vue' /* webpackChunkName: "components/equity-stock-preferred-foreign-benefit" */).then(c => wrapFunctional(c.default || c)),
  EquityStockPreferredForeignCover: () => import('../../components/Pages/Equity/Stock/PreferredForeign/Cover.vue' /* webpackChunkName: "components/equity-stock-preferred-foreign-cover" */).then(c => wrapFunctional(c.default || c)),
  EquityStockPreferredForeignHistorical: () => import('../../components/Pages/Equity/Stock/PreferredForeign/Historical.vue' /* webpackChunkName: "components/equity-stock-preferred-foreign-historical" */).then(c => wrapFunctional(c.default || c)),
  EquityStockPreferredForeignNews: () => import('../../components/Pages/Equity/Stock/PreferredForeign/News.vue' /* webpackChunkName: "components/equity-stock-preferred-foreign-news" */).then(c => wrapFunctional(c.default || c)),
  EquityStockPreferredForeignOverview: () => import('../../components/Pages/Equity/Stock/PreferredForeign/Overview.vue' /* webpackChunkName: "components/equity-stock-preferred-foreign-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityStockPreferredForeign: () => import('../../components/Pages/Equity/Stock/PreferredForeign/PreferredForeign.vue' /* webpackChunkName: "components/equity-stock-preferred-foreign" */).then(c => wrapFunctional(c.default || c)),
  EquityStockUnitTrustCover: () => import('../../components/Pages/Equity/Stock/UnitTrust/Cover.vue' /* webpackChunkName: "components/equity-stock-unit-trust-cover" */).then(c => wrapFunctional(c.default || c)),
  EquityStockUnitTrustHistorical: () => import('../../components/Pages/Equity/Stock/UnitTrust/Historical.vue' /* webpackChunkName: "components/equity-stock-unit-trust-historical" */).then(c => wrapFunctional(c.default || c)),
  EquityStockUnitTrustNews: () => import('../../components/Pages/Equity/Stock/UnitTrust/News.vue' /* webpackChunkName: "components/equity-stock-unit-trust-news" */).then(c => wrapFunctional(c.default || c)),
  EquityStockUnitTrustOverview: () => import('../../components/Pages/Equity/Stock/UnitTrust/Overview.vue' /* webpackChunkName: "components/equity-stock-unit-trust-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityStockUnitTrust: () => import('../../components/Pages/Equity/Stock/UnitTrust/UnitTrust.vue' /* webpackChunkName: "components/equity-stock-unit-trust" */).then(c => wrapFunctional(c.default || c)),
  EquityStockWarrantBenefit: () => import('../../components/Pages/Equity/Stock/Warrant/Benefit.vue' /* webpackChunkName: "components/equity-stock-warrant-benefit" */).then(c => wrapFunctional(c.default || c)),
  EquityStockWarrantCover: () => import('../../components/Pages/Equity/Stock/Warrant/Cover.vue' /* webpackChunkName: "components/equity-stock-warrant-cover" */).then(c => wrapFunctional(c.default || c)),
  EquityStockWarrantDateLowHigh: () => import('../../components/Pages/Equity/Stock/Warrant/DateLowHigh.vue' /* webpackChunkName: "components/equity-stock-warrant-date-low-high" */).then(c => wrapFunctional(c.default || c)),
  EquityStockWarrantHistorical: () => import('../../components/Pages/Equity/Stock/Warrant/Historical.vue' /* webpackChunkName: "components/equity-stock-warrant-historical" */).then(c => wrapFunctional(c.default || c)),
  EquityStockWarrantNews: () => import('../../components/Pages/Equity/Stock/Warrant/News.vue' /* webpackChunkName: "components/equity-stock-warrant-news" */).then(c => wrapFunctional(c.default || c)),
  EquityStockWarrantOverview: () => import('../../components/Pages/Equity/Stock/Warrant/Overview.vue' /* webpackChunkName: "components/equity-stock-warrant-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityStockWarrant: () => import('../../components/Pages/Equity/Stock/Warrant/Warrant.vue' /* webpackChunkName: "components/equity-stock-warrant" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCalendarSymbolBenefitsXB: () => import('../../components/Pages/Equity/StockCalendar/SymbolBenefits/XB.vue' /* webpackChunkName: "components/equity-stock-calendar-symbol-benefits-x-b" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCalendarSymbolBenefitsXD: () => import('../../components/Pages/Equity/StockCalendar/SymbolBenefits/XD.vue' /* webpackChunkName: "components/equity-stock-calendar-symbol-benefits-x-d" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCalendarSymbolBenefitsXE: () => import('../../components/Pages/Equity/StockCalendar/SymbolBenefits/XE.vue' /* webpackChunkName: "components/equity-stock-calendar-symbol-benefits-x-e" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCalendarSymbolBenefitsXI: () => import('../../components/Pages/Equity/StockCalendar/SymbolBenefits/XI.vue' /* webpackChunkName: "components/equity-stock-calendar-symbol-benefits-x-i" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCalendarSymbolBenefitsXM: () => import('../../components/Pages/Equity/StockCalendar/SymbolBenefits/XM.vue' /* webpackChunkName: "components/equity-stock-calendar-symbol-benefits-x-m" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCalendarSymbolBenefitsXN: () => import('../../components/Pages/Equity/StockCalendar/SymbolBenefits/XN.vue' /* webpackChunkName: "components/equity-stock-calendar-symbol-benefits-x-n" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCalendarSymbolBenefitsXRWT: () => import('../../components/Pages/Equity/StockCalendar/SymbolBenefits/XRWT.vue' /* webpackChunkName: "components/equity-stock-calendar-symbol-benefits-x-r-w-t" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryOverViewChart: () => import('../../components/Pages/MarketSummary/OverView/Chart/index.vue' /* webpackChunkName: "components/market-summary-over-view-chart" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryOverViewDetailIndexInfo: () => import('../../components/Pages/MarketSummary/OverView/Detail/IndexInfo.vue' /* webpackChunkName: "components/market-summary-over-view-detail-index-info" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryOverViewDetailSumMarket: () => import('../../components/Pages/MarketSummary/OverView/Detail/SumMarket.vue' /* webpackChunkName: "components/market-summary-over-view-detail-sum-market" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryOverViewDetail: () => import('../../components/Pages/MarketSummary/OverView/Detail/index.vue' /* webpackChunkName: "components/market-summary-over-view-detail" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryOverViewStatistics: () => import('../../components/Pages/MarketSummary/OverView/Statistics/index.vue' /* webpackChunkName: "components/market-summary-over-view-statistics" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryRankingMostActiveValue: () => import('../../components/Pages/MarketSummary/Ranking/MostActiveValue/index.vue' /* webpackChunkName: "components/market-summary-ranking-most-active-value" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryRankingMostActiveVolume: () => import('../../components/Pages/MarketSummary/Ranking/MostActiveVolume/index.vue' /* webpackChunkName: "components/market-summary-ranking-most-active-volume" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryRankingOverviewTableOverview: () => import('../../components/Pages/MarketSummary/Ranking/Overview/TableOverview.vue' /* webpackChunkName: "components/market-summary-ranking-overview-table-overview" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryRankingOverview: () => import('../../components/Pages/MarketSummary/Ranking/Overview/index.vue' /* webpackChunkName: "components/market-summary-ranking-overview" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryRankingPopularQuote: () => import('../../components/Pages/MarketSummary/Ranking/PopularQuote/index.vue' /* webpackChunkName: "components/market-summary-ranking-popular-quote" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryRankingTopDividendYield: () => import('../../components/Pages/MarketSummary/Ranking/TopDividendYield/index.vue' /* webpackChunkName: "components/market-summary-ranking-top-dividend-yield" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryRankingTopEPS: () => import('../../components/Pages/MarketSummary/Ranking/TopEPS/index.vue' /* webpackChunkName: "components/market-summary-ranking-top-e-p-s" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryRankingTopGainer: () => import('../../components/Pages/MarketSummary/Ranking/TopGainer/index.vue' /* webpackChunkName: "components/market-summary-ranking-top-gainer" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryRankingTopLoser: () => import('../../components/Pages/MarketSummary/Ranking/TopLoser/index.vue' /* webpackChunkName: "components/market-summary-ranking-top-loser" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryRankingTopNetProfitMargin: () => import('../../components/Pages/MarketSummary/Ranking/TopNetProfitMargin/index.vue' /* webpackChunkName: "components/market-summary-ranking-top-net-profit-margin" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryRankingTopPE: () => import('../../components/Pages/MarketSummary/Ranking/TopPE/index.vue' /* webpackChunkName: "components/market-summary-ranking-top-p-e" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryRankingTopROA: () => import('../../components/Pages/MarketSummary/Ranking/TopROA/index.vue' /* webpackChunkName: "components/market-summary-ranking-top-r-o-a" */).then(c => wrapFunctional(c.default || c)),
  MarketSummaryRankingTopROE: () => import('../../components/Pages/MarketSummary/Ranking/TopROE/index.vue' /* webpackChunkName: "components/market-summary-ranking-top-r-o-e" */).then(c => wrapFunctional(c.default || c)),
  MemberFavouriteTableAll: () => import('../../components/Pages/Member/Favourite/Table/All.vue' /* webpackChunkName: "components/member-favourite-table-all" */).then(c => wrapFunctional(c.default || c)),
  MemberFavouriteTableDR: () => import('../../components/Pages/Member/Favourite/Table/DR.vue' /* webpackChunkName: "components/member-favourite-table-d-r" */).then(c => wrapFunctional(c.default || c)),
  MemberFavouriteTableDRx: () => import('../../components/Pages/Member/Favourite/Table/DRx.vue' /* webpackChunkName: "components/member-favourite-table-d-rx" */).then(c => wrapFunctional(c.default || c)),
  MemberFavouriteTableDW: () => import('../../components/Pages/Member/Favourite/Table/DW.vue' /* webpackChunkName: "components/member-favourite-table-d-w" */).then(c => wrapFunctional(c.default || c)),
  MemberFavouriteTableETF: () => import('../../components/Pages/Member/Favourite/Table/ETF.vue' /* webpackChunkName: "components/member-favourite-table-e-t-f" */).then(c => wrapFunctional(c.default || c)),
  MemberFavouriteTableFund: () => import('../../components/Pages/Member/Favourite/Table/Fund.vue' /* webpackChunkName: "components/member-favourite-table-fund" */).then(c => wrapFunctional(c.default || c)),
  MemberFavouriteTableFutures: () => import('../../components/Pages/Member/Favourite/Table/Futures.vue' /* webpackChunkName: "components/member-favourite-table-futures" */).then(c => wrapFunctional(c.default || c)),
  MemberFavouriteTableOptions: () => import('../../components/Pages/Member/Favourite/Table/Options.vue' /* webpackChunkName: "components/member-favourite-table-options" */).then(c => wrapFunctional(c.default || c)),
  MemberFavouriteTableStock: () => import('../../components/Pages/Member/Favourite/Table/Stock.vue' /* webpackChunkName: "components/member-favourite-table-stock" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioDerivativeAssetAllocation: () => import('../../components/Pages/Member/VirtualPortfolio/Derivative/AssetAllocation.vue' /* webpackChunkName: "components/member-virtual-portfolio-derivative-asset-allocation" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioDerivativePortfolio: () => import('../../components/Pages/Member/VirtualPortfolio/Derivative/DerivativePortfolio.vue' /* webpackChunkName: "components/member-virtual-portfolio-derivative-portfolio" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioDerivativeOverview: () => import('../../components/Pages/Member/VirtualPortfolio/Derivative/Overview.vue' /* webpackChunkName: "components/member-virtual-portfolio-derivative-overview" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioMutualFundAssetAllocation: () => import('../../components/Pages/Member/VirtualPortfolio/MutualFund/AssetAllocation.vue' /* webpackChunkName: "components/member-virtual-portfolio-mutual-fund-asset-allocation" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioMutualFundPortfolio: () => import('../../components/Pages/Member/VirtualPortfolio/MutualFund/MutualFundPortfolio.vue' /* webpackChunkName: "components/member-virtual-portfolio-mutual-fund-portfolio" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioMutualFundOverview: () => import('../../components/Pages/Member/VirtualPortfolio/MutualFund/Overview.vue' /* webpackChunkName: "components/member-virtual-portfolio-mutual-fund-overview" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioStockAssetAllocation: () => import('../../components/Pages/Member/VirtualPortfolio/Stock/AssetAllocation.vue' /* webpackChunkName: "components/member-virtual-portfolio-stock-asset-allocation" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioStockOverview: () => import('../../components/Pages/Member/VirtualPortfolio/Stock/Overview.vue' /* webpackChunkName: "components/member-virtual-portfolio-stock-overview" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioStockPortfolio: () => import('../../components/Pages/Member/VirtualPortfolio/Stock/StockPortfolio.vue' /* webpackChunkName: "components/member-virtual-portfolio-stock-portfolio" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioSummaryOverview: () => import('../../components/Pages/Member/VirtualPortfolio/Summary/Overview.vue' /* webpackChunkName: "components/member-virtual-portfolio-summary-overview" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioSummaryShareholding: () => import('../../components/Pages/Member/VirtualPortfolio/Summary/Shareholding.vue' /* webpackChunkName: "components/member-virtual-portfolio-summary-shareholding" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioSummaryPortfolio: () => import('../../components/Pages/Member/VirtualPortfolio/Summary/SummaryPortfolio.vue' /* webpackChunkName: "components/member-virtual-portfolio-summary-portfolio" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketScreeningCardComparison: () => import('../../components/Pages/MutualFund/Market/Screening/CardComparison.vue' /* webpackChunkName: "components/mutual-fund-market-screening-card-comparison" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketScreeningCheckbox: () => import('../../components/Pages/MutualFund/Market/Screening/Checkbox.vue' /* webpackChunkName: "components/mutual-fund-market-screening-checkbox" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketScreeningFilter: () => import('../../components/Pages/MutualFund/Market/Screening/Filter.vue' /* webpackChunkName: "components/mutual-fund-market-screening-filter" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketScreening: () => import('../../components/Pages/MutualFund/Market/Screening/Screening.vue' /* webpackChunkName: "components/mutual-fund-market-screening" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketScreeningTableAdvancedCustom: () => import('../../components/Pages/MutualFund/Market/Screening/TableAdvancedCustom.vue' /* webpackChunkName: "components/mutual-fund-market-screening-table-advanced-custom" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketScreeningTableFee: () => import('../../components/Pages/MutualFund/Market/Screening/TableFee.vue' /* webpackChunkName: "components/mutual-fund-market-screening-table-fee" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketScreeningTableOverview: () => import('../../components/Pages/MutualFund/Market/Screening/TableOverview.vue' /* webpackChunkName: "components/mutual-fund-market-screening-table-overview" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketScreeningTablePerformance: () => import('../../components/Pages/MutualFund/Market/Screening/TablePerformance.vue' /* webpackChunkName: "components/mutual-fund-market-screening-table-performance" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketScreeningTitle: () => import('../../components/Pages/MutualFund/Market/Screening/Title.vue' /* webpackChunkName: "components/mutual-fund-market-screening-title" */).then(c => wrapFunctional(c.default || c)),
  HomeNewsAndAnalystEconomicCrudeOilPrice: () => import('../../components/Pages/Home/NewsAndAnalyst/Economic/CrudeOilPrice.vue' /* webpackChunkName: "components/home-news-and-analyst-economic-crude-oil-price" */).then(c => wrapFunctional(c.default || c)),
  HomeNewsAndAnalystEconomicExchange: () => import('../../components/Pages/Home/NewsAndAnalyst/Economic/Exchange.vue' /* webpackChunkName: "components/home-news-and-analyst-economic-exchange" */).then(c => wrapFunctional(c.default || c)),
  HomeNewsAndAnalystEconomicGold: () => import('../../components/Pages/Home/NewsAndAnalyst/Economic/Gold.vue' /* webpackChunkName: "components/home-news-and-analyst-economic-gold" */).then(c => wrapFunctional(c.default || c)),
  HomeNewsAndAnalystEconomicInterest: () => import('../../components/Pages/Home/NewsAndAnalyst/Economic/Interest.vue' /* webpackChunkName: "components/home-news-and-analyst-economic-interest" */).then(c => wrapFunctional(c.default || c)),
  HomeNewsAndAnalystEconomicMetal: () => import('../../components/Pages/Home/NewsAndAnalyst/Economic/Metal.vue' /* webpackChunkName: "components/home-news-and-analyst-economic-metal" */).then(c => wrapFunctional(c.default || c)),
  HomeNewsAndAnalystEconomicOil: () => import('../../components/Pages/Home/NewsAndAnalyst/Economic/Oil.vue' /* webpackChunkName: "components/home-news-and-analyst-economic-oil" */).then(c => wrapFunctional(c.default || c)),
  HomeNewsAndAnalystEconomicReference: () => import('../../components/Pages/Home/NewsAndAnalyst/Economic/Reference.vue' /* webpackChunkName: "components/home-news-and-analyst-economic-reference" */).then(c => wrapFunctional(c.default || c)),
  HomeNewsAndAnalystEconomicReferenceFromAPI: () => import('../../components/Pages/Home/NewsAndAnalyst/Economic/ReferenceFromAPI.vue' /* webpackChunkName: "components/home-news-and-analyst-economic-reference-from-a-p-i" */).then(c => wrapFunctional(c.default || c)),
  HomeNewsAndAnalystEconomicRisingPrice: () => import('../../components/Pages/Home/NewsAndAnalyst/Economic/RisingPrice.vue' /* webpackChunkName: "components/home-news-and-analyst-economic-rising-price" */).then(c => wrapFunctional(c.default || c)),
  HomeNewsAndAnalystEconomicTitle: () => import('../../components/Pages/Home/NewsAndAnalyst/Economic/Title.vue' /* webpackChunkName: "components/home-news-and-analyst-economic-title" */).then(c => wrapFunctional(c.default || c)),
  HomeNewsAndAnalystEconomic: () => import('../../components/Pages/Home/NewsAndAnalyst/Economic/index.vue' /* webpackChunkName: "components/home-news-and-analyst-economic" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketOverviewCover: () => import('../../components/Pages/MutualFund/Market/Overview/Cover.vue' /* webpackChunkName: "components/mutual-fund-market-overview-cover" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketOverviewNewList: () => import('../../components/Pages/MutualFund/Market/Overview/NewList.vue' /* webpackChunkName: "components/mutual-fund-market-overview-new-list" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketOverviewNewbie: () => import('../../components/Pages/MutualFund/Market/Overview/Newbie.vue' /* webpackChunkName: "components/mutual-fund-market-overview-newbie" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketOverviewTabs: () => import('../../components/Pages/MutualFund/Market/Overview/Tabs.vue' /* webpackChunkName: "components/mutual-fund-market-overview-tabs" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketOverviewThematic: () => import('../../components/Pages/MutualFund/Market/Overview/Thematic.vue' /* webpackChunkName: "components/mutual-fund-market-overview-thematic" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketOverviewTopRanking: () => import('../../components/Pages/MutualFund/Market/Overview/TopRanking.vue' /* webpackChunkName: "components/mutual-fund-market-overview-top-ranking" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketOverview: () => import('../../components/Pages/MutualFund/Market/Overview/index.vue' /* webpackChunkName: "components/mutual-fund-market-overview" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAAConsensusDailyUpdateArticlesFilter: () => import('../../components/Pages/Research/IAAConsensus/DailyUpdateArticles/Filter.vue' /* webpackChunkName: "components/research-i-a-a-consensus-daily-update-articles-filter" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAAConsensusDailyUpdateArticlesTable: () => import('../../components/Pages/Research/IAAConsensus/DailyUpdateArticles/Table.vue' /* webpackChunkName: "components/research-i-a-a-consensus-daily-update-articles-table" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAAConsensusGroupByBrokerFilter: () => import('../../components/Pages/Research/IAAConsensus/GroupByBroker/Filter.vue' /* webpackChunkName: "components/research-i-a-a-consensus-group-by-broker-filter" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAAConsensusGroupByBrokerTable: () => import('../../components/Pages/Research/IAAConsensus/GroupByBroker/Table.vue' /* webpackChunkName: "components/research-i-a-a-consensus-group-by-broker-table" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketComparison: () => import('../../components/Pages/MutualFund/Market/Comparison/Comparison.vue' /* webpackChunkName: "components/mutual-fund-market-comparison" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketComparisonFee: () => import('../../components/Pages/MutualFund/Market/Comparison/Fee.vue' /* webpackChunkName: "components/mutual-fund-market-comparison-fee" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketComparisonFilter: () => import('../../components/Pages/MutualFund/Market/Comparison/Filter.vue' /* webpackChunkName: "components/mutual-fund-market-comparison-filter" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketComparisonInformation: () => import('../../components/Pages/MutualFund/Market/Comparison/Information.vue' /* webpackChunkName: "components/mutual-fund-market-comparison-information" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketComparisonInvestmentAsset: () => import('../../components/Pages/MutualFund/Market/Comparison/InvestmentAsset.vue' /* webpackChunkName: "components/mutual-fund-market-comparison-investment-asset" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAAConsensusGroupBySectorFilter: () => import('../../components/Pages/Research/IAAConsensus/GroupBySector/Filter.vue' /* webpackChunkName: "components/research-i-a-a-consensus-group-by-sector-filter" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAAConsensusGroupBySectorTable: () => import('../../components/Pages/Research/IAAConsensus/GroupBySector/Table.vue' /* webpackChunkName: "components/research-i-a-a-consensus-group-by-sector-table" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAAConsensusNetProfitAndValuationFilter: () => import('../../components/Pages/Research/IAAConsensus/NetProfitAndValuation/Filter.vue' /* webpackChunkName: "components/research-i-a-a-consensus-net-profit-and-valuation-filter" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAAConsensusNetProfitAndValuationTable: () => import('../../components/Pages/Research/IAAConsensus/NetProfitAndValuation/Table.vue' /* webpackChunkName: "components/research-i-a-a-consensus-net-profit-and-valuation-table" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAAConsensusSet50FinancialRatioEstimationFilter: () => import('../../components/Pages/Research/IAAConsensus/Set50FinancialRatioEstimation/Filter.vue' /* webpackChunkName: "components/research-i-a-a-consensus-set50-financial-ratio-estimation-filter" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAAConsensusSet50FinancialRatioEstimationTable: () => import('../../components/Pages/Research/IAAConsensus/Set50FinancialRatioEstimation/Table.vue' /* webpackChunkName: "components/research-i-a-a-consensus-set50-financial-ratio-estimation-table" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAAConsensusSummaryOfAnalystConsensusFilter: () => import('../../components/Pages/Research/IAAConsensus/SummaryOfAnalystConsensus/Filter.vue' /* webpackChunkName: "components/research-i-a-a-consensus-summary-of-analyst-consensus-filter" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAAConsensusSummaryOfAnalystConsensusTable: () => import('../../components/Pages/Research/IAAConsensus/SummaryOfAnalystConsensus/Table.vue' /* webpackChunkName: "components/research-i-a-a-consensus-summary-of-analyst-consensus-table" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAAConsensusViewByProjectFilter: () => import('../../components/Pages/Research/IAAConsensus/ViewByProject/Filter.vue' /* webpackChunkName: "components/research-i-a-a-consensus-view-by-project-filter" */).then(c => wrapFunctional(c.default || c)),
  ResearchIAAConsensusViewByProjectTable: () => import('../../components/Pages/Research/IAAConsensus/ViewByProject/Table.vue' /* webpackChunkName: "components/research-i-a-a-consensus-view-by-project-table" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewMarketDataBlockTradeTable: () => import('../../components/Pages/Derivative/Overview/MarketData/BlockTrade/Table.vue' /* webpackChunkName: "components/derivative-overview-market-data-block-trade-table" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewMarketDataBlockTrade: () => import('../../components/Pages/Derivative/Overview/MarketData/BlockTrade/index.vue' /* webpackChunkName: "components/derivative-overview-market-data-block-trade" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewMarketDataHistoricalDataFilter: () => import('../../components/Pages/Derivative/Overview/MarketData/HistoricalData/Filter.vue' /* webpackChunkName: "components/derivative-overview-market-data-historical-data-filter" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewMarketDataHistoricalDataTable: () => import('../../components/Pages/Derivative/Overview/MarketData/HistoricalData/Table.vue' /* webpackChunkName: "components/derivative-overview-market-data-historical-data-table" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewMarketDataHistoricalData: () => import('../../components/Pages/Derivative/Overview/MarketData/HistoricalData/index.vue' /* webpackChunkName: "components/derivative-overview-market-data-historical-data" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewMarketDataInvestorType: () => import('../../components/Pages/Derivative/Overview/MarketData/InvestorType/index.vue' /* webpackChunkName: "components/derivative-overview-market-data-investor-type" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewMarketDataTradingQuotationBySeriesFilter: () => import('../../components/Pages/Derivative/Overview/MarketData/TradingQuotationBySeries/Filter.vue' /* webpackChunkName: "components/derivative-overview-market-data-trading-quotation-by-series-filter" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewMarketDataTradingQuotationBySeriesTable: () => import('../../components/Pages/Derivative/Overview/MarketData/TradingQuotationBySeries/Table.vue' /* webpackChunkName: "components/derivative-overview-market-data-trading-quotation-by-series-table" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewMarketDataTradingQuotationBySeriesTableFuture: () => import('../../components/Pages/Derivative/Overview/MarketData/TradingQuotationBySeries/TableFuture.vue' /* webpackChunkName: "components/derivative-overview-market-data-trading-quotation-by-series-table-future" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewMarketDataTradingQuotationBySeriesTableOptions: () => import('../../components/Pages/Derivative/Overview/MarketData/TradingQuotationBySeries/TableOptions.vue' /* webpackChunkName: "components/derivative-overview-market-data-trading-quotation-by-series-table-options" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewMarketDataTradingQuotationBySeries: () => import('../../components/Pages/Derivative/Overview/MarketData/TradingQuotationBySeries/index.vue' /* webpackChunkName: "components/derivative-overview-market-data-trading-quotation-by-series" */).then(c => wrapFunctional(c.default || c)),
  EquityETFPastPerformanceFromAdjustedPriceCalendar: () => import('../../components/Pages/Equity/ETF/PastPerformance/FromAdjustedPrice/Calendar.vue' /* webpackChunkName: "components/equity-e-t-f-past-performance-from-adjusted-price-calendar" */).then(c => wrapFunctional(c.default || c)),
  EquityETFPastPerformanceFromAdjustedPrice: () => import('../../components/Pages/Equity/ETF/PastPerformance/FromAdjustedPrice/FromAdjustedPrice.vue' /* webpackChunkName: "components/equity-e-t-f-past-performance-from-adjusted-price" */).then(c => wrapFunctional(c.default || c)),
  EquityETFPastPerformanceFromAdjustedPriceTimePeriod: () => import('../../components/Pages/Equity/ETF/PastPerformance/FromAdjustedPrice/TimePeriod.vue' /* webpackChunkName: "components/equity-e-t-f-past-performance-from-adjusted-price-time-period" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDRKnowLedge: () => import('../../components/Pages/Equity/Market/DR/KnowLedge/index.vue' /* webpackChunkName: "components/equity-market-d-r-know-ledge" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDROverviewNewsAndAnalystNew: () => import('../../components/Pages/Equity/Market/DR/Overview/NewsAndAnalystNew.vue' /* webpackChunkName: "components/equity-market-d-r-overview-news-and-analyst-new" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDROverview: () => import('../../components/Pages/Equity/Market/DR/Overview/index.vue' /* webpackChunkName: "components/equity-market-d-r-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDRXElementTableTrading: () => import('../../components/Pages/Equity/Market/DRX/Element/TableTrading.vue' /* webpackChunkName: "components/equity-market-d-r-x-element-table-trading" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDRXFullStockScreening: () => import('../../components/Pages/Equity/Market/DRX/Full/StockScreening.vue' /* webpackChunkName: "components/equity-market-d-r-x-full-stock-screening" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDRXFullTopRanking: () => import('../../components/Pages/Equity/Market/DRX/Full/TopRanking.vue' /* webpackChunkName: "components/equity-market-d-r-x-full-top-ranking" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDRXFull: () => import('../../components/Pages/Equity/Market/DRX/Full/index.vue' /* webpackChunkName: "components/equity-market-d-r-x-full" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDRXOverview: () => import('../../components/Pages/Equity/Market/DRX/Overview/index.vue' /* webpackChunkName: "components/equity-market-d-r-x-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWElementTableDataDW: () => import('../../components/Pages/Equity/Market/DW/Element/TableDataDW.vue' /* webpackChunkName: "components/equity-market-d-w-element-table-data-d-w" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWForeign: () => import('../../components/Pages/Equity/Market/DW/Foreign/Foreign.vue' /* webpackChunkName: "components/equity-market-d-w-foreign" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWForeignOverview: () => import('../../components/Pages/Equity/Market/DW/Foreign/Overview.vue' /* webpackChunkName: "components/equity-market-d-w-foreign-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWMiscNotation: () => import('../../components/Pages/Equity/Market/DW/Misc/Notation.vue' /* webpackChunkName: "components/equity-market-d-w-misc-notation" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWMiscNotationSmall: () => import('../../components/Pages/Equity/Market/DW/Misc/NotationSmall.vue' /* webpackChunkName: "components/equity-market-d-w-misc-notation-small" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWOverviewFilter: () => import('../../components/Pages/Equity/Market/DW/Overview/Filter.vue' /* webpackChunkName: "components/equity-market-d-w-overview-filter" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWOverviewGlobal: () => import('../../components/Pages/Equity/Market/DW/Overview/Global.vue' /* webpackChunkName: "components/equity-market-d-w-overview-global" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWOverviewMostActive: () => import('../../components/Pages/Equity/Market/DW/Overview/MostActive.vue' /* webpackChunkName: "components/equity-market-d-w-overview-most-active" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWOverviewNewList: () => import('../../components/Pages/Equity/Market/DW/Overview/NewList.vue' /* webpackChunkName: "components/equity-market-d-w-overview-new-list" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWOverview: () => import('../../components/Pages/Equity/Market/DW/Overview/Overview.vue' /* webpackChunkName: "components/equity-market-d-w-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWOverviewStatus: () => import('../../components/Pages/Equity/Market/DW/Overview/Status.vue' /* webpackChunkName: "components/equity-market-d-w-overview-status" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWOverviewTopRanking: () => import('../../components/Pages/Equity/Market/DW/Overview/TopRanking.vue' /* webpackChunkName: "components/equity-market-d-w-overview-top-ranking" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWOverviewTradingSummary: () => import('../../components/Pages/Equity/Market/DW/Overview/TradingSummary.vue' /* webpackChunkName: "components/equity-market-d-w-overview-trading-summary" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWStatisticsReportCardReports: () => import('../../components/Pages/Equity/Market/DW/StatisticsReport/CardReports.vue' /* webpackChunkName: "components/equity-market-d-w-statistics-report-card-reports" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWStatisticsReportModalNodata: () => import('../../components/Pages/Equity/Market/DW/StatisticsReport/ModalNodata.vue' /* webpackChunkName: "components/equity-market-d-w-statistics-report-modal-nodata" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWStatisticsReport: () => import('../../components/Pages/Equity/Market/DW/StatisticsReport/index.vue' /* webpackChunkName: "components/equity-market-d-w-statistics-report" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketETFElementTableDataETF: () => import('../../components/Pages/Equity/Market/ETF/Element/TableDataETF.vue' /* webpackChunkName: "components/equity-market-e-t-f-element-table-data-e-t-f" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketETFMiscThematicItem: () => import('../../components/Pages/Equity/Market/ETF/Misc/ThematicItem.vue' /* webpackChunkName: "components/equity-market-e-t-f-misc-thematic-item" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketETFMiscViewMore: () => import('../../components/Pages/Equity/Market/ETF/Misc/ViewMore.vue' /* webpackChunkName: "components/equity-market-e-t-f-misc-view-more" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketETFOverviewNews: () => import('../../components/Pages/Equity/Market/ETF/Overview/News.vue' /* webpackChunkName: "components/equity-market-e-t-f-overview-news" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketETFOverview: () => import('../../components/Pages/Equity/Market/ETF/Overview/Overview.vue' /* webpackChunkName: "components/equity-market-e-t-f-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketETFOverviewThematic: () => import('../../components/Pages/Equity/Market/ETF/Overview/Thematic.vue' /* webpackChunkName: "components/equity-market-e-t-f-overview-thematic" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketETFOverviewTopRanking: () => import('../../components/Pages/Equity/Market/ETF/Overview/TopRanking.vue' /* webpackChunkName: "components/equity-market-e-t-f-overview-top-ranking" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataOverviewMAITableAutoMatching: () => import('../../components/Pages/Equity/MarketData/Overview/MAI/TableAutoMatching.vue' /* webpackChunkName: "components/equity-market-data-overview-m-a-i-table-auto-matching" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataOverviewMAITableIndex: () => import('../../components/Pages/Equity/MarketData/Overview/MAI/TableIndex.vue' /* webpackChunkName: "components/equity-market-data-overview-m-a-i-table-index" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataOverviewMAITableIndustry: () => import('../../components/Pages/Equity/MarketData/Overview/MAI/TableIndustry.vue' /* webpackChunkName: "components/equity-market-data-overview-m-a-i-table-industry" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataOverviewMAITableOtherStock: () => import('../../components/Pages/Equity/MarketData/Overview/MAI/TableOtherStock.vue' /* webpackChunkName: "components/equity-market-data-overview-m-a-i-table-other-stock" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataOverviewMAITableTradingMethod: () => import('../../components/Pages/Equity/MarketData/Overview/MAI/TableTradingMethod.vue' /* webpackChunkName: "components/equity-market-data-overview-m-a-i-table-trading-method" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataOverviewSETTableAutoMatching: () => import('../../components/Pages/Equity/MarketData/Overview/SET/TableAutoMatching.vue' /* webpackChunkName: "components/equity-market-data-overview-s-e-t-table-auto-matching" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataOverviewSETTableCommonStock: () => import('../../components/Pages/Equity/MarketData/Overview/SET/TableCommonStock.vue' /* webpackChunkName: "components/equity-market-data-overview-s-e-t-table-common-stock" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataOverviewSETTableIndex: () => import('../../components/Pages/Equity/MarketData/Overview/SET/TableIndex.vue' /* webpackChunkName: "components/equity-market-data-overview-s-e-t-table-index" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataOverviewSETTableIndustry: () => import('../../components/Pages/Equity/MarketData/Overview/SET/TableIndustry.vue' /* webpackChunkName: "components/equity-market-data-overview-s-e-t-table-industry" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataOverviewSETTableIndustryAll: () => import('../../components/Pages/Equity/MarketData/Overview/SET/TableIndustryAll.vue' /* webpackChunkName: "components/equity-market-data-overview-s-e-t-table-industry-all" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataOverviewSETTableOtherStock: () => import('../../components/Pages/Equity/MarketData/Overview/SET/TableOtherStock.vue' /* webpackChunkName: "components/equity-market-data-overview-s-e-t-table-other-stock" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataOverviewSETTableSector: () => import('../../components/Pages/Equity/MarketData/Overview/SET/TableSector.vue' /* webpackChunkName: "components/equity-market-data-overview-s-e-t-table-sector" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataOverviewSETTableTradingMethod: () => import('../../components/Pages/Equity/MarketData/Overview/SET/TableTradingMethod.vue' /* webpackChunkName: "components/equity-market-data-overview-s-e-t-table-trading-method" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDataOverviewSETTableWithAccordion: () => import('../../components/Pages/Equity/MarketData/Overview/SET/TableWithAccordion.vue' /* webpackChunkName: "components/equity-market-data-overview-s-e-t-table-with-accordion" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonCompanyInformationBoardDirector: () => import('../../components/Pages/Equity/Stock/Common/CompanyInformation/BoardDirector.vue' /* webpackChunkName: "components/equity-stock-common-company-information-board-director" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonCompanyInformation: () => import('../../components/Pages/Equity/Stock/Common/CompanyInformation/CompanyInformation.vue' /* webpackChunkName: "components/equity-stock-common-company-information" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonCompanyInformationMajorShareholder: () => import('../../components/Pages/Equity/Stock/Common/CompanyInformation/MajorShareholder.vue' /* webpackChunkName: "components/equity-stock-common-company-information-major-shareholder" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonCompanyInformationOverview: () => import('../../components/Pages/Equity/Stock/Common/CompanyInformation/Overview.vue' /* webpackChunkName: "components/equity-stock-common-company-information-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonCompanyInformationReportIPO: () => import('../../components/Pages/Equity/Stock/Common/CompanyInformation/ReportIPO.vue' /* webpackChunkName: "components/equity-stock-common-company-information-report-i-p-o" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonFinancialStatement: () => import('../../components/Pages/Equity/Stock/Common/FinancialStatement/FinancialStatement.vue' /* webpackChunkName: "components/equity-stock-common-financial-statement" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonFinancialStatementFiveYear: () => import('../../components/Pages/Equity/Stock/Common/FinancialStatement/FiveYear.vue' /* webpackChunkName: "components/equity-stock-common-financial-statement-five-year" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonFinancialStatementFull: () => import('../../components/Pages/Equity/Stock/Common/FinancialStatement/Full.vue' /* webpackChunkName: "components/equity-stock-common-financial-statement-full" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonFinancialStatementLatest: () => import('../../components/Pages/Equity/Stock/Common/FinancialStatement/Latest.vue' /* webpackChunkName: "components/equity-stock-common-financial-statement-latest" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonForeignCompanyInformationBoardDirector: () => import('../../components/Pages/Equity/Stock/CommonForeign/CompanyInformation/BoardDirector.vue' /* webpackChunkName: "components/equity-stock-common-foreign-company-information-board-director" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonForeignCompanyInformation: () => import('../../components/Pages/Equity/Stock/CommonForeign/CompanyInformation/CompanyInformation.vue' /* webpackChunkName: "components/equity-stock-common-foreign-company-information" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonForeignCompanyInformationMajorShareholder: () => import('../../components/Pages/Equity/Stock/CommonForeign/CompanyInformation/MajorShareholder.vue' /* webpackChunkName: "components/equity-stock-common-foreign-company-information-major-shareholder" */).then(c => wrapFunctional(c.default || c)),
  EquityStockCommonForeignCompanyInformationOverview: () => import('../../components/Pages/Equity/Stock/CommonForeign/CompanyInformation/Overview.vue' /* webpackChunkName: "components/equity-stock-common-foreign-company-information-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityStockPreferredCompanyInformationBoardDirector: () => import('../../components/Pages/Equity/Stock/Preferred/CompanyInformation/BoardDirector.vue' /* webpackChunkName: "components/equity-stock-preferred-company-information-board-director" */).then(c => wrapFunctional(c.default || c)),
  EquityStockPreferredCompanyInformation: () => import('../../components/Pages/Equity/Stock/Preferred/CompanyInformation/CompanyInformation.vue' /* webpackChunkName: "components/equity-stock-preferred-company-information" */).then(c => wrapFunctional(c.default || c)),
  EquityStockPreferredCompanyInformationMajorShareholder: () => import('../../components/Pages/Equity/Stock/Preferred/CompanyInformation/MajorShareholder.vue' /* webpackChunkName: "components/equity-stock-preferred-company-information-major-shareholder" */).then(c => wrapFunctional(c.default || c)),
  EquityStockPreferredCompanyInformationOverview: () => import('../../components/Pages/Equity/Stock/Preferred/CompanyInformation/Overview.vue' /* webpackChunkName: "components/equity-stock-preferred-company-information-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityStockPreferredForeignCompanyInformationBoardDirector: () => import('../../components/Pages/Equity/Stock/PreferredForeign/CompanyInformation/BoardDirector.vue' /* webpackChunkName: "components/equity-stock-preferred-foreign-company-information-board-director" */).then(c => wrapFunctional(c.default || c)),
  EquityStockPreferredForeignCompanyInformation: () => import('../../components/Pages/Equity/Stock/PreferredForeign/CompanyInformation/CompanyInformation.vue' /* webpackChunkName: "components/equity-stock-preferred-foreign-company-information" */).then(c => wrapFunctional(c.default || c)),
  EquityStockPreferredForeignCompanyInformationMajorShareholder: () => import('../../components/Pages/Equity/Stock/PreferredForeign/CompanyInformation/MajorShareholder.vue' /* webpackChunkName: "components/equity-stock-preferred-foreign-company-information-major-shareholder" */).then(c => wrapFunctional(c.default || c)),
  EquityStockPreferredForeignCompanyInformationOverview: () => import('../../components/Pages/Equity/Stock/PreferredForeign/CompanyInformation/Overview.vue' /* webpackChunkName: "components/equity-stock-preferred-foreign-company-information-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityStockUnitTrustCompanyCover: () => import('../../components/Pages/Equity/Stock/UnitTrust/CompanyInformation/CompanyCover.vue' /* webpackChunkName: "components/equity-stock-unit-trust-company-cover" */).then(c => wrapFunctional(c.default || c)),
  EquityStockUnitTrustCompanyDetails: () => import('../../components/Pages/Equity/Stock/UnitTrust/CompanyInformation/CompanyDetails.vue' /* webpackChunkName: "components/equity-stock-unit-trust-company-details" */).then(c => wrapFunctional(c.default || c)),
  EquityStockUnitTrustCompanyInformation: () => import('../../components/Pages/Equity/Stock/UnitTrust/CompanyInformation/CompanyInformation.vue' /* webpackChunkName: "components/equity-stock-unit-trust-company-information" */).then(c => wrapFunctional(c.default || c)),
  EquityStockWarrantCompanyInformationBoardDirector: () => import('../../components/Pages/Equity/Stock/Warrant/CompanyInformation/BoardDirector.vue' /* webpackChunkName: "components/equity-stock-warrant-company-information-board-director" */).then(c => wrapFunctional(c.default || c)),
  EquityStockWarrantCompanyInformation: () => import('../../components/Pages/Equity/Stock/Warrant/CompanyInformation/CompanyInformation.vue' /* webpackChunkName: "components/equity-stock-warrant-company-information" */).then(c => wrapFunctional(c.default || c)),
  EquityStockWarrantCompanyInformationOverview: () => import('../../components/Pages/Equity/Stock/Warrant/CompanyInformation/Overview.vue' /* webpackChunkName: "components/equity-stock-warrant-company-information-overview" */).then(c => wrapFunctional(c.default || c)),
  EquityStockWarrantInformation: () => import('../../components/Pages/Equity/Stock/Warrant/CompanyInformation/WarrantInformation.vue' /* webpackChunkName: "components/equity-stock-warrant-information" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioDerivativeInvestmentAssetHistory: () => import('../../components/Pages/Member/VirtualPortfolio/Derivative/InvestmentAsset/History.vue' /* webpackChunkName: "components/member-virtual-portfolio-derivative-investment-asset-history" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioDerivativeInvestmentAssetMyPort: () => import('../../components/Pages/Member/VirtualPortfolio/Derivative/InvestmentAsset/MyPort.vue' /* webpackChunkName: "components/member-virtual-portfolio-derivative-investment-asset-my-port" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioDerivativeInvestmentAssetNotation: () => import('../../components/Pages/Member/VirtualPortfolio/Derivative/InvestmentAsset/Notation.vue' /* webpackChunkName: "components/member-virtual-portfolio-derivative-investment-asset-notation" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioDerivativeInvestmentAssetPerformance: () => import('../../components/Pages/Member/VirtualPortfolio/Derivative/InvestmentAsset/Performance.vue' /* webpackChunkName: "components/member-virtual-portfolio-derivative-investment-asset-performance" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioDerivativeInvestmentAssetShowAndHideContent: () => import('../../components/Pages/Member/VirtualPortfolio/Derivative/InvestmentAsset/ShowAndHideContent.vue' /* webpackChunkName: "components/member-virtual-portfolio-derivative-investment-asset-show-and-hide-content" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioDerivativeInvestmentAssetTable: () => import('../../components/Pages/Member/VirtualPortfolio/Derivative/InvestmentAsset/Table.vue' /* webpackChunkName: "components/member-virtual-portfolio-derivative-investment-asset-table" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioMutualFundInvestmentAssetHistory: () => import('../../components/Pages/Member/VirtualPortfolio/MutualFund/InvestmentAsset/History.vue' /* webpackChunkName: "components/member-virtual-portfolio-mutual-fund-investment-asset-history" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioMutualFundInvestmentAssetMyPort: () => import('../../components/Pages/Member/VirtualPortfolio/MutualFund/InvestmentAsset/MyPort.vue' /* webpackChunkName: "components/member-virtual-portfolio-mutual-fund-investment-asset-my-port" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioMutualFundInvestmentAssetPerformance: () => import('../../components/Pages/Member/VirtualPortfolio/MutualFund/InvestmentAsset/Performance.vue' /* webpackChunkName: "components/member-virtual-portfolio-mutual-fund-investment-asset-performance" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioStockAssetAllocationBySector: () => import('../../components/Pages/Member/VirtualPortfolio/Stock/AssetAllocation/BySector.vue' /* webpackChunkName: "components/member-virtual-portfolio-stock-asset-allocation-by-sector" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioStockAssetAllocationByStock: () => import('../../components/Pages/Member/VirtualPortfolio/Stock/AssetAllocation/ByStock.vue' /* webpackChunkName: "components/member-virtual-portfolio-stock-asset-allocation-by-stock" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioStockAssetAllocationByStockTable: () => import('../../components/Pages/Member/VirtualPortfolio/Stock/AssetAllocation/ByStockTable.vue' /* webpackChunkName: "components/member-virtual-portfolio-stock-asset-allocation-by-stock-table" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioStockInvestmentAssetHistory: () => import('../../components/Pages/Member/VirtualPortfolio/Stock/InvestmentAsset/History.vue' /* webpackChunkName: "components/member-virtual-portfolio-stock-investment-asset-history" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioStockInvestmentAssetMyPort: () => import('../../components/Pages/Member/VirtualPortfolio/Stock/InvestmentAsset/MyPort.vue' /* webpackChunkName: "components/member-virtual-portfolio-stock-investment-asset-my-port" */).then(c => wrapFunctional(c.default || c)),
  MemberVirtualPortfolioStockInvestmentAssetPerformance: () => import('../../components/Pages/Member/VirtualPortfolio/Stock/InvestmentAsset/Performance.vue' /* webpackChunkName: "components/member-virtual-portfolio-stock-investment-asset-performance" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketComparisonPerformanceChart: () => import('../../components/Pages/MutualFund/Market/Comparison/Performance/Chart.vue' /* webpackChunkName: "components/mutual-fund-market-comparison-performance-chart" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketComparisonPerformanceInfo: () => import('../../components/Pages/MutualFund/Market/Comparison/Performance/Info.vue' /* webpackChunkName: "components/mutual-fund-market-comparison-performance-info" */).then(c => wrapFunctional(c.default || c)),
  MutualFundMarketComparisonPerformance: () => import('../../components/Pages/MutualFund/Market/Comparison/Performance/Performance.vue' /* webpackChunkName: "components/mutual-fund-market-comparison-performance" */).then(c => wrapFunctional(c.default || c)),
  DerivativeOverviewMarketDataTradingQuotationBySeriesMiscNotation: () => import('../../components/Pages/Derivative/Overview/MarketData/TradingQuotationBySeries/Misc/Notation.vue' /* webpackChunkName: "components/derivative-overview-market-data-trading-quotation-by-series-misc-notation" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWForeignKnowledgeExampleCalculator: () => import('../../components/Pages/Equity/Market/DW/Foreign/Knowledge/ExampleCalculator.vue' /* webpackChunkName: "components/equity-market-d-w-foreign-knowledge-example-calculator" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWForeignKnowledgeGlobal: () => import('../../components/Pages/Equity/Market/DW/Foreign/Knowledge/Global.vue' /* webpackChunkName: "components/equity-market-d-w-foreign-knowledge-global" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWForeignKnowledgeIssuer: () => import('../../components/Pages/Equity/Market/DW/Foreign/Knowledge/Issuer.vue' /* webpackChunkName: "components/equity-market-d-w-foreign-knowledge-issuer" */).then(c => wrapFunctional(c.default || c)),
  EquityMarketDWForeignKnowledge: () => import('../../components/Pages/Equity/Market/DW/Foreign/Knowledge/Knowledge.vue' /* webpackChunkName: "components/equity-market-d-w-foreign-knowledge" */).then(c => wrapFunctional(c.default || c)),
  ContentLazy: () => import('../../components/ContentLazy.vue' /* webpackChunkName: "components/content-lazy" */).then(c => wrapFunctional(c.default || c)),
  Divider: () => import('../../components/Divider.vue' /* webpackChunkName: "components/divider" */).then(c => wrapFunctional(c.default || c)),
  GoTop: () => import('../../components/GoTop.vue' /* webpackChunkName: "components/go-top" */).then(c => wrapFunctional(c.default || c)),
  Logo: () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c)),
  PDFViewer: () => import('../../components/PDFViewer.vue' /* webpackChunkName: "components/p-d-f-viewer" */).then(c => wrapFunctional(c.default || c)),
  RawHtml: () => import('../../components/RawHtml.vue' /* webpackChunkName: "components/raw-html" */).then(c => wrapFunctional(c.default || c)),
  SocialShare: () => import('../../components/SocialShare.vue' /* webpackChunkName: "components/social-share" */).then(c => wrapFunctional(c.default || c)),
  StickyBar: () => import('../../components/StickyBar.vue' /* webpackChunkName: "components/sticky-bar" */).then(c => wrapFunctional(c.default || c)),
  StringToHtml: () => import('../../components/StringToHtml.vue' /* webpackChunkName: "components/string-to-html" */).then(c => wrapFunctional(c.default || c)),
  SymbolSign: () => import('../../components/SymbolSign.vue' /* webpackChunkName: "components/symbol-sign" */).then(c => wrapFunctional(c.default || c)),
  Advertisement: () => import('../../components/Advertisement/Advertisement.vue' /* webpackChunkName: "components/advertisement" */).then(c => wrapFunctional(c.default || c)),
  AdvertisementFloating: () => import('../../components/Advertisement/Floating.vue' /* webpackChunkName: "components/advertisement-floating" */).then(c => wrapFunctional(c.default || c)),
  AdvertisementLightbox: () => import('../../components/Advertisement/Lightbox.vue' /* webpackChunkName: "components/advertisement-lightbox" */).then(c => wrapFunctional(c.default || c)),
  Accordion: () => import('../../components/Accordion/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c)),
  AccordionItem: () => import('../../components/Accordion/Item.vue' /* webpackChunkName: "components/accordion-item" */).then(c => wrapFunctional(c.default || c)),
  Breadcrumb: () => import('../../components/Breadcrumb/Breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c)),
  BreadcrumbItem: () => import('../../components/Breadcrumb/Item.vue' /* webpackChunkName: "components/breadcrumb-item" */).then(c => wrapFunctional(c.default || c)),
  BannerAdvertisement: () => import('../../components/Banner/Advertisement.vue' /* webpackChunkName: "components/banner-advertisement" */).then(c => wrapFunctional(c.default || c)),
  Banner: () => import('../../components/Banner/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c)),
  BannerItems: () => import('../../components/Banner/BannerItems.vue' /* webpackChunkName: "components/banner-items" */).then(c => wrapFunctional(c.default || c)),
  BannerPersonalized: () => import('../../components/Banner/Personalized.vue' /* webpackChunkName: "components/banner-personalized" */).then(c => wrapFunctional(c.default || c)),
  BannerStandard: () => import('../../components/Banner/Standard.vue' /* webpackChunkName: "components/banner-standard" */).then(c => wrapFunctional(c.default || c)),
  AppBar: () => import('../../components/AppBar/AppBar.vue' /* webpackChunkName: "components/app-bar" */).then(c => wrapFunctional(c.default || c)),
  AppBarNav: () => import('../../components/AppBar/Nav.vue' /* webpackChunkName: "components/app-bar-nav" */).then(c => wrapFunctional(c.default || c)),
  AppBarSearchBox: () => import('../../components/AppBar/SearchBox.vue' /* webpackChunkName: "components/app-bar-search-box" */).then(c => wrapFunctional(c.default || c)),
  AppBarShortcut: () => import('../../components/AppBar/Shortcut.vue' /* webpackChunkName: "components/app-bar-shortcut" */).then(c => wrapFunctional(c.default || c)),
  AppBarSubMenu: () => import('../../components/AppBar/SubMenu.vue' /* webpackChunkName: "components/app-bar-sub-menu" */).then(c => wrapFunctional(c.default || c)),
  ButtonBackToTopOnMobile: () => import('../../components/Button/BackToTopOnMobile.vue' /* webpackChunkName: "components/button-back-to-top-on-mobile" */).then(c => wrapFunctional(c.default || c)),
  Button: () => import('../../components/Button/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c)),
  ButtonCheckbox: () => import('../../components/Button/ButtonCheckbox.vue' /* webpackChunkName: "components/button-checkbox" */).then(c => wrapFunctional(c.default || c)),
  ButtonFactsheet: () => import('../../components/Button/ButtonFactsheet.vue' /* webpackChunkName: "components/button-factsheet" */).then(c => wrapFunctional(c.default || c)),
  ButtonFiling: () => import('../../components/Button/ButtonFiling.vue' /* webpackChunkName: "components/button-filing" */).then(c => wrapFunctional(c.default || c)),
  ButtonManualGuides: () => import('../../components/Button/ButtonManualGuides.vue' /* webpackChunkName: "components/button-manual-guides" */).then(c => wrapFunctional(c.default || c)),
  ButtonQuote: () => import('../../components/Button/ButtonQuote.vue' /* webpackChunkName: "components/button-quote" */).then(c => wrapFunctional(c.default || c)),
  ButtonQuoteAccordion: () => import('../../components/Button/ButtonQuoteAccordion.vue' /* webpackChunkName: "components/button-quote-accordion" */).then(c => wrapFunctional(c.default || c)),
  ButtonRemoveSymbol: () => import('../../components/Button/ButtonRemoveSymbol.vue' /* webpackChunkName: "components/button-remove-symbol" */).then(c => wrapFunctional(c.default || c)),
  ButtonSetSmart: () => import('../../components/Button/ButtonSetSmart.vue' /* webpackChunkName: "components/button-set-smart" */).then(c => wrapFunctional(c.default || c)),
  ButtonUpcoming: () => import('../../components/Button/ButtonUpcoming.vue' /* webpackChunkName: "components/button-upcoming" */).then(c => wrapFunctional(c.default || c)),
  ButtonCloseModal: () => import('../../components/Button/CloseModal.vue' /* webpackChunkName: "components/button-close-modal" */).then(c => wrapFunctional(c.default || c)),
  ButtonCustomize: () => import('../../components/Button/Customize.vue' /* webpackChunkName: "components/button-customize" */).then(c => wrapFunctional(c.default || c)),
  ButtonExpandCollapse: () => import('../../components/Button/ExpandCollapse.vue' /* webpackChunkName: "components/button-expand-collapse" */).then(c => wrapFunctional(c.default || c)),
  ButtonExportExcel: () => import('../../components/Button/ExportExcel.vue' /* webpackChunkName: "components/button-export-excel" */).then(c => wrapFunctional(c.default || c)),
  ButtonFavorite: () => import('../../components/Button/Favorite.vue' /* webpackChunkName: "components/button-favorite" */).then(c => wrapFunctional(c.default || c)),
  ButtonLinkOut: () => import('../../components/Button/LinkOut.vue' /* webpackChunkName: "components/button-link-out" */).then(c => wrapFunctional(c.default || c)),
  ButtonResearch: () => import('../../components/Button/Research.vue' /* webpackChunkName: "components/button-research" */).then(c => wrapFunctional(c.default || c)),
  ButtonShare: () => import('../../components/Button/Share.vue' /* webpackChunkName: "components/button-share" */).then(c => wrapFunctional(c.default || c)),
  ButtonShareExternal: () => import('../../components/Button/ShareExternal.vue' /* webpackChunkName: "components/button-share-external" */).then(c => wrapFunctional(c.default || c)),
  ButtonStreaming: () => import('../../components/Button/Streaming.vue' /* webpackChunkName: "components/button-streaming" */).then(c => wrapFunctional(c.default || c)),
  ButtonTabPill: () => import('../../components/Button/TabPill.vue' /* webpackChunkName: "components/button-tab-pill" */).then(c => wrapFunctional(c.default || c)),
  ButtonViewMore: () => import('../../components/Button/ViewMore.vue' /* webpackChunkName: "components/button-view-more" */).then(c => wrapFunctional(c.default || c)),
  ButtonViewMoreGradient: () => import('../../components/Button/ViewMoreGradient.vue' /* webpackChunkName: "components/button-view-more-gradient" */).then(c => wrapFunctional(c.default || c)),
  ButtonVirtualPortfolio: () => import('../../components/Button/VirtualPortfolio.vue' /* webpackChunkName: "components/button-virtual-portfolio" */).then(c => wrapFunctional(c.default || c)),
  CPopover: () => import('../../components/CPopover/CPopover.vue' /* webpackChunkName: "components/c-popover" */).then(c => wrapFunctional(c.default || c)),
  CalendarStock: () => import('../../components/Calendar/Stock.vue' /* webpackChunkName: "components/calendar-stock" */).then(c => wrapFunctional(c.default || c)),
  Calendar: () => import('../../components/Calendar/index.vue' /* webpackChunkName: "components/calendar" */).then(c => wrapFunctional(c.default || c)),
  Card: () => import('../../components/Card/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c)),
  CardContent: () => import('../../components/Card/CardContent.vue' /* webpackChunkName: "components/card-content" */).then(c => wrapFunctional(c.default || c)),
  CardFilter: () => import('../../components/Card/CardFilter.vue' /* webpackChunkName: "components/card-filter" */).then(c => wrapFunctional(c.default || c)),
  CardFtseSet: () => import('../../components/Card/CardFtseSet.vue' /* webpackChunkName: "components/card-ftse-set" */).then(c => wrapFunctional(c.default || c)),
  CardIndustry: () => import('../../components/Card/CardIndustry.vue' /* webpackChunkName: "components/card-industry" */).then(c => wrapFunctional(c.default || c)),
  CardMarketIndex: () => import('../../components/Card/CardMarketIndex.vue' /* webpackChunkName: "components/card-market-index" */).then(c => wrapFunctional(c.default || c)),
  Chart2HomeStockCompare: () => import('../../components/Chart2/HomeStockCompare.vue' /* webpackChunkName: "components/chart2-home-stock-compare" */).then(c => wrapFunctional(c.default || c)),
  Chart2LineMarket: () => import('../../components/Chart2/LineMarket.vue' /* webpackChunkName: "components/chart2-line-market" */).then(c => wrapFunctional(c.default || c)),
  Chart2MicroLine: () => import('../../components/Chart2/MicroLine.vue' /* webpackChunkName: "components/chart2-micro-line" */).then(c => wrapFunctional(c.default || c)),
  Chart2StockCompare: () => import('../../components/Chart2/StockCompare.vue' /* webpackChunkName: "components/chart2-stock-compare" */).then(c => wrapFunctional(c.default || c)),
  ChartBarDwCallPut: () => import('../../components/Chart/BarDwCallPut.vue' /* webpackChunkName: "components/chart-bar-dw-call-put" */).then(c => wrapFunctional(c.default || c)),
  ChartBarDwValue: () => import('../../components/Chart/BarDwValue.vue' /* webpackChunkName: "components/chart-bar-dw-value" */).then(c => wrapFunctional(c.default || c)),
  ChartClusterColumn: () => import('../../components/Chart/ClusterColumn.vue' /* webpackChunkName: "components/chart-cluster-column" */).then(c => wrapFunctional(c.default || c)),
  ChartColumn: () => import('../../components/Chart/Column.vue' /* webpackChunkName: "components/chart-column" */).then(c => wrapFunctional(c.default || c)),
  ChartComparison: () => import('../../components/Chart/Comparison.vue' /* webpackChunkName: "components/chart-comparison" */).then(c => wrapFunctional(c.default || c)),
  ChartDerivative: () => import('../../components/Chart/Derivative.vue' /* webpackChunkName: "components/chart-derivative" */).then(c => wrapFunctional(c.default || c)),
  ChartDerivativeLine: () => import('../../components/Chart/DerivativeLine.vue' /* webpackChunkName: "components/chart-derivative-line" */).then(c => wrapFunctional(c.default || c)),
  ChartDonut: () => import('../../components/Chart/Donut.vue' /* webpackChunkName: "components/chart-donut" */).then(c => wrapFunctional(c.default || c)),
  ChartETFPerformanceCalendar: () => import('../../components/Chart/ETFPerformanceCalendar.vue' /* webpackChunkName: "components/chart-e-t-f-performance-calendar" */).then(c => wrapFunctional(c.default || c)),
  ChartGroupColumn: () => import('../../components/Chart/GroupColumn.vue' /* webpackChunkName: "components/chart-group-column" */).then(c => wrapFunctional(c.default || c)),
  ChartHomeStockCompare: () => import('../../components/Chart/HomeStockCompare.vue' /* webpackChunkName: "components/chart-home-stock-compare" */).then(c => wrapFunctional(c.default || c)),
  ChartIAA: () => import('../../components/Chart/IAA.vue' /* webpackChunkName: "components/chart-i-a-a" */).then(c => wrapFunctional(c.default || c)),
  ChartIAATfex: () => import('../../components/Chart/IAATfex.vue' /* webpackChunkName: "components/chart-i-a-a-tfex" */).then(c => wrapFunctional(c.default || c)),
  ChartIndexRetro: () => import('../../components/Chart/IndexRetro.vue' /* webpackChunkName: "components/chart-index-retro" */).then(c => wrapFunctional(c.default || c)),
  ChartLineDr: () => import('../../components/Chart/LineDr.vue' /* webpackChunkName: "components/chart-line-dr" */).then(c => wrapFunctional(c.default || c)),
  ChartLineIAA: () => import('../../components/Chart/LineIAA.vue' /* webpackChunkName: "components/chart-line-i-a-a" */).then(c => wrapFunctional(c.default || c)),
  ChartLineMarket: () => import('../../components/Chart/LineMarket.vue' /* webpackChunkName: "components/chart-line-market" */).then(c => wrapFunctional(c.default || c)),
  ChartLineSet: () => import('../../components/Chart/LineSet.vue' /* webpackChunkName: "components/chart-line-set" */).then(c => wrapFunctional(c.default || c)),
  ChartLineTRI: () => import('../../components/Chart/LineTRI.vue' /* webpackChunkName: "components/chart-line-t-r-i" */).then(c => wrapFunctional(c.default || c)),
  ChartLoading: () => import('../../components/Chart/Loading.vue' /* webpackChunkName: "components/chart-loading" */).then(c => wrapFunctional(c.default || c)),
  ChartMarketDataCompare: () => import('../../components/Chart/MarketDataCompare.vue' /* webpackChunkName: "components/chart-market-data-compare" */).then(c => wrapFunctional(c.default || c)),
  ChartMicroLine: () => import('../../components/Chart/MicroLine.vue' /* webpackChunkName: "components/chart-micro-line" */).then(c => wrapFunctional(c.default || c)),
  ChartMultipleLine: () => import('../../components/Chart/MultipleLine.vue' /* webpackChunkName: "components/chart-multiple-line" */).then(c => wrapFunctional(c.default || c)),
  ChartPie: () => import('../../components/Chart/Pie.vue' /* webpackChunkName: "components/chart-pie" */).then(c => wrapFunctional(c.default || c)),
  ChartPricePerformance: () => import('../../components/Chart/PricePerformance.vue' /* webpackChunkName: "components/chart-price-performance" */).then(c => wrapFunctional(c.default || c)),
  ChartRadar: () => import('../../components/Chart/Radar.vue' /* webpackChunkName: "components/chart-radar" */).then(c => wrapFunctional(c.default || c)),
  ChartStock: () => import('../../components/Chart/Stock.vue' /* webpackChunkName: "components/chart-stock" */).then(c => wrapFunctional(c.default || c)),
  ChartStockCompare: () => import('../../components/Chart/StockCompare.vue' /* webpackChunkName: "components/chart-stock-compare" */).then(c => wrapFunctional(c.default || c)),
  ChartStockDRx: () => import('../../components/Chart/StockDRx.vue' /* webpackChunkName: "components/chart-stock-d-rx" */).then(c => wrapFunctional(c.default || c)),
  ChartStockLine: () => import('../../components/Chart/StockLine.vue' /* webpackChunkName: "components/chart-stock-line" */).then(c => wrapFunctional(c.default || c)),
  ChartStockLineDRx: () => import('../../components/Chart/StockLineDRx.vue' /* webpackChunkName: "components/chart-stock-line-d-rx" */).then(c => wrapFunctional(c.default || c)),
  ChartTVWidget: () => import('../../components/Chart/TVWidget.vue' /* webpackChunkName: "components/chart-t-v-widget" */).then(c => wrapFunctional(c.default || c)),
  ChatBot: () => import('../../components/Chat/Bot.vue' /* webpackChunkName: "components/chat-bot" */).then(c => wrapFunctional(c.default || c)),
  ChatBotIcon: () => import('../../components/Chat/BotIcon.vue' /* webpackChunkName: "components/chat-bot-icon" */).then(c => wrapFunctional(c.default || c)),
  ChatBotPanel: () => import('../../components/Chat/BotPanel.vue' /* webpackChunkName: "components/chat-bot-panel" */).then(c => wrapFunctional(c.default || c)),
  Chat: () => import('../../components/Chat/Chat.vue' /* webpackChunkName: "components/chat" */).then(c => wrapFunctional(c.default || c)),
  ChatMediaPopup: () => import('../../components/Chat/ChatMediaPopup.vue' /* webpackChunkName: "components/chat-media-popup" */).then(c => wrapFunctional(c.default || c)),
  ChatFacebookChat: () => import('../../components/Chat/FacebookChat.vue' /* webpackChunkName: "components/chat-facebook-chat" */).then(c => wrapFunctional(c.default || c)),
  ChatBotZwizBull: () => import('../../components/ChatBotZwiz/Bull.vue' /* webpackChunkName: "components/chat-bot-zwiz-bull" */).then(c => wrapFunctional(c.default || c)),
  ChatBotZwizPersonalizedPopup: () => import('../../components/ChatBotZwiz/PersonalizedPopup.vue' /* webpackChunkName: "components/chat-bot-zwiz-personalized-popup" */).then(c => wrapFunctional(c.default || c)),
  ChatBotZwiz: () => import('../../components/ChatBotZwiz/index.vue' /* webpackChunkName: "components/chat-bot-zwiz" */).then(c => wrapFunctional(c.default || c)),
  ContentChild: () => import('../../components/Content/Child.vue' /* webpackChunkName: "components/content-child" */).then(c => wrapFunctional(c.default || c)),
  Content: () => import('../../components/Content/Content.vue' /* webpackChunkName: "components/content" */).then(c => wrapFunctional(c.default || c)),
  ContentDynamic: () => import('../../components/Content/Dynamic.vue' /* webpackChunkName: "components/content-dynamic" */).then(c => wrapFunctional(c.default || c)),
  ContentElement: () => import('../../components/Content/Element.vue' /* webpackChunkName: "components/content-element" */).then(c => wrapFunctional(c.default || c)),
  ContentPreview: () => import('../../components/Content/Preview.vue' /* webpackChunkName: "components/content-preview" */).then(c => wrapFunctional(c.default || c)),
  ContentTabs: () => import('../../components/Content/Tabs.vue' /* webpackChunkName: "components/content-tabs" */).then(c => wrapFunctional(c.default || c)),
  ContentCardGrid: () => import('../../components/ContentCard/Grid.vue' /* webpackChunkName: "components/content-card-grid" */).then(c => wrapFunctional(c.default || c)),
  ContentCardItem: () => import('../../components/ContentCard/Item.vue' /* webpackChunkName: "components/content-card-item" */).then(c => wrapFunctional(c.default || c)),
  ContentCardSlide: () => import('../../components/ContentCard/Slide.vue' /* webpackChunkName: "components/content-card-slide" */).then(c => wrapFunctional(c.default || c)),
  ContentCard: () => import('../../components/ContentCard/index.vue' /* webpackChunkName: "components/content-card" */).then(c => wrapFunctional(c.default || c)),
  Cover: () => import('../../components/Cover/Cover.vue' /* webpackChunkName: "components/cover" */).then(c => wrapFunctional(c.default || c)),
  CoverCustomUnused: () => import('../../components/Cover/CoverCustomUnused.vue' /* webpackChunkName: "components/cover-custom-unused" */).then(c => wrapFunctional(c.default || c)),
  CoverQuote: () => import('../../components/Cover/CoverQuote.vue' /* webpackChunkName: "components/cover-quote" */).then(c => wrapFunctional(c.default || c)),
  CoverEquity: () => import('../../components/Cover/Equity.vue' /* webpackChunkName: "components/cover-equity" */).then(c => wrapFunctional(c.default || c)),
  CoverGetQuote: () => import('../../components/Cover/GetQuote.vue' /* webpackChunkName: "components/cover-get-quote" */).then(c => wrapFunctional(c.default || c)),
  CoverHome: () => import('../../components/Cover/Home.vue' /* webpackChunkName: "components/cover-home" */).then(c => wrapFunctional(c.default || c)),
  CoverPage: () => import('../../components/Cover/Page.vue' /* webpackChunkName: "components/cover-page" */).then(c => wrapFunctional(c.default || c)),
  DropdownDropdowSET: () => import('../../components/Dropdown/DropdowSET.vue' /* webpackChunkName: "components/dropdown-dropdow-s-e-t" */).then(c => wrapFunctional(c.default || c)),
  Dropdown: () => import('../../components/Dropdown/Dropdown.vue' /* webpackChunkName: "components/dropdown" */).then(c => wrapFunctional(c.default || c)),
  DropdownComparison: () => import('../../components/Dropdown/DropdownComparison.vue' /* webpackChunkName: "components/dropdown-comparison" */).then(c => wrapFunctional(c.default || c)),
  DropdownSearch: () => import('../../components/Dropdown/DropdownSearch.vue' /* webpackChunkName: "components/dropdown-search" */).then(c => wrapFunctional(c.default || c)),
  DropdownSearchAdvance: () => import('../../components/Dropdown/DropdownSearchAdvance.vue' /* webpackChunkName: "components/dropdown-search-advance" */).then(c => wrapFunctional(c.default || c)),
  DropdownXClenda: () => import('../../components/Dropdown/DropdownXClenda.vue' /* webpackChunkName: "components/dropdown-x-clenda" */).then(c => wrapFunctional(c.default || c)),
  DropdownItem: () => import('../../components/Dropdown/Item.vue' /* webpackChunkName: "components/dropdown-item" */).then(c => wrapFunctional(c.default || c)),
  DropdownLanguage: () => import('../../components/Dropdown/Language.vue' /* webpackChunkName: "components/dropdown-language" */).then(c => wrapFunctional(c.default || c)),
  DropdownShortcut: () => import('../../components/DropdownShortcut/DropdownShortcut.vue' /* webpackChunkName: "components/dropdown-shortcut" */).then(c => wrapFunctional(c.default || c)),
  DropdownShortcutV1: () => import('../../components/DropdownShortcut/DropdownShortcutV1.vue' /* webpackChunkName: "components/dropdown-shortcut-v1" */).then(c => wrapFunctional(c.default || c)),
  ElementAccordion: () => import('../../components/Element/Accordion.vue' /* webpackChunkName: "components/element-accordion" */).then(c => wrapFunctional(c.default || c)),
  ElementAuthor: () => import('../../components/Element/Author.vue' /* webpackChunkName: "components/element-author" */).then(c => wrapFunctional(c.default || c)),
  ElementBanner: () => import('../../components/Element/Banner.vue' /* webpackChunkName: "components/element-banner" */).then(c => wrapFunctional(c.default || c)),
  ElementBreadcrumb: () => import('../../components/Element/Breadcrumb.vue' /* webpackChunkName: "components/element-breadcrumb" */).then(c => wrapFunctional(c.default || c)),
  ElementButton: () => import('../../components/Element/Button.vue' /* webpackChunkName: "components/element-button" */).then(c => wrapFunctional(c.default || c)),
  ElementButtonImage: () => import('../../components/Element/ButtonImage.vue' /* webpackChunkName: "components/element-button-image" */).then(c => wrapFunctional(c.default || c)),
  ElementCard: () => import('../../components/Element/Card.vue' /* webpackChunkName: "components/element-card" */).then(c => wrapFunctional(c.default || c)),
  ElementContentCard: () => import('../../components/Element/ContentCard.vue' /* webpackChunkName: "components/element-content-card" */).then(c => wrapFunctional(c.default || c)),
  ElementContentHighlight: () => import('../../components/Element/ContentHighlight.vue' /* webpackChunkName: "components/element-content-highlight" */).then(c => wrapFunctional(c.default || c)),
  ElementDateAndView: () => import('../../components/Element/DateAndView.vue' /* webpackChunkName: "components/element-date-and-view" */).then(c => wrapFunctional(c.default || c)),
  ElementDivider: () => import('../../components/Element/Divider.vue' /* webpackChunkName: "components/element-divider" */).then(c => wrapFunctional(c.default || c)),
  ElementElearning: () => import('../../components/Element/Elearning.vue' /* webpackChunkName: "components/element-elearning" */).then(c => wrapFunctional(c.default || c)),
  ElementFAQ: () => import('../../components/Element/FAQ.vue' /* webpackChunkName: "components/element-f-a-q" */).then(c => wrapFunctional(c.default || c)),
  ElementFileDownload: () => import('../../components/Element/FileDownload.vue' /* webpackChunkName: "components/element-file-download" */).then(c => wrapFunctional(c.default || c)),
  ElementForm: () => import('../../components/Element/Form.vue' /* webpackChunkName: "components/element-form" */).then(c => wrapFunctional(c.default || c)),
  ElementGetQuote: () => import('../../components/Element/GetQuote.vue' /* webpackChunkName: "components/element-get-quote" */).then(c => wrapFunctional(c.default || c)),
  ElementHeading: () => import('../../components/Element/Heading.vue' /* webpackChunkName: "components/element-heading" */).then(c => wrapFunctional(c.default || c)),
  ElementHtml: () => import('../../components/Element/Html.vue' /* webpackChunkName: "components/element-html" */).then(c => wrapFunctional(c.default || c)),
  ElementIconList: () => import('../../components/Element/IconList.vue' /* webpackChunkName: "components/element-icon-list" */).then(c => wrapFunctional(c.default || c)),
  ElementImage: () => import('../../components/Element/Image.vue' /* webpackChunkName: "components/element-image" */).then(c => wrapFunctional(c.default || c)),
  ElementImageGallery: () => import('../../components/Element/ImageGallery.vue' /* webpackChunkName: "components/element-image-gallery" */).then(c => wrapFunctional(c.default || c)),
  ElementKeyword: () => import('../../components/Element/Keyword.vue' /* webpackChunkName: "components/element-keyword" */).then(c => wrapFunctional(c.default || c)),
  ElementLink: () => import('../../components/Element/Link.vue' /* webpackChunkName: "components/element-link" */).then(c => wrapFunctional(c.default || c)),
  ElementNativeAdsLongBanner: () => import('../../components/Element/NativeAdsLongBanner.vue' /* webpackChunkName: "components/element-native-ads-long-banner" */).then(c => wrapFunctional(c.default || c)),
  ElementNativeAdsThumbnailBanner: () => import('../../components/Element/NativeAdsThumbnailBanner.vue' /* webpackChunkName: "components/element-native-ads-thumbnail-banner" */).then(c => wrapFunctional(c.default || c)),
  ElementNumber: () => import('../../components/Element/Number.vue' /* webpackChunkName: "components/element-number" */).then(c => wrapFunctional(c.default || c)),
  ElementQuizChoices: () => import('../../components/Element/QuizChoices.vue' /* webpackChunkName: "components/element-quiz-choices" */).then(c => wrapFunctional(c.default || c)),
  ElementRating: () => import('../../components/Element/Rating.vue' /* webpackChunkName: "components/element-rating" */).then(c => wrapFunctional(c.default || c)),
  ElementReadTime: () => import('../../components/Element/ReadTime.vue' /* webpackChunkName: "components/element-read-time" */).then(c => wrapFunctional(c.default || c)),
  ElementRelatedContent: () => import('../../components/Element/RelatedContent.vue' /* webpackChunkName: "components/element-related-content" */).then(c => wrapFunctional(c.default || c)),
  ElementRelatedLink: () => import('../../components/Element/RelatedLink.vue' /* webpackChunkName: "components/element-related-link" */).then(c => wrapFunctional(c.default || c)),
  ElementSectionLock: () => import('../../components/Element/SectionLock.vue' /* webpackChunkName: "components/element-section-lock" */).then(c => wrapFunctional(c.default || c)),
  ElementSocialShare: () => import('../../components/Element/SocialShare.vue' /* webpackChunkName: "components/element-social-share" */).then(c => wrapFunctional(c.default || c)),
  ElementSpacer: () => import('../../components/Element/Spacer.vue' /* webpackChunkName: "components/element-spacer" */).then(c => wrapFunctional(c.default || c)),
  ElementTab: () => import('../../components/Element/Tab.vue' /* webpackChunkName: "components/element-tab" */).then(c => wrapFunctional(c.default || c)),
  ElementTabMenu: () => import('../../components/Element/TabMenu.vue' /* webpackChunkName: "components/element-tab-menu" */).then(c => wrapFunctional(c.default || c)),
  ElementTextEditor: () => import('../../components/Element/TextEditor.vue' /* webpackChunkName: "components/element-text-editor" */).then(c => wrapFunctional(c.default || c)),
  ElementTips: () => import('../../components/Element/Tips.vue' /* webpackChunkName: "components/element-tips" */).then(c => wrapFunctional(c.default || c)),
  ElementYoutubeVideo: () => import('../../components/Element/YoutubeVideo.vue' /* webpackChunkName: "components/element-youtube-video" */).then(c => wrapFunctional(c.default || c)),
  FontIcon: () => import('../../components/FontAwesome/FontIcon.vue' /* webpackChunkName: "components/font-icon" */).then(c => wrapFunctional(c.default || c)),
  Footer: () => import('../../components/Footer/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c)),
  FooterBar: () => import('../../components/Footer/FooterBar.vue' /* webpackChunkName: "components/footer-bar" */).then(c => wrapFunctional(c.default || c)),
  FooterDownloadApp: () => import('../../components/Footer/FooterDownloadApp.vue' /* webpackChunkName: "components/footer-download-app" */).then(c => wrapFunctional(c.default || c)),
  FooterSocial: () => import('../../components/Footer/FooterSocial.vue' /* webpackChunkName: "components/footer-social" */).then(c => wrapFunctional(c.default || c)),
  FooterMenuTab: () => import('../../components/Footer/MenuTab.vue' /* webpackChunkName: "components/footer-menu-tab" */).then(c => wrapFunctional(c.default || c)),
  FooterMenuTabMobile: () => import('../../components/Footer/MenuTabMobile.vue' /* webpackChunkName: "components/footer-menu-tab-mobile" */).then(c => wrapFunctional(c.default || c)),
  FormCustomCheckBox: () => import('../../components/Form/CustomCheckBox.vue' /* webpackChunkName: "components/form-custom-check-box" */).then(c => wrapFunctional(c.default || c)),
  FormCustomDropdown: () => import('../../components/Form/CustomDropdown.vue' /* webpackChunkName: "components/form-custom-dropdown" */).then(c => wrapFunctional(c.default || c)),
  FormCustomRadioBox: () => import('../../components/Form/CustomRadioBox.vue' /* webpackChunkName: "components/form-custom-radio-box" */).then(c => wrapFunctional(c.default || c)),
  FormDateTimePicker: () => import('../../components/Form/DateTimePicker.vue' /* webpackChunkName: "components/form-date-time-picker" */).then(c => wrapFunctional(c.default || c)),
  FormFileItem: () => import('../../components/Form/FileItem.vue' /* webpackChunkName: "components/form-file-item" */).then(c => wrapFunctional(c.default || c)),
  Form: () => import('../../components/Form/Form.vue' /* webpackChunkName: "components/form" */).then(c => wrapFunctional(c.default || c)),
  FormInputInfo: () => import('../../components/Form/InputInfo.vue' /* webpackChunkName: "components/form-input-info" */).then(c => wrapFunctional(c.default || c)),
  FormInputLabel: () => import('../../components/Form/InputLabel.vue' /* webpackChunkName: "components/form-input-label" */).then(c => wrapFunctional(c.default || c)),
  FormPreview: () => import('../../components/Form/Preview.vue' /* webpackChunkName: "components/form-preview" */).then(c => wrapFunctional(c.default || c)),
  FormSubmitComplete: () => import('../../components/Form/SubmitComplete.vue' /* webpackChunkName: "components/form-submit-complete" */).then(c => wrapFunctional(c.default || c)),
  GalleryImage: () => import('../../components/Gallery/Image.vue' /* webpackChunkName: "components/gallery-image" */).then(c => wrapFunctional(c.default || c)),
  GalleryImageItem: () => import('../../components/Gallery/ImageItem.vue' /* webpackChunkName: "components/gallery-image-item" */).then(c => wrapFunctional(c.default || c)),
  Header: () => import('../../components/Header/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c)),
  HeaderMarketSummary: () => import('../../components/Header/MarketSummary.vue' /* webpackChunkName: "components/header-market-summary" */).then(c => wrapFunctional(c.default || c)),
  Heading: () => import('../../components/Heading/Heading.vue' /* webpackChunkName: "components/heading" */).then(c => wrapFunctional(c.default || c)),
  Iframe: () => import('../../components/Iframe/Iframe.vue' /* webpackChunkName: "components/iframe" */).then(c => wrapFunctional(c.default || c)),
  InputBox: () => import('../../components/Input/Box.vue' /* webpackChunkName: "components/input-box" */).then(c => wrapFunctional(c.default || c)),
  InputCheckBox: () => import('../../components/Input/CheckBox.vue' /* webpackChunkName: "components/input-check-box" */).then(c => wrapFunctional(c.default || c)),
  InputCheckBoxCustomize: () => import('../../components/Input/CheckBoxCustomize.vue' /* webpackChunkName: "components/input-check-box-customize" */).then(c => wrapFunctional(c.default || c)),
  InputDateTimePicker: () => import('../../components/Input/DateTimePicker.vue' /* webpackChunkName: "components/input-date-time-picker" */).then(c => wrapFunctional(c.default || c)),
  InputRange: () => import('../../components/Input/Range.vue' /* webpackChunkName: "components/input-range" */).then(c => wrapFunctional(c.default || c)),
  InputRangeBidOffer: () => import('../../components/Input/RangeBidOffer.vue' /* webpackChunkName: "components/input-range-bid-offer" */).then(c => wrapFunctional(c.default || c)),
  InputRangeCustomPrice: () => import('../../components/Input/RangeCustomPrice.vue' /* webpackChunkName: "components/input-range-custom-price" */).then(c => wrapFunctional(c.default || c)),
  InputRangeDate: () => import('../../components/Input/RangeDate.vue' /* webpackChunkName: "components/input-range-date" */).then(c => wrapFunctional(c.default || c)),
  InputRangeLowHigh: () => import('../../components/Input/RangeLowHigh.vue' /* webpackChunkName: "components/input-range-low-high" */).then(c => wrapFunctional(c.default || c)),
  InputRangeMulti: () => import('../../components/Input/RangeMulti.vue' /* webpackChunkName: "components/input-range-multi" */).then(c => wrapFunctional(c.default || c)),
  InputRangePriceLowHigh: () => import('../../components/Input/RangePriceLowHigh.vue' /* webpackChunkName: "components/input-range-price-low-high" */).then(c => wrapFunctional(c.default || c)),
  InputRating: () => import('../../components/Input/Rating.vue' /* webpackChunkName: "components/input-rating" */).then(c => wrapFunctional(c.default || c)),
  InputResponsible: () => import('../../components/Input/Responsible.vue' /* webpackChunkName: "components/input-responsible" */).then(c => wrapFunctional(c.default || c)),
  InputResponsibleGroup: () => import('../../components/Input/ResponsibleGroup.vue' /* webpackChunkName: "components/input-responsible-group" */).then(c => wrapFunctional(c.default || c)),
  InputResponsibleSingle: () => import('../../components/Input/ResponsibleSingle.vue' /* webpackChunkName: "components/input-responsible-single" */).then(c => wrapFunctional(c.default || c)),
  InputSearch: () => import('../../components/Input/Search.vue' /* webpackChunkName: "components/input-search" */).then(c => wrapFunctional(c.default || c)),
  InputSelect: () => import('../../components/Input/Select.vue' /* webpackChunkName: "components/input-select" */).then(c => wrapFunctional(c.default || c)),
  InputTagInput: () => import('../../components/Input/TagInput.vue' /* webpackChunkName: "components/input-tag-input" */).then(c => wrapFunctional(c.default || c)),
  InputTags: () => import('../../components/Input/Tags.vue' /* webpackChunkName: "components/input-tags" */).then(c => wrapFunctional(c.default || c)),
  IconActiveTabmenuMobile: () => import('../../components/Icon/ActiveTabmenuMobile.vue' /* webpackChunkName: "components/icon-active-tabmenu-mobile" */).then(c => wrapFunctional(c.default || c)),
  IconAlert: () => import('../../components/Icon/Alert.vue' /* webpackChunkName: "components/icon-alert" */).then(c => wrapFunctional(c.default || c)),
  IconAnswer: () => import('../../components/Icon/Answer.vue' /* webpackChunkName: "components/icon-answer" */).then(c => wrapFunctional(c.default || c)),
  IconAppMenu: () => import('../../components/Icon/AppMenu.vue' /* webpackChunkName: "components/icon-app-menu" */).then(c => wrapFunctional(c.default || c)),
  IconArrowCircle: () => import('../../components/Icon/ArrowCircle.vue' /* webpackChunkName: "components/icon-arrow-circle" */).then(c => wrapFunctional(c.default || c)),
  IconArrowDown: () => import('../../components/Icon/ArrowDown.vue' /* webpackChunkName: "components/icon-arrow-down" */).then(c => wrapFunctional(c.default || c)),
  IconArrowDownBold: () => import('../../components/Icon/ArrowDownBold.vue' /* webpackChunkName: "components/icon-arrow-down-bold" */).then(c => wrapFunctional(c.default || c)),
  IconArrowDownCircle: () => import('../../components/Icon/ArrowDownCircle.vue' /* webpackChunkName: "components/icon-arrow-down-circle" */).then(c => wrapFunctional(c.default || c)),
  IconArrowReset: () => import('../../components/Icon/ArrowReset.vue' /* webpackChunkName: "components/icon-arrow-reset" */).then(c => wrapFunctional(c.default || c)),
  IconArrowRight: () => import('../../components/Icon/ArrowRight.vue' /* webpackChunkName: "components/icon-arrow-right" */).then(c => wrapFunctional(c.default || c)),
  IconArrowRightCircle: () => import('../../components/Icon/ArrowRightCircle.vue' /* webpackChunkName: "components/icon-arrow-right-circle" */).then(c => wrapFunctional(c.default || c)),
  IconArrowRightLong: () => import('../../components/Icon/ArrowRightLong.vue' /* webpackChunkName: "components/icon-arrow-right-long" */).then(c => wrapFunctional(c.default || c)),
  IconArrowRightShort: () => import('../../components/Icon/ArrowRightShort.vue' /* webpackChunkName: "components/icon-arrow-right-short" */).then(c => wrapFunctional(c.default || c)),
  IconArrowTop: () => import('../../components/Icon/ArrowTop.vue' /* webpackChunkName: "components/icon-arrow-top" */).then(c => wrapFunctional(c.default || c)),
  IconArrowTopCircle: () => import('../../components/Icon/ArrowTopCircle.vue' /* webpackChunkName: "components/icon-arrow-top-circle" */).then(c => wrapFunctional(c.default || c)),
  IconAssertMoney: () => import('../../components/Icon/AssertMoney.vue' /* webpackChunkName: "components/icon-assert-money" */).then(c => wrapFunctional(c.default || c)),
  IconAssetAllocation: () => import('../../components/Icon/AssetAllocation.vue' /* webpackChunkName: "components/icon-asset-allocation" */).then(c => wrapFunctional(c.default || c)),
  IconBarChart: () => import('../../components/Icon/BarChart.vue' /* webpackChunkName: "components/icon-bar-chart" */).then(c => wrapFunctional(c.default || c)),
  IconBearish: () => import('../../components/Icon/Bearish.vue' /* webpackChunkName: "components/icon-bearish" */).then(c => wrapFunctional(c.default || c)),
  IconBell: () => import('../../components/Icon/Bell.vue' /* webpackChunkName: "components/icon-bell" */).then(c => wrapFunctional(c.default || c)),
  IconBellNoti: () => import('../../components/Icon/BellNoti.vue' /* webpackChunkName: "components/icon-bell-noti" */).then(c => wrapFunctional(c.default || c)),
  IconBin: () => import('../../components/Icon/Bin.vue' /* webpackChunkName: "components/icon-bin" */).then(c => wrapFunctional(c.default || c)),
  IconBin2: () => import('../../components/Icon/Bin2.vue' /* webpackChunkName: "components/icon-bin2" */).then(c => wrapFunctional(c.default || c)),
  IconBookmark: () => import('../../components/Icon/Bookmark.vue' /* webpackChunkName: "components/icon-bookmark" */).then(c => wrapFunctional(c.default || c)),
  IconBoxArrowUpRight: () => import('../../components/Icon/BoxArrowUpRight.vue' /* webpackChunkName: "components/icon-box-arrow-up-right" */).then(c => wrapFunctional(c.default || c)),
  IconBoxArrowUpRight2: () => import('../../components/Icon/BoxArrowUpRight2.vue' /* webpackChunkName: "components/icon-box-arrow-up-right2" */).then(c => wrapFunctional(c.default || c)),
  IconBullish: () => import('../../components/Icon/Bullish.vue' /* webpackChunkName: "components/icon-bullish" */).then(c => wrapFunctional(c.default || c)),
  IconCalculator: () => import('../../components/Icon/Calculator.vue' /* webpackChunkName: "components/icon-calculator" */).then(c => wrapFunctional(c.default || c)),
  IconCalendar: () => import('../../components/Icon/Calendar.vue' /* webpackChunkName: "components/icon-calendar" */).then(c => wrapFunctional(c.default || c)),
  IconCalendarNearly: () => import('../../components/Icon/CalendarNearly.vue' /* webpackChunkName: "components/icon-calendar-nearly" */).then(c => wrapFunctional(c.default || c)),
  IconCalendarNearlyBorderBlack: () => import('../../components/Icon/CalendarNearlyBorderBlack.vue' /* webpackChunkName: "components/icon-calendar-nearly-border-black" */).then(c => wrapFunctional(c.default || c)),
  IconCalendarWarning: () => import('../../components/Icon/CalendarWarning.vue' /* webpackChunkName: "components/icon-calendar-warning" */).then(c => wrapFunctional(c.default || c)),
  IconCall: () => import('../../components/Icon/Call.vue' /* webpackChunkName: "components/icon-call" */).then(c => wrapFunctional(c.default || c)),
  IconCgReportOff: () => import('../../components/Icon/CgReportOff.vue' /* webpackChunkName: "components/icon-cg-report-off" */).then(c => wrapFunctional(c.default || c)),
  IconCgReportOn: () => import('../../components/Icon/CgReportOn.vue' /* webpackChunkName: "components/icon-cg-report-on" */).then(c => wrapFunctional(c.default || c)),
  IconChartUp: () => import('../../components/Icon/ChartUp.vue' /* webpackChunkName: "components/icon-chart-up" */).then(c => wrapFunctional(c.default || c)),
  IconCheck: () => import('../../components/Icon/Check.vue' /* webpackChunkName: "components/icon-check" */).then(c => wrapFunctional(c.default || c)),
  IconCheckCircle: () => import('../../components/Icon/CheckCircle.vue' /* webpackChunkName: "components/icon-check-circle" */).then(c => wrapFunctional(c.default || c)),
  IconChevronBoldLeft: () => import('../../components/Icon/ChevronBoldLeft.vue' /* webpackChunkName: "components/icon-chevron-bold-left" */).then(c => wrapFunctional(c.default || c)),
  IconChevronBoldRight: () => import('../../components/Icon/ChevronBoldRight.vue' /* webpackChunkName: "components/icon-chevron-bold-right" */).then(c => wrapFunctional(c.default || c)),
  IconChevronDown: () => import('../../components/Icon/ChevronDown.vue' /* webpackChunkName: "components/icon-chevron-down" */).then(c => wrapFunctional(c.default || c)),
  IconChevronLeft: () => import('../../components/Icon/ChevronLeft.vue' /* webpackChunkName: "components/icon-chevron-left" */).then(c => wrapFunctional(c.default || c)),
  IconChevronRight: () => import('../../components/Icon/ChevronRight.vue' /* webpackChunkName: "components/icon-chevron-right" */).then(c => wrapFunctional(c.default || c)),
  IconChevronUp: () => import('../../components/Icon/ChevronUp.vue' /* webpackChunkName: "components/icon-chevron-up" */).then(c => wrapFunctional(c.default || c)),
  IconCircleArrowDown: () => import('../../components/Icon/CircleArrowDown.vue' /* webpackChunkName: "components/icon-circle-arrow-down" */).then(c => wrapFunctional(c.default || c)),
  IconCircleMoney: () => import('../../components/Icon/CircleMoney.vue' /* webpackChunkName: "components/icon-circle-money" */).then(c => wrapFunctional(c.default || c)),
  IconClock: () => import('../../components/Icon/Clock.vue' /* webpackChunkName: "components/icon-clock" */).then(c => wrapFunctional(c.default || c)),
  IconClose: () => import('../../components/Icon/Close.vue' /* webpackChunkName: "components/icon-close" */).then(c => wrapFunctional(c.default || c)),
  IconCloseCircle: () => import('../../components/Icon/CloseCircle.vue' /* webpackChunkName: "components/icon-close-circle" */).then(c => wrapFunctional(c.default || c)),
  IconCloseCircleFilled: () => import('../../components/Icon/CloseCircleFilled.vue' /* webpackChunkName: "components/icon-close-circle-filled" */).then(c => wrapFunctional(c.default || c)),
  IconCloseNotice: () => import('../../components/Icon/CloseNotice.vue' /* webpackChunkName: "components/icon-close-notice" */).then(c => wrapFunctional(c.default || c)),
  IconCloseSearch: () => import('../../components/Icon/CloseSearch.vue' /* webpackChunkName: "components/icon-close-search" */).then(c => wrapFunctional(c.default || c)),
  IconCloseTags: () => import('../../components/Icon/CloseTags.vue' /* webpackChunkName: "components/icon-close-tags" */).then(c => wrapFunctional(c.default || c)),
  IconCollapseAll: () => import('../../components/Icon/CollapseAll.vue' /* webpackChunkName: "components/icon-collapse-all" */).then(c => wrapFunctional(c.default || c)),
  IconCompany: () => import('../../components/Icon/Company.vue' /* webpackChunkName: "components/icon-company" */).then(c => wrapFunctional(c.default || c)),
  IconCompare: () => import('../../components/Icon/Compare.vue' /* webpackChunkName: "components/icon-compare" */).then(c => wrapFunctional(c.default || c)),
  IconCreatePort: () => import('../../components/Icon/CreatePort.vue' /* webpackChunkName: "components/icon-create-port" */).then(c => wrapFunctional(c.default || c)),
  IconCustomize: () => import('../../components/Icon/Customize.vue' /* webpackChunkName: "components/icon-customize" */).then(c => wrapFunctional(c.default || c)),
  IconDocument: () => import('../../components/Icon/Document.vue' /* webpackChunkName: "components/icon-document" */).then(c => wrapFunctional(c.default || c)),
  IconDocumentChartUp: () => import('../../components/Icon/DocumentChartUp.vue' /* webpackChunkName: "components/icon-document-chart-up" */).then(c => wrapFunctional(c.default || c)),
  IconDocumentCoding: () => import('../../components/Icon/DocumentCoding.vue' /* webpackChunkName: "components/icon-document-coding" */).then(c => wrapFunctional(c.default || c)),
  IconDocumentCodingLarge: () => import('../../components/Icon/DocumentCodingLarge.vue' /* webpackChunkName: "components/icon-document-coding-large" */).then(c => wrapFunctional(c.default || c)),
  IconDocumentSearchTrend: () => import('../../components/Icon/DocumentSearchTrend.vue' /* webpackChunkName: "components/icon-document-search-trend" */).then(c => wrapFunctional(c.default || c)),
  IconDot: () => import('../../components/Icon/Dot.vue' /* webpackChunkName: "components/icon-dot" */).then(c => wrapFunctional(c.default || c)),
  IconDownload: () => import('../../components/Icon/Download.vue' /* webpackChunkName: "components/icon-download" */).then(c => wrapFunctional(c.default || c)),
  IconDownloadCircle: () => import('../../components/Icon/DownloadCircle.vue' /* webpackChunkName: "components/icon-download-circle" */).then(c => wrapFunctional(c.default || c)),
  IconDwExpire: () => import('../../components/Icon/DwExpire.vue' /* webpackChunkName: "components/icon-dw-expire" */).then(c => wrapFunctional(c.default || c)),
  IconEdit: () => import('../../components/Icon/Edit.vue' /* webpackChunkName: "components/icon-edit" */).then(c => wrapFunctional(c.default || c)),
  IconExpandAll: () => import('../../components/Icon/ExpandAll.vue' /* webpackChunkName: "components/icon-expand-all" */).then(c => wrapFunctional(c.default || c)),
  IconExpired: () => import('../../components/Icon/Expired.vue' /* webpackChunkName: "components/icon-expired" */).then(c => wrapFunctional(c.default || c)),
  IconExport: () => import('../../components/Icon/Export.vue' /* webpackChunkName: "components/icon-export" */).then(c => wrapFunctional(c.default || c)),
  IconExternal: () => import('../../components/Icon/External.vue' /* webpackChunkName: "components/icon-external" */).then(c => wrapFunctional(c.default || c)),
  IconEye: () => import('../../components/Icon/Eye.vue' /* webpackChunkName: "components/icon-eye" */).then(c => wrapFunctional(c.default || c)),
  IconFactSheet: () => import('../../components/Icon/FactSheet.vue' /* webpackChunkName: "components/icon-fact-sheet" */).then(c => wrapFunctional(c.default || c)),
  IconFavorite: () => import('../../components/Icon/Favorite.vue' /* webpackChunkName: "components/icon-favorite" */).then(c => wrapFunctional(c.default || c)),
  IconFavoriteQuote: () => import('../../components/Icon/FavoriteQuote.vue' /* webpackChunkName: "components/icon-favorite-quote" */).then(c => wrapFunctional(c.default || c)),
  IconFax: () => import('../../components/Icon/Fax.vue' /* webpackChunkName: "components/icon-fax" */).then(c => wrapFunctional(c.default || c)),
  IconFeed: () => import('../../components/Icon/Feed.vue' /* webpackChunkName: "components/icon-feed" */).then(c => wrapFunctional(c.default || c)),
  IconFile: () => import('../../components/Icon/File.vue' /* webpackChunkName: "components/icon-file" */).then(c => wrapFunctional(c.default || c)),
  IconFileSheet: () => import('../../components/Icon/FileSheet.vue' /* webpackChunkName: "components/icon-file-sheet" */).then(c => wrapFunctional(c.default || c)),
  IconFilter: () => import('../../components/Icon/Filter.vue' /* webpackChunkName: "components/icon-filter" */).then(c => wrapFunctional(c.default || c)),
  IconFilterTable: () => import('../../components/Icon/FilterTable.vue' /* webpackChunkName: "components/icon-filter-table" */).then(c => wrapFunctional(c.default || c)),
  IconFinanceData: () => import('../../components/Icon/FinanceData.vue' /* webpackChunkName: "components/icon-finance-data" */).then(c => wrapFunctional(c.default || c)),
  IconFinancial: () => import('../../components/Icon/Financial.vue' /* webpackChunkName: "components/icon-financial" */).then(c => wrapFunctional(c.default || c)),
  IconFinancialPro: () => import('../../components/Icon/FinancialPro.vue' /* webpackChunkName: "components/icon-financial-pro" */).then(c => wrapFunctional(c.default || c)),
  IconFullFinanceData: () => import('../../components/Icon/FullFinanceData.vue' /* webpackChunkName: "components/icon-full-finance-data" */).then(c => wrapFunctional(c.default || c)),
  IconFundInfo: () => import('../../components/Icon/FundInfo.vue' /* webpackChunkName: "components/icon-fund-info" */).then(c => wrapFunctional(c.default || c)),
  IconGlobal: () => import('../../components/Icon/Global.vue' /* webpackChunkName: "components/icon-global" */).then(c => wrapFunctional(c.default || c)),
  IconGlobalOrbit: () => import('../../components/Icon/GlobalOrbit.vue' /* webpackChunkName: "components/icon-global-orbit" */).then(c => wrapFunctional(c.default || c)),
  IconGoodBasis: () => import('../../components/Icon/GoodBasis.vue' /* webpackChunkName: "components/icon-good-basis" */).then(c => wrapFunctional(c.default || c)),
  IconGreenArrowRightCircle: () => import('../../components/Icon/GreenArrowRightCircle.vue' /* webpackChunkName: "components/icon-green-arrow-right-circle" */).then(c => wrapFunctional(c.default || c)),
  IconGrid: () => import('../../components/Icon/Grid.vue' /* webpackChunkName: "components/icon-grid" */).then(c => wrapFunctional(c.default || c)),
  IconHighlight: () => import('../../components/Icon/Highlight.vue' /* webpackChunkName: "components/icon-highlight" */).then(c => wrapFunctional(c.default || c)),
  IconIndicativePrice: () => import('../../components/Icon/IndicativePrice.vue' /* webpackChunkName: "components/icon-indicative-price" */).then(c => wrapFunctional(c.default || c)),
  IconInfo: () => import('../../components/Icon/Info.vue' /* webpackChunkName: "components/icon-info" */).then(c => wrapFunctional(c.default || c)),
  IconInformation: () => import('../../components/Icon/Information.vue' /* webpackChunkName: "components/icon-information" */).then(c => wrapFunctional(c.default || c)),
  IconInformation2: () => import('../../components/Icon/Information2.vue' /* webpackChunkName: "components/icon-information2" */).then(c => wrapFunctional(c.default || c)),
  IconInvestmentAsset: () => import('../../components/Icon/InvestmentAsset.vue' /* webpackChunkName: "components/icon-investment-asset" */).then(c => wrapFunctional(c.default || c)),
  IconLike: () => import('../../components/Icon/Like.vue' /* webpackChunkName: "components/icon-like" */).then(c => wrapFunctional(c.default || c)),
  IconLineChart: () => import('../../components/Icon/LineChart.vue' /* webpackChunkName: "components/icon-line-chart" */).then(c => wrapFunctional(c.default || c)),
  IconLinearArrowDown: () => import('../../components/Icon/LinearArrowDown.vue' /* webpackChunkName: "components/icon-linear-arrow-down" */).then(c => wrapFunctional(c.default || c)),
  IconLinearArrowRight: () => import('../../components/Icon/LinearArrowRight.vue' /* webpackChunkName: "components/icon-linear-arrow-right" */).then(c => wrapFunctional(c.default || c)),
  IconLinearConsensus: () => import('../../components/Icon/LinearConsensus.vue' /* webpackChunkName: "components/icon-linear-consensus" */).then(c => wrapFunctional(c.default || c)),
  IconLinearForward: () => import('../../components/Icon/LinearForward.vue' /* webpackChunkName: "components/icon-linear-forward" */).then(c => wrapFunctional(c.default || c)),
  IconLinearResearch: () => import('../../components/Icon/LinearResearch.vue' /* webpackChunkName: "components/icon-linear-research" */).then(c => wrapFunctional(c.default || c)),
  IconLinearStaticChart: () => import('../../components/Icon/LinearStaticChart.vue' /* webpackChunkName: "components/icon-linear-static-chart" */).then(c => wrapFunctional(c.default || c)),
  IconLinkNewTab: () => import('../../components/Icon/LinkNewTab.vue' /* webpackChunkName: "components/icon-link-new-tab" */).then(c => wrapFunctional(c.default || c)),
  IconLoadingChart: () => import('../../components/Icon/LoadingChart.vue' /* webpackChunkName: "components/icon-loading-chart" */).then(c => wrapFunctional(c.default || c)),
  IconLocation: () => import('../../components/Icon/Location.vue' /* webpackChunkName: "components/icon-location" */).then(c => wrapFunctional(c.default || c)),
  IconLock: () => import('../../components/Icon/Lock.vue' /* webpackChunkName: "components/icon-lock" */).then(c => wrapFunctional(c.default || c)),
  IconLockCircle: () => import('../../components/Icon/LockCircle.vue' /* webpackChunkName: "components/icon-lock-circle" */).then(c => wrapFunctional(c.default || c)),
  IconLogOut: () => import('../../components/Icon/LogOut.vue' /* webpackChunkName: "components/icon-log-out" */).then(c => wrapFunctional(c.default || c)),
  IconLoveNotice: () => import('../../components/Icon/LoveNotice.vue' /* webpackChunkName: "components/icon-love-notice" */).then(c => wrapFunctional(c.default || c)),
  IconManager: () => import('../../components/Icon/Manager.vue' /* webpackChunkName: "components/icon-manager" */).then(c => wrapFunctional(c.default || c)),
  IconMarket: () => import('../../components/Icon/Market.vue' /* webpackChunkName: "components/icon-market" */).then(c => wrapFunctional(c.default || c)),
  IconMemberBacktesting: () => import('../../components/Icon/MemberBacktesting.vue' /* webpackChunkName: "components/icon-member-backtesting" */).then(c => wrapFunctional(c.default || c)),
  IconMemberFavourite: () => import('../../components/Icon/MemberFavourite.vue' /* webpackChunkName: "components/icon-member-favourite" */).then(c => wrapFunctional(c.default || c)),
  IconMemberLogout: () => import('../../components/Icon/MemberLogout.vue' /* webpackChunkName: "components/icon-member-logout" */).then(c => wrapFunctional(c.default || c)),
  IconMemberMyFeed: () => import('../../components/Icon/MemberMyFeed.vue' /* webpackChunkName: "components/icon-member-my-feed" */).then(c => wrapFunctional(c.default || c)),
  IconMemberProfile: () => import('../../components/Icon/MemberProfile.vue' /* webpackChunkName: "components/icon-member-profile" */).then(c => wrapFunctional(c.default || c)),
  IconMemberVirtualPort: () => import('../../components/Icon/MemberVirtualPort.vue' /* webpackChunkName: "components/icon-member-virtual-port" */).then(c => wrapFunctional(c.default || c)),
  IconMenu: () => import('../../components/Icon/Menu.vue' /* webpackChunkName: "components/icon-menu" */).then(c => wrapFunctional(c.default || c)),
  IconMenuArrow: () => import('../../components/Icon/MenuArrow.vue' /* webpackChunkName: "components/icon-menu-arrow" */).then(c => wrapFunctional(c.default || c)),
  IconMinus: () => import('../../components/Icon/Minus.vue' /* webpackChunkName: "components/icon-minus" */).then(c => wrapFunctional(c.default || c)),
  IconMinusCircle: () => import('../../components/Icon/MinusCircle.vue' /* webpackChunkName: "components/icon-minus-circle" */).then(c => wrapFunctional(c.default || c)),
  IconMostActiveValue: () => import('../../components/Icon/MostActiveValue.vue' /* webpackChunkName: "components/icon-most-active-value" */).then(c => wrapFunctional(c.default || c)),
  IconMostActiveVolume: () => import('../../components/Icon/MostActiveVolume.vue' /* webpackChunkName: "components/icon-most-active-volume" */).then(c => wrapFunctional(c.default || c)),
  IconMultiplierIndex: () => import('../../components/Icon/MultiplierIndex.vue' /* webpackChunkName: "components/icon-multiplier-index" */).then(c => wrapFunctional(c.default || c)),
  IconMyPortMoney: () => import('../../components/Icon/MyPortMoney.vue' /* webpackChunkName: "components/icon-my-port-money" */).then(c => wrapFunctional(c.default || c)),
  IconNew: () => import('../../components/Icon/New.vue' /* webpackChunkName: "components/icon-new" */).then(c => wrapFunctional(c.default || c)),
  IconNewFill: () => import('../../components/Icon/NewFill.vue' /* webpackChunkName: "components/icon-new-fill" */).then(c => wrapFunctional(c.default || c)),
  IconNews: () => import('../../components/Icon/News.vue' /* webpackChunkName: "components/icon-news" */).then(c => wrapFunctional(c.default || c)),
  IconOutstandingDividend: () => import('../../components/Icon/OutstandingDividend.vue' /* webpackChunkName: "components/icon-outstanding-dividend" */).then(c => wrapFunctional(c.default || c)),
  IconOverviewTopRank: () => import('../../components/Icon/OverviewTopRank.vue' /* webpackChunkName: "components/icon-overview-top-rank" */).then(c => wrapFunctional(c.default || c)),
  IconPdf: () => import('../../components/Icon/Pdf.vue' /* webpackChunkName: "components/icon-pdf" */).then(c => wrapFunctional(c.default || c)),
  IconPieChart: () => import('../../components/Icon/PieChart.vue' /* webpackChunkName: "components/icon-pie-chart" */).then(c => wrapFunctional(c.default || c)),
  IconPlay: () => import('../../components/Icon/Play.vue' /* webpackChunkName: "components/icon-play" */).then(c => wrapFunctional(c.default || c)),
  IconPlayOutline: () => import('../../components/Icon/PlayOutline.vue' /* webpackChunkName: "components/icon-play-outline" */).then(c => wrapFunctional(c.default || c)),
  IconPlus: () => import('../../components/Icon/Plus.vue' /* webpackChunkName: "components/icon-plus" */).then(c => wrapFunctional(c.default || c)),
  IconPlusCircle: () => import('../../components/Icon/PlusCircle.vue' /* webpackChunkName: "components/icon-plus-circle" */).then(c => wrapFunctional(c.default || c)),
  IconPlusCircleFilled: () => import('../../components/Icon/PlusCircleFilled.vue' /* webpackChunkName: "components/icon-plus-circle-filled" */).then(c => wrapFunctional(c.default || c)),
  IconPopular: () => import('../../components/Icon/Popular.vue' /* webpackChunkName: "components/icon-popular" */).then(c => wrapFunctional(c.default || c)),
  IconPortfolio: () => import('../../components/Icon/Portfolio.vue' /* webpackChunkName: "components/icon-portfolio" */).then(c => wrapFunctional(c.default || c)),
  IconPresent: () => import('../../components/Icon/Present.vue' /* webpackChunkName: "components/icon-present" */).then(c => wrapFunctional(c.default || c)),
  IconQuestion: () => import('../../components/Icon/Question.vue' /* webpackChunkName: "components/icon-question" */).then(c => wrapFunctional(c.default || c)),
  IconRefresh: () => import('../../components/Icon/Refresh.vue' /* webpackChunkName: "components/icon-refresh" */).then(c => wrapFunctional(c.default || c)),
  IconReport: () => import('../../components/Icon/Report.vue' /* webpackChunkName: "components/icon-report" */).then(c => wrapFunctional(c.default || c)),
  IconResearch: () => import('../../components/Icon/Research.vue' /* webpackChunkName: "components/icon-research" */).then(c => wrapFunctional(c.default || c)),
  IconResearch2: () => import('../../components/Icon/Research2.vue' /* webpackChunkName: "components/icon-research2" */).then(c => wrapFunctional(c.default || c)),
  IconResearchIAA: () => import('../../components/Icon/ResearchIAA.vue' /* webpackChunkName: "components/icon-research-i-a-a" */).then(c => wrapFunctional(c.default || c)),
  IconScreeningGrowth: () => import('../../components/Icon/ScreeningGrowth.vue' /* webpackChunkName: "components/icon-screening-growth" */).then(c => wrapFunctional(c.default || c)),
  IconScrollTable: () => import('../../components/Icon/ScrollTable.vue' /* webpackChunkName: "components/icon-scroll-table" */).then(c => wrapFunctional(c.default || c)),
  IconSearch: () => import('../../components/Icon/Search.vue' /* webpackChunkName: "components/icon-search" */).then(c => wrapFunctional(c.default || c)),
  IconSearchClose: () => import('../../components/Icon/SearchClose.vue' /* webpackChunkName: "components/icon-search-close" */).then(c => wrapFunctional(c.default || c)),
  IconSearchIndividual: () => import('../../components/Icon/SearchIndividual.vue' /* webpackChunkName: "components/icon-search-individual" */).then(c => wrapFunctional(c.default || c)),
  IconSearchRecent: () => import('../../components/Icon/SearchRecent.vue' /* webpackChunkName: "components/icon-search-recent" */).then(c => wrapFunctional(c.default || c)),
  IconShare: () => import('../../components/Icon/Share.vue' /* webpackChunkName: "components/icon-share" */).then(c => wrapFunctional(c.default || c)),
  IconShortcutClose: () => import('../../components/Icon/ShortcutClose.vue' /* webpackChunkName: "components/icon-shortcut-close" */).then(c => wrapFunctional(c.default || c)),
  IconSliders: () => import('../../components/Icon/Sliders.vue' /* webpackChunkName: "components/icon-sliders" */).then(c => wrapFunctional(c.default || c)),
  IconSpeedometer: () => import('../../components/Icon/Speedometer.vue' /* webpackChunkName: "components/icon-speedometer" */).then(c => wrapFunctional(c.default || c)),
  IconStar: () => import('../../components/Icon/Star.vue' /* webpackChunkName: "components/icon-star" */).then(c => wrapFunctional(c.default || c)),
  IconStarFill: () => import('../../components/Icon/StarFill.vue' /* webpackChunkName: "components/icon-star-fill" */).then(c => wrapFunctional(c.default || c)),
  IconStarFillColor: () => import('../../components/Icon/StarFillColor.vue' /* webpackChunkName: "components/icon-star-fill-color" */).then(c => wrapFunctional(c.default || c)),
  IconStarIaa: () => import('../../components/Icon/StarIaa.vue' /* webpackChunkName: "components/icon-star-iaa" */).then(c => wrapFunctional(c.default || c)),
  IconStarNotice: () => import('../../components/Icon/StarNotice.vue' /* webpackChunkName: "components/icon-star-notice" */).then(c => wrapFunctional(c.default || c)),
  IconStockScreen: () => import('../../components/Icon/StockScreen.vue' /* webpackChunkName: "components/icon-stock-screen" */).then(c => wrapFunctional(c.default || c)),
  IconStreaming: () => import('../../components/Icon/Streaming.vue' /* webpackChunkName: "components/icon-streaming" */).then(c => wrapFunctional(c.default || c)),
  IconSubTable: () => import('../../components/Icon/SubTable.vue' /* webpackChunkName: "components/icon-sub-table" */).then(c => wrapFunctional(c.default || c)),
  IconSuccessNotice: () => import('../../components/Icon/SuccessNotice.vue' /* webpackChunkName: "components/icon-success-notice" */).then(c => wrapFunctional(c.default || c)),
  IconTabMenuMobileArrow: () => import('../../components/Icon/TabMenuMobileArrow.vue' /* webpackChunkName: "components/icon-tab-menu-mobile-arrow" */).then(c => wrapFunctional(c.default || c)),
  IconTechnicalGraph: () => import('../../components/Icon/TechnicalGraph.vue' /* webpackChunkName: "components/icon-technical-graph" */).then(c => wrapFunctional(c.default || c)),
  IconTime: () => import('../../components/Icon/Time.vue' /* webpackChunkName: "components/icon-time" */).then(c => wrapFunctional(c.default || c)),
  IconTimePaper: () => import('../../components/Icon/TimePaper.vue' /* webpackChunkName: "components/icon-time-paper" */).then(c => wrapFunctional(c.default || c)),
  IconTimeToMarket: () => import('../../components/Icon/TimeToMarket.vue' /* webpackChunkName: "components/icon-time-to-market" */).then(c => wrapFunctional(c.default || c)),
  IconTopDividendYield: () => import('../../components/Icon/TopDividendYield.vue' /* webpackChunkName: "components/icon-top-dividend-yield" */).then(c => wrapFunctional(c.default || c)),
  IconTopEPS: () => import('../../components/Icon/TopEPS.vue' /* webpackChunkName: "components/icon-top-e-p-s" */).then(c => wrapFunctional(c.default || c)),
  IconTopGainer: () => import('../../components/Icon/TopGainer.vue' /* webpackChunkName: "components/icon-top-gainer" */).then(c => wrapFunctional(c.default || c)),
  IconTopLoser: () => import('../../components/Icon/TopLoser.vue' /* webpackChunkName: "components/icon-top-loser" */).then(c => wrapFunctional(c.default || c)),
  IconTopNetProfitMargin: () => import('../../components/Icon/TopNetProfitMargin.vue' /* webpackChunkName: "components/icon-top-net-profit-margin" */).then(c => wrapFunctional(c.default || c)),
  IconTopPE: () => import('../../components/Icon/TopPE.vue' /* webpackChunkName: "components/icon-top-p-e" */).then(c => wrapFunctional(c.default || c)),
  IconTopROA: () => import('../../components/Icon/TopROA.vue' /* webpackChunkName: "components/icon-top-r-o-a" */).then(c => wrapFunctional(c.default || c)),
  IconTopROE: () => import('../../components/Icon/TopROE.vue' /* webpackChunkName: "components/icon-top-r-o-e" */).then(c => wrapFunctional(c.default || c)),
  IconTraced: () => import('../../components/Icon/Traced.vue' /* webpackChunkName: "components/icon-traced" */).then(c => wrapFunctional(c.default || c)),
  IconTradingDerivatives: () => import('../../components/Icon/TradingDerivatives.vue' /* webpackChunkName: "components/icon-trading-derivatives" */).then(c => wrapFunctional(c.default || c)),
  IconTreadingUp: () => import('../../components/Icon/TreadingUp.vue' /* webpackChunkName: "components/icon-treading-up" */).then(c => wrapFunctional(c.default || c)),
  IconTriangle: () => import('../../components/Icon/Triangle.vue' /* webpackChunkName: "components/icon-triangle" */).then(c => wrapFunctional(c.default || c)),
  IconUnFavorite: () => import('../../components/Icon/UnFavorite.vue' /* webpackChunkName: "components/icon-un-favorite" */).then(c => wrapFunctional(c.default || c)),
  IconUnLoveNotice: () => import('../../components/Icon/UnLoveNotice.vue' /* webpackChunkName: "components/icon-un-love-notice" */).then(c => wrapFunctional(c.default || c)),
  IconWarning: () => import('../../components/Icon/Warning.vue' /* webpackChunkName: "components/icon-warning" */).then(c => wrapFunctional(c.default || c)),
  IconWarningNotice: () => import('../../components/Icon/WarningNotice.vue' /* webpackChunkName: "components/icon-warning-notice" */).then(c => wrapFunctional(c.default || c)),
  IconWorld: () => import('../../components/Icon/World.vue' /* webpackChunkName: "components/icon-world" */).then(c => wrapFunctional(c.default || c)),
  IconZip: () => import('../../components/Icon/Zip.vue' /* webpackChunkName: "components/icon-zip" */).then(c => wrapFunctional(c.default || c)),
  IconZipFile: () => import('../../components/Icon/ZipFile.vue' /* webpackChunkName: "components/icon-zip-file" */).then(c => wrapFunctional(c.default || c)),
  LayoutComponentFooter: () => import('../../components/LayoutComponent/Footer.vue' /* webpackChunkName: "components/layout-component-footer" */).then(c => wrapFunctional(c.default || c)),
  LoadingColumn: () => import('../../components/Loading/Column.vue' /* webpackChunkName: "components/loading-column" */).then(c => wrapFunctional(c.default || c)),
  LoadingLinear: () => import('../../components/Loading/Linear.vue' /* webpackChunkName: "components/loading-linear" */).then(c => wrapFunctional(c.default || c)),
  LoadingSpinner: () => import('../../components/Loading/Spinner.vue' /* webpackChunkName: "components/loading-spinner" */).then(c => wrapFunctional(c.default || c)),
  MarketTimeUpdateAsOfDate: () => import('../../components/MarketTimeUpdate/AsOfDate.vue' /* webpackChunkName: "components/market-time-update-as-of-date" */).then(c => wrapFunctional(c.default || c)),
  MarketTimeUpdateConsensusMarketTime: () => import('../../components/MarketTimeUpdate/ConsensusMarketTime.vue' /* webpackChunkName: "components/market-time-update-consensus-market-time" */).then(c => wrapFunctional(c.default || c)),
  MarketTimeUpdateDateAt: () => import('../../components/MarketTimeUpdate/DateAt.vue' /* webpackChunkName: "components/market-time-update-date-at" */).then(c => wrapFunctional(c.default || c)),
  MarketTimeUpdateDateInfo: () => import('../../components/MarketTimeUpdate/DateInfo.vue' /* webpackChunkName: "components/market-time-update-date-info" */).then(c => wrapFunctional(c.default || c)),
  MessageComparison: () => import('../../components/Message/Comparison.vue' /* webpackChunkName: "components/message-comparison" */).then(c => wrapFunctional(c.default || c)),
  MessageGetQuote: () => import('../../components/Message/GetQuote.vue' /* webpackChunkName: "components/message-get-quote" */).then(c => wrapFunctional(c.default || c)),
  Message: () => import('../../components/Message/index.vue' /* webpackChunkName: "components/message" */).then(c => wrapFunctional(c.default || c)),
  Modal: () => import('../../components/Modal/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c)),
  ModalDeleteFavourite: () => import('../../components/Modal/ModalDeleteFavourite.vue' /* webpackChunkName: "components/modal-delete-favourite" */).then(c => wrapFunctional(c.default || c)),
  ModalDeleteUpcoming: () => import('../../components/Modal/ModalDeleteUpcoming.vue' /* webpackChunkName: "components/modal-delete-upcoming" */).then(c => wrapFunctional(c.default || c)),
  ModalFullSymbol: () => import('../../components/Modal/ModalFullSymbol.vue' /* webpackChunkName: "components/modal-full-symbol" */).then(c => wrapFunctional(c.default || c)),
  ModalIsDwExpired: () => import('../../components/Modal/ModalIsDwExpired.vue' /* webpackChunkName: "components/modal-is-dw-expired" */).then(c => wrapFunctional(c.default || c)),
  ModalVideo: () => import('../../components/Modal/Video.vue' /* webpackChunkName: "components/modal-video" */).then(c => wrapFunctional(c.default || c)),
  Mouse: () => import('../../components/Mouse/Mouse.vue' /* webpackChunkName: "components/mouse" */).then(c => wrapFunctional(c.default || c)),
  Pagination: () => import('../../components/Pagination/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c)),
  PaginationCustom: () => import('../../components/Pagination/PaginationCustom.vue' /* webpackChunkName: "components/pagination-custom" */).then(c => wrapFunctional(c.default || c)),
  PaginationGetQuote: () => import('../../components/Pagination/PaginationGetQuote.vue' /* webpackChunkName: "components/pagination-get-quote" */).then(c => wrapFunctional(c.default || c)),
  Pdpa: () => import('../../components/Pdpa/Pdpa.vue' /* webpackChunkName: "components/pdpa" */).then(c => wrapFunctional(c.default || c)),
  Picture: () => import('../../components/Picture/Picture.vue' /* webpackChunkName: "components/picture" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderCard: () => import('../../components/Placeholder/Card.vue' /* webpackChunkName: "components/placeholder-card" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderCardImage: () => import('../../components/Placeholder/CardImage.vue' /* webpackChunkName: "components/placeholder-card-image" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderCardImageOnly: () => import('../../components/Placeholder/CardImageOnly.vue' /* webpackChunkName: "components/placeholder-card-image-only" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderFullTable: () => import('../../components/Placeholder/FullTable.vue' /* webpackChunkName: "components/placeholder-full-table" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderIPOCard: () => import('../../components/Placeholder/IPOCard.vue' /* webpackChunkName: "components/placeholder-i-p-o-card" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderIcon: () => import('../../components/Placeholder/Icon.vue' /* webpackChunkName: "components/placeholder-icon" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderList: () => import('../../components/Placeholder/List.vue' /* webpackChunkName: "components/placeholder-list" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderMarketUpdate: () => import('../../components/Placeholder/MarketUpdate.vue' /* webpackChunkName: "components/placeholder-market-update" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderOppdayCard: () => import('../../components/Placeholder/OppdayCard.vue' /* webpackChunkName: "components/placeholder-oppday-card" */).then(c => wrapFunctional(c.default || c)),
  Placeholder: () => import('../../components/Placeholder/Placeholder.vue' /* webpackChunkName: "components/placeholder" */).then(c => wrapFunctional(c.default || c)),
  PlaceholderTable: () => import('../../components/Placeholder/Table.vue' /* webpackChunkName: "components/placeholder-table" */).then(c => wrapFunctional(c.default || c)),
  Previewer: () => import('../../components/Previewer/Previewer.vue' /* webpackChunkName: "components/previewer" */).then(c => wrapFunctional(c.default || c)),
  QuizChoices: () => import('../../components/Quiz/Choices.vue' /* webpackChunkName: "components/quiz-choices" */).then(c => wrapFunctional(c.default || c)),
  QuizIcon: () => import('../../components/Quiz/Icon.vue' /* webpackChunkName: "components/quiz-icon" */).then(c => wrapFunctional(c.default || c)),
  RelatedArticle: () => import('../../components/Related/Article.vue' /* webpackChunkName: "components/related-article" */).then(c => wrapFunctional(c.default || c)),
  RelatedMediaIcon: () => import('../../components/Related/MediaIcon.vue' /* webpackChunkName: "components/related-media-icon" */).then(c => wrapFunctional(c.default || c)),
  SearchInput: () => import('../../components/Search/Input.vue' /* webpackChunkName: "components/search-input" */).then(c => wrapFunctional(c.default || c)),
  SearchContainer: () => import('../../components/Search/SearchContainer.vue' /* webpackChunkName: "components/search-container" */).then(c => wrapFunctional(c.default || c)),
  SearchSuggestion: () => import('../../components/Search/Suggestion.vue' /* webpackChunkName: "components/search-suggestion" */).then(c => wrapFunctional(c.default || c)),
  SearchSuggestionAdvance: () => import('../../components/Search/SuggestionAdvance.vue' /* webpackChunkName: "components/search-suggestion-advance" */).then(c => wrapFunctional(c.default || c)),
  SharedButton: () => import('../../components/Shared/Button.vue' /* webpackChunkName: "components/shared-button" */).then(c => wrapFunctional(c.default || c)),
  SharedCopyLink: () => import('../../components/Shared/CopyLink.vue' /* webpackChunkName: "components/shared-copy-link" */).then(c => wrapFunctional(c.default || c)),
  SharedItem: () => import('../../components/Shared/Item.vue' /* webpackChunkName: "components/shared-item" */).then(c => wrapFunctional(c.default || c)),
  Shared: () => import('../../components/Shared/index.vue' /* webpackChunkName: "components/shared" */).then(c => wrapFunctional(c.default || c)),
  SmartBanner: () => import('../../components/SmartBanner/SmartBanner.vue' /* webpackChunkName: "components/smart-banner" */).then(c => wrapFunctional(c.default || c)),
  SwiperCarousel: () => import('../../components/SwiperCarousel/SwiperCarousel.vue' /* webpackChunkName: "components/swiper-carousel" */).then(c => wrapFunctional(c.default || c)),
  Table: () => import('../../components/Table/Table.vue' /* webpackChunkName: "components/table" */).then(c => wrapFunctional(c.default || c)),
  TableAdvanced: () => import('../../components/Table/TableAdvanced.vue' /* webpackChunkName: "components/table-advanced" */).then(c => wrapFunctional(c.default || c)),
  TableCustomField: () => import('../../components/Table/TableCustomField.vue' /* webpackChunkName: "components/table-custom-field" */).then(c => wrapFunctional(c.default || c)),
  TableDraggableColumn: () => import('../../components/Table/TableDraggableColumn.vue' /* webpackChunkName: "components/table-draggable-column" */).then(c => wrapFunctional(c.default || c)),
  TableGroupColumn: () => import('../../components/Table/TableGroupColumn.vue' /* webpackChunkName: "components/table-group-column" */).then(c => wrapFunctional(c.default || c)),
  TableGroupColumnServer: () => import('../../components/Table/TableGroupColumnServer.vue' /* webpackChunkName: "components/table-group-column-server" */).then(c => wrapFunctional(c.default || c)),
  TableSimple: () => import('../../components/Table/TableSimple.vue' /* webpackChunkName: "components/table-simple" */).then(c => wrapFunctional(c.default || c)),
  TipsCorner: () => import('../../components/Tips/Corner.vue' /* webpackChunkName: "components/tips-corner" */).then(c => wrapFunctional(c.default || c)),
  TipsIcon: () => import('../../components/Tips/Icon.vue' /* webpackChunkName: "components/tips-icon" */).then(c => wrapFunctional(c.default || c)),
  Tips: () => import('../../components/Tips/Tips.vue' /* webpackChunkName: "components/tips" */).then(c => wrapFunctional(c.default || c)),
  Toasts: () => import('../../components/Toasts/Toasts.vue' /* webpackChunkName: "components/toasts" */).then(c => wrapFunctional(c.default || c)),
  TooltipInformation: () => import('../../components/Tooltip/Information.vue' /* webpackChunkName: "components/tooltip-information" */).then(c => wrapFunctional(c.default || c)),
  Viewer: () => import('../../components/Viewer/Viewer.vue' /* webpackChunkName: "components/viewer" */).then(c => wrapFunctional(c.default || c)),
  AccordionHorizontal: () => import('../../components/Accordion/Horizontal/AccordionHorizontal.vue' /* webpackChunkName: "components/accordion-horizontal" */).then(c => wrapFunctional(c.default || c)),
  AccordionHorizontalContentItem: () => import('../../components/Accordion/Horizontal/ContentItem.vue' /* webpackChunkName: "components/accordion-horizontal-content-item" */).then(c => wrapFunctional(c.default || c)),
  AccordionHorizontalHeadingItem: () => import('../../components/Accordion/Horizontal/HeadingItem.vue' /* webpackChunkName: "components/accordion-horizontal-heading-item" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationFFFloating: () => import('../../components/Banner/Location/FFFloating.vue' /* webpackChunkName: "components/banner-location-f-f-floating" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationFMMiddle: () => import('../../components/Banner/Location/FMMiddle.vue' /* webpackChunkName: "components/banner-location-f-m-middle" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationFSSquare: () => import('../../components/Banner/Location/FSSquare.vue' /* webpackChunkName: "components/banner-location-f-s-square" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationFSSquare2: () => import('../../components/Banner/Location/FSSquare2.vue' /* webpackChunkName: "components/banner-location-f-s-square2" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationFTTopExpandable: () => import('../../components/Banner/Location/FTTopExpandable.vue' /* webpackChunkName: "components/banner-location-f-t-top-expandable" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationIBBottom: () => import('../../components/Banner/Location/IBBottom.vue' /* webpackChunkName: "components/banner-location-i-b-bottom" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationIMMiddle: () => import('../../components/Banner/Location/IMMiddle.vue' /* webpackChunkName: "components/banner-location-i-m-middle" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationITTop: () => import('../../components/Banner/Location/ITTop.vue' /* webpackChunkName: "components/banner-location-i-t-top" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationMFBeginner: () => import('../../components/Banner/Location/MFBeginner.vue' /* webpackChunkName: "components/banner-location-m-f-beginner" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationMFInsight: () => import('../../components/Banner/Location/MFInsight.vue' /* webpackChunkName: "components/banner-location-m-f-insight" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationOverviewDRx: () => import('../../components/Banner/Location/OverviewDRx.vue' /* webpackChunkName: "components/banner-location-overview-d-rx" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationOverviewDW: () => import('../../components/Banner/Location/OverviewDW.vue' /* webpackChunkName: "components/banner-location-overview-d-w" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationOverviewDerivative: () => import('../../components/Banner/Location/OverviewDerivative.vue' /* webpackChunkName: "components/banner-location-overview-derivative" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationOverviewETF: () => import('../../components/Banner/Location/OverviewETF.vue' /* webpackChunkName: "components/banner-location-overview-e-t-f" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationOverviewMF: () => import('../../components/Banner/Location/OverviewMF.vue' /* webpackChunkName: "components/banner-location-overview-m-f" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationQBBottom: () => import('../../components/Banner/Location/QBBottom.vue' /* webpackChunkName: "components/banner-location-q-b-bottom" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationQSSSkyscraper: () => import('../../components/Banner/Location/QSSSkyscraper.vue' /* webpackChunkName: "components/banner-location-q-s-s-skyscraper" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationQSSquare: () => import('../../components/Banner/Location/QSSquare.vue' /* webpackChunkName: "components/banner-location-q-s-square" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationQTTop: () => import('../../components/Banner/Location/QTTop.vue' /* webpackChunkName: "components/banner-location-q-t-top" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationQVRVerticalRectangle: () => import('../../components/Banner/Location/QVRVerticalRectangle.vue' /* webpackChunkName: "components/banner-location-q-v-r-vertical-rectangle" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationStickyAds: () => import('../../components/Banner/Location/StickyAds.vue' /* webpackChunkName: "components/banner-location-sticky-ads" */).then(c => wrapFunctional(c.default || c)),
  BannerLocationStickyAdsQuote: () => import('../../components/Banner/Location/StickyAdsQuote.vue' /* webpackChunkName: "components/banner-location-sticky-ads-quote" */).then(c => wrapFunctional(c.default || c)),
  AppBarNavMobileMenu: () => import('../../components/AppBar/NavMobile/Menu.vue' /* webpackChunkName: "components/app-bar-nav-mobile-menu" */).then(c => wrapFunctional(c.default || c)),
  AppBarNavMobileMenuCollapse: () => import('../../components/AppBar/NavMobile/MenuCollapse.vue' /* webpackChunkName: "components/app-bar-nav-mobile-menu-collapse" */).then(c => wrapFunctional(c.default || c)),
  AppBarNavMobile: () => import('../../components/AppBar/NavMobile/NavMobile.vue' /* webpackChunkName: "components/app-bar-nav-mobile" */).then(c => wrapFunctional(c.default || c)),
  FooterSitemap: () => import('../../components/Footer/Sitemap/FooterSitemap.vue' /* webpackChunkName: "components/footer-sitemap" */).then(c => wrapFunctional(c.default || c)),
  FooterSubMenu: () => import('../../components/Footer/SubMenu/SubMenu.vue' /* webpackChunkName: "components/footer-sub-menu" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySector: () => import('../../components/Icon/IndustrySector/IconIndustrySector.vue' /* webpackChunkName: "components/icon-industry-sector" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorNoIcon: () => import('../../components/Icon/IndustrySector/NoIcon.vue' /* webpackChunkName: "components/icon-industry-sector-no-icon" */).then(c => wrapFunctional(c.default || c)),
  IconMediaArticle: () => import('../../components/Icon/Media/Article.vue' /* webpackChunkName: "components/icon-media-article" */).then(c => wrapFunctional(c.default || c)),
  IconMediaEBook: () => import('../../components/Icon/Media/EBook.vue' /* webpackChunkName: "components/icon-media-e-book" */).then(c => wrapFunctional(c.default || c)),
  IconMediaELearning: () => import('../../components/Icon/Media/ELearning.vue' /* webpackChunkName: "components/icon-media-e-learning" */).then(c => wrapFunctional(c.default || c)),
  IconMediaGlossary: () => import('../../components/Icon/Media/Glossary.vue' /* webpackChunkName: "components/icon-media-glossary" */).then(c => wrapFunctional(c.default || c)),
  IconMediaInfographic: () => import('../../components/Icon/Media/Infographic.vue' /* webpackChunkName: "components/icon-media-infographic" */).then(c => wrapFunctional(c.default || c)),
  IconMediaKnowledge: () => import('../../components/Icon/Media/Knowledge.vue' /* webpackChunkName: "components/icon-media-knowledge" */).then(c => wrapFunctional(c.default || c)),
  IconMediaNews: () => import('../../components/Icon/Media/News.vue' /* webpackChunkName: "components/icon-media-news" */).then(c => wrapFunctional(c.default || c)),
  IconMediaPlay: () => import('../../components/Icon/Media/Play.vue' /* webpackChunkName: "components/icon-media-play" */).then(c => wrapFunctional(c.default || c)),
  IconMediaPodcast: () => import('../../components/Icon/Media/Podcast.vue' /* webpackChunkName: "components/icon-media-podcast" */).then(c => wrapFunctional(c.default || c)),
  IconMediaResearch: () => import('../../components/Icon/Media/Research.vue' /* webpackChunkName: "components/icon-media-research" */).then(c => wrapFunctional(c.default || c)),
  IconSharedCopied: () => import('../../components/Icon/Shared/Copied.vue' /* webpackChunkName: "components/icon-shared-copied" */).then(c => wrapFunctional(c.default || c)),
  IconSharedCopyLink: () => import('../../components/Icon/Shared/CopyLink.vue' /* webpackChunkName: "components/icon-shared-copy-link" */).then(c => wrapFunctional(c.default || c)),
  IconSharedFacebook: () => import('../../components/Icon/Shared/Facebook.vue' /* webpackChunkName: "components/icon-shared-facebook" */).then(c => wrapFunctional(c.default || c)),
  IconSharedLine: () => import('../../components/Icon/Shared/Line.vue' /* webpackChunkName: "components/icon-shared-line" */).then(c => wrapFunctional(c.default || c)),
  IconSharedTwitter: () => import('../../components/Icon/Shared/Twitter.vue' /* webpackChunkName: "components/icon-shared-twitter" */).then(c => wrapFunctional(c.default || c)),
  LoadingBar: () => import('../../components/Loading/Bar/Bar.vue' /* webpackChunkName: "components/loading-bar" */).then(c => wrapFunctional(c.default || c)),
  LoadingBarLarge: () => import('../../components/Loading/Bar/Large.vue' /* webpackChunkName: "components/loading-bar-large" */).then(c => wrapFunctional(c.default || c)),
  LoadingBarNormal: () => import('../../components/Loading/Bar/Normal.vue' /* webpackChunkName: "components/loading-bar-normal" */).then(c => wrapFunctional(c.default || c)),
  LoadingBarSmall: () => import('../../components/Loading/Bar/Small.vue' /* webpackChunkName: "components/loading-bar-small" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeCover: () => import('../../components/Misc/Derivative/Cover.vue' /* webpackChunkName: "components/misc-derivative-cover" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeModalCostOfCarryModel: () => import('../../components/Misc/Derivative/ModalCostOfCarryModel.vue' /* webpackChunkName: "components/misc-derivative-modal-cost-of-carry-model" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeModalOptionPriceCalculation: () => import('../../components/Misc/Derivative/ModalOptionPriceCalculation.vue' /* webpackChunkName: "components/misc-derivative-modal-option-price-calculation" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeModalUnderlyingInstrument: () => import('../../components/Misc/Derivative/ModalUnderlyingInstrument.vue' /* webpackChunkName: "components/misc-derivative-modal-underlying-instrument" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeNotation: () => import('../../components/Misc/Derivative/Notation.vue' /* webpackChunkName: "components/misc-derivative-notation" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeNotationHistorical: () => import('../../components/Misc/Derivative/NotationHistorical.vue' /* webpackChunkName: "components/misc-derivative-notation-historical" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeTradingCalendar: () => import('../../components/Misc/Derivative/TradingCalendar.vue' /* webpackChunkName: "components/misc-derivative-trading-calendar" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityBackToTopButton: () => import('../../components/Misc/Equity/BackToTopButton.vue' /* webpackChunkName: "components/misc-equity-back-to-top-button" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityButtonTooltip: () => import('../../components/Misc/Equity/ButtonTooltip.vue' /* webpackChunkName: "components/misc-equity-button-tooltip" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityButtonWithIcon: () => import('../../components/Misc/Equity/ButtonWithIcon.vue' /* webpackChunkName: "components/misc-equity-button-with-icon" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityCompanyLogo: () => import('../../components/Misc/Equity/CompanyLogo.vue' /* webpackChunkName: "components/misc-equity-company-logo" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityDatepicker: () => import('../../components/Misc/Equity/Datepicker.vue' /* webpackChunkName: "components/misc-equity-datepicker" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityInputFilter: () => import('../../components/Misc/Equity/InputFilter.vue' /* webpackChunkName: "components/misc-equity-input-filter" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityInputFilterAdvance: () => import('../../components/Misc/Equity/InputFilterAdvance.vue' /* webpackChunkName: "components/misc-equity-input-filter-advance" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityInputFilterAdvanceWithSimple: () => import('../../components/Misc/Equity/InputFilterAdvanceWithSimple.vue' /* webpackChunkName: "components/misc-equity-input-filter-advance-with-simple" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityInputFilterSimple: () => import('../../components/Misc/Equity/InputFilterSimple.vue' /* webpackChunkName: "components/misc-equity-input-filter-simple" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityLineAdvertisement: () => import('../../components/Misc/Equity/LineAdvertisement.vue' /* webpackChunkName: "components/misc-equity-line-advertisement" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityNotation: () => import('../../components/Misc/Equity/Notation.vue' /* webpackChunkName: "components/misc-equity-notation" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityPicture: () => import('../../components/Misc/Equity/Picture.vue' /* webpackChunkName: "components/misc-equity-picture" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityPlaceholder: () => import('../../components/Misc/Equity/Placeholder.vue' /* webpackChunkName: "components/misc-equity-placeholder" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityRiskGauge: () => import('../../components/Misc/Equity/RiskGauge.vue' /* webpackChunkName: "components/misc-equity-risk-gauge" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityRiskLevel: () => import('../../components/Misc/Equity/RiskLevel.vue' /* webpackChunkName: "components/misc-equity-risk-level" */).then(c => wrapFunctional(c.default || c)),
  MiscEquitySelectWithLabel: () => import('../../components/Misc/Equity/SelectWithLabel.vue' /* webpackChunkName: "components/misc-equity-select-with-label" */).then(c => wrapFunctional(c.default || c)),
  MiscEquitySelectWithLabelWithGetQuote: () => import('../../components/Misc/Equity/SelectWithLabelWithGetQuote.vue' /* webpackChunkName: "components/misc-equity-select-with-label-with-get-quote" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityShowAndHideContent: () => import('../../components/Misc/Equity/ShowAndHideContent.vue' /* webpackChunkName: "components/misc-equity-show-and-hide-content" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityShowAndHideIPOContent: () => import('../../components/Misc/Equity/ShowAndHideIPOContent.vue' /* webpackChunkName: "components/misc-equity-show-and-hide-i-p-o-content" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityShowAndHideMarketContent: () => import('../../components/Misc/Equity/ShowAndHideMarketContent.vue' /* webpackChunkName: "components/misc-equity-show-and-hide-market-content" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityTabButtonGroup: () => import('../../components/Misc/Equity/TabButtonGroup.vue' /* webpackChunkName: "components/misc-equity-tab-button-group" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityTabLevel2: () => import('../../components/Misc/Equity/TabLevel2.vue' /* webpackChunkName: "components/misc-equity-tab-level2" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityTableNoData: () => import('../../components/Misc/Equity/TableNoData.vue' /* webpackChunkName: "components/misc-equity-table-no-data" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityTableTitle: () => import('../../components/Misc/Equity/TableTitle.vue' /* webpackChunkName: "components/misc-equity-table-title" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityTooltipContent: () => import('../../components/Misc/Equity/TooltipContent.vue' /* webpackChunkName: "components/misc-equity-tooltip-content" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityUnderlyingSecurity: () => import('../../components/Misc/Equity/UnderlyingSecurity.vue' /* webpackChunkName: "components/misc-equity-underlying-security" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityUnderlyingSecurityOther: () => import('../../components/Misc/Equity/UnderlyingSecurityOther.vue' /* webpackChunkName: "components/misc-equity-underlying-security-other" */).then(c => wrapFunctional(c.default || c)),
  MiscError500: () => import('../../components/Misc/Error/Error500.vue' /* webpackChunkName: "components/misc-error500" */).then(c => wrapFunctional(c.default || c)),
  MiscError503: () => import('../../components/Misc/Error/Error503.vue' /* webpackChunkName: "components/misc-error503" */).then(c => wrapFunctional(c.default || c)),
  MiscErrorVersion: () => import('../../components/Misc/Error/ErrorVersion.vue' /* webpackChunkName: "components/misc-error-version" */).then(c => wrapFunctional(c.default || c)),
  MiscErrorOpera: () => import('../../components/Misc/Error/Opera.vue' /* webpackChunkName: "components/misc-error-opera" */).then(c => wrapFunctional(c.default || c)),
  MiscErrorSafari: () => import('../../components/Misc/Error/Safari.vue' /* webpackChunkName: "components/misc-error-safari" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberCover: () => import('../../components/Misc/Member/Cover.vue' /* webpackChunkName: "components/misc-member-cover" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMainTabs: () => import('../../components/Misc/Member/MainTabs.vue' /* webpackChunkName: "components/misc-member-main-tabs" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundPined: () => import('../../components/Misc/MutualFund/Pined.vue' /* webpackChunkName: "components/misc-mutual-fund-pined" */).then(c => wrapFunctional(c.default || c)),
  MiscNewsAndArticlesTagSymbol: () => import('../../components/Misc/NewsAndArticles/TagSymbol.vue' /* webpackChunkName: "components/misc-news-and-articles-tag-symbol" */).then(c => wrapFunctional(c.default || c)),
  MiscQuoteModalAddPort: () => import('../../components/Misc/Quote/ModalAddPort.vue' /* webpackChunkName: "components/misc-quote-modal-add-port" */).then(c => wrapFunctional(c.default || c)),
  MiscQuoteModalAddQuote: () => import('../../components/Misc/Quote/ModalAddQuote.vue' /* webpackChunkName: "components/misc-quote-modal-add-quote" */).then(c => wrapFunctional(c.default || c)),
  MiscRecentlyViewedButtonClearRecent: () => import('../../components/Misc/RecentlyViewed/ButtonClearRecent.vue' /* webpackChunkName: "components/misc-recently-viewed-button-clear-recent" */).then(c => wrapFunctional(c.default || c)),
  MiscRecentlyViewedModalDelete: () => import('../../components/Misc/RecentlyViewed/ModalDelete.vue' /* webpackChunkName: "components/misc-recently-viewed-modal-delete" */).then(c => wrapFunctional(c.default || c)),
  MiscResearchLoading: () => import('../../components/Misc/Research/Loading.vue' /* webpackChunkName: "components/misc-research-loading" */).then(c => wrapFunctional(c.default || c)),
  MiscResearchMainContainer: () => import('../../components/Misc/Research/MainContainer.vue' /* webpackChunkName: "components/misc-research-main-container" */).then(c => wrapFunctional(c.default || c)),
  MiscResearchMainTabs: () => import('../../components/Misc/Research/MainTabs.vue' /* webpackChunkName: "components/misc-research-main-tabs" */).then(c => wrapFunctional(c.default || c)),
  RelatedContentCard: () => import('../../components/Related/Content/Card.vue' /* webpackChunkName: "components/related-content-card" */).then(c => wrapFunctional(c.default || c)),
  RelatedContent: () => import('../../components/Related/Content/index.vue' /* webpackChunkName: "components/related-content" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorAgroAgri: () => import('../../components/Icon/IndustrySector/Agro/Agri.vue' /* webpackChunkName: "components/icon-industry-sector-agro-agri" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorAgro: () => import('../../components/Icon/IndustrySector/Agro/Agro.vue' /* webpackChunkName: "components/icon-industry-sector-agro" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorAgroFood: () => import('../../components/Icon/IndustrySector/Agro/Food.vue' /* webpackChunkName: "components/icon-industry-sector-agro-food" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorConsump: () => import('../../components/Icon/IndustrySector/Consump/Consump.vue' /* webpackChunkName: "components/icon-industry-sector-consump" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorConsumpFashion: () => import('../../components/Icon/IndustrySector/Consump/Fashion.vue' /* webpackChunkName: "components/icon-industry-sector-consump-fashion" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorConsumpHome: () => import('../../components/Icon/IndustrySector/Consump/Home.vue' /* webpackChunkName: "components/icon-industry-sector-consump-home" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorConsumpPerson: () => import('../../components/Icon/IndustrySector/Consump/Person.vue' /* webpackChunkName: "components/icon-industry-sector-consump-person" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorFincialBank: () => import('../../components/Icon/IndustrySector/Fincial/Bank.vue' /* webpackChunkName: "components/icon-industry-sector-fincial-bank" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorFincialFin: () => import('../../components/Icon/IndustrySector/Fincial/Fin.vue' /* webpackChunkName: "components/icon-industry-sector-fincial-fin" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorFincial: () => import('../../components/Icon/IndustrySector/Fincial/Fincial.vue' /* webpackChunkName: "components/icon-industry-sector-fincial" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorFincialInsur: () => import('../../components/Icon/IndustrySector/Fincial/Insur.vue' /* webpackChunkName: "components/icon-industry-sector-fincial-insur" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorIndusAuto: () => import('../../components/Icon/IndustrySector/Indus/Auto.vue' /* webpackChunkName: "components/icon-industry-sector-indus-auto" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorIndusImm: () => import('../../components/Icon/IndustrySector/Indus/Imm.vue' /* webpackChunkName: "components/icon-industry-sector-indus-imm" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorIndus: () => import('../../components/Icon/IndustrySector/Indus/Indus.vue' /* webpackChunkName: "components/icon-industry-sector-indus" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorIndusPaper: () => import('../../components/Icon/IndustrySector/Indus/Paper.vue' /* webpackChunkName: "components/icon-industry-sector-indus-paper" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorIndusPetro: () => import('../../components/Icon/IndustrySector/Indus/Petro.vue' /* webpackChunkName: "components/icon-industry-sector-indus-petro" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorIndusPkg: () => import('../../components/Icon/IndustrySector/Indus/Pkg.vue' /* webpackChunkName: "components/icon-industry-sector-indus-pkg" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorIndusSteel: () => import('../../components/Icon/IndustrySector/Indus/Steel.vue' /* webpackChunkName: "components/icon-industry-sector-indus-steel" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorPropconConmat: () => import('../../components/Icon/IndustrySector/Propcon/Conmat.vue' /* webpackChunkName: "components/icon-industry-sector-propcon-conmat" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorPropconCons: () => import('../../components/Icon/IndustrySector/Propcon/Cons.vue' /* webpackChunkName: "components/icon-industry-sector-propcon-cons" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorPropconPfANDReit: () => import('../../components/Icon/IndustrySector/Propcon/PfANDReit.vue' /* webpackChunkName: "components/icon-industry-sector-propcon-pf-a-n-d-reit" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorPropconProp: () => import('../../components/Icon/IndustrySector/Propcon/Prop.vue' /* webpackChunkName: "components/icon-industry-sector-propcon-prop" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorPropcon: () => import('../../components/Icon/IndustrySector/Propcon/Propcon.vue' /* webpackChunkName: "components/icon-industry-sector-propcon" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorResourcEnerg: () => import('../../components/Icon/IndustrySector/Resourc/Energ.vue' /* webpackChunkName: "components/icon-industry-sector-resourc-energ" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorResourcMine: () => import('../../components/Icon/IndustrySector/Resourc/Mine.vue' /* webpackChunkName: "components/icon-industry-sector-resourc-mine" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorResourc: () => import('../../components/Icon/IndustrySector/Resourc/Resourc.vue' /* webpackChunkName: "components/icon-industry-sector-resourc" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorServiceComm: () => import('../../components/Icon/IndustrySector/Service/Comm.vue' /* webpackChunkName: "components/icon-industry-sector-service-comm" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorServiceHelth: () => import('../../components/Icon/IndustrySector/Service/Helth.vue' /* webpackChunkName: "components/icon-industry-sector-service-helth" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorServiceMedia: () => import('../../components/Icon/IndustrySector/Service/Media.vue' /* webpackChunkName: "components/icon-industry-sector-service-media" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorServiceProf: () => import('../../components/Icon/IndustrySector/Service/Prof.vue' /* webpackChunkName: "components/icon-industry-sector-service-prof" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorService: () => import('../../components/Icon/IndustrySector/Service/Service.vue' /* webpackChunkName: "components/icon-industry-sector-service" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorServiceTourism: () => import('../../components/Icon/IndustrySector/Service/Tourism.vue' /* webpackChunkName: "components/icon-industry-sector-service-tourism" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorServiceTrans: () => import('../../components/Icon/IndustrySector/Service/Trans.vue' /* webpackChunkName: "components/icon-industry-sector-service-trans" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorTechEtron: () => import('../../components/Icon/IndustrySector/Tech/Etron.vue' /* webpackChunkName: "components/icon-industry-sector-tech-etron" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorTechIct: () => import('../../components/Icon/IndustrySector/Tech/Ict.vue' /* webpackChunkName: "components/icon-industry-sector-tech-ict" */).then(c => wrapFunctional(c.default || c)),
  IconIndustrySectorTech: () => import('../../components/Icon/IndustrySector/Tech/Tech.vue' /* webpackChunkName: "components/icon-industry-sector-tech" */).then(c => wrapFunctional(c.default || c)),
  IconSharedPageCopyLink: () => import('../../components/Icon/Shared/Page/CopyLink.vue' /* webpackChunkName: "components/icon-shared-page-copy-link" */).then(c => wrapFunctional(c.default || c)),
  IconSharedPageFacebook: () => import('../../components/Icon/Shared/Page/Facebook.vue' /* webpackChunkName: "components/icon-shared-page-facebook" */).then(c => wrapFunctional(c.default || c)),
  IconSharedPageLine: () => import('../../components/Icon/Shared/Page/Line.vue' /* webpackChunkName: "components/icon-shared-page-line" */).then(c => wrapFunctional(c.default || c)),
  IconSharedPageTwitter: () => import('../../components/Icon/Shared/Page/Twitter.vue' /* webpackChunkName: "components/icon-shared-page-twitter" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeAnalystConsensus: () => import('../../components/Misc/Derivative/AnalystConsensus/AnalystConsensus.vue' /* webpackChunkName: "components/misc-derivative-analyst-consensus" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeAnalystConsensusGraph: () => import('../../components/Misc/Derivative/AnalystConsensus/Graph.vue' /* webpackChunkName: "components/misc-derivative-analyst-consensus-graph" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeAnalystConsensusRecommend: () => import('../../components/Misc/Derivative/AnalystConsensus/Recommend.vue' /* webpackChunkName: "components/misc-derivative-analyst-consensus-recommend" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeAnalystConsensusResearch: () => import('../../components/Misc/Derivative/AnalystConsensus/Research.vue' /* webpackChunkName: "components/misc-derivative-analyst-consensus-research" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeAnalystConsensusTable: () => import('../../components/Misc/Derivative/AnalystConsensus/Table.vue' /* webpackChunkName: "components/misc-derivative-analyst-consensus-table" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeAnalystConsensusTfexConsensus: () => import('../../components/Misc/Derivative/AnalystConsensus/TfexConsensus.vue' /* webpackChunkName: "components/misc-derivative-analyst-consensus-tfex-consensus" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeHistoricalBySession: () => import('../../components/Misc/Derivative/Historical/BySession.vue' /* webpackChunkName: "components/misc-derivative-historical-by-session" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeHistoricalChartPriceHistory: () => import('../../components/Misc/Derivative/Historical/ChartPriceHistory.vue' /* webpackChunkName: "components/misc-derivative-historical-chart-price-history" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeHistoricalPriceHistory: () => import('../../components/Misc/Derivative/Historical/PriceHistory.vue' /* webpackChunkName: "components/misc-derivative-historical-price-history" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeHistoricalTradingDay: () => import('../../components/Misc/Derivative/Historical/TradingDay.vue' /* webpackChunkName: "components/misc-derivative-historical-trading-day" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeMarketDataBuySellByContractOption: () => import('../../components/Misc/Derivative/MarketData/BuySellByContractOption.vue' /* webpackChunkName: "components/misc-derivative-market-data-buy-sell-by-contract-option" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeOverviewBuySellByContractFuture: () => import('../../components/Misc/Derivative/Overview/BuySellByContractFuture.vue' /* webpackChunkName: "components/misc-derivative-overview-buy-sell-by-contract-future" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeOverviewBuySellByContractOption: () => import('../../components/Misc/Derivative/Overview/BuySellByContractOption.vue' /* webpackChunkName: "components/misc-derivative-overview-buy-sell-by-contract-option" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeOverviewBuySellByContractOptionTable: () => import('../../components/Misc/Derivative/Overview/BuySellByContractOptionTable.vue' /* webpackChunkName: "components/misc-derivative-overview-buy-sell-by-contract-option-table" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeOverviewBuySellInformation: () => import('../../components/Misc/Derivative/Overview/BuySellInformation.vue' /* webpackChunkName: "components/misc-derivative-overview-buy-sell-information" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeOverviewChartPriceLasted: () => import('../../components/Misc/Derivative/Overview/ChartPriceLasted.vue' /* webpackChunkName: "components/misc-derivative-overview-chart-price-lasted" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeOverviewSessionComparison: () => import('../../components/Misc/Derivative/Overview/SessionComparison.vue' /* webpackChunkName: "components/misc-derivative-overview-session-comparison" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeOverviewUnderlying: () => import('../../components/Misc/Derivative/Overview/Underlying.vue' /* webpackChunkName: "components/misc-derivative-overview-underlying" */).then(c => wrapFunctional(c.default || c)),
  MiscDerivativeOverviewUnderlyingExternal: () => import('../../components/Misc/Derivative/Overview/UnderlyingExternal.vue' /* webpackChunkName: "components/misc-derivative-overview-underlying-external" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityAnalystConcensusCardViewAnalyst: () => import('../../components/Misc/Equity/AnalystConcensus/CardViewAnalyst.vue' /* webpackChunkName: "components/misc-equity-analyst-concensus-card-view-analyst" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityAnalystConcensusGraph: () => import('../../components/Misc/Equity/AnalystConcensus/Graph.vue' /* webpackChunkName: "components/misc-equity-analyst-concensus-graph" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityAnalystConcensusRecommendOverall: () => import('../../components/Misc/Equity/AnalystConcensus/RecommendOverall.vue' /* webpackChunkName: "components/misc-equity-analyst-concensus-recommend-overall" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityAnalystConcensusTable: () => import('../../components/Misc/Equity/AnalystConcensus/Table.vue' /* webpackChunkName: "components/misc-equity-analyst-concensus-table" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityBenefit: () => import('../../components/Misc/Equity/Benefit/Benefit.vue' /* webpackChunkName: "components/misc-equity-benefit" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityBenefitTabPaneAll: () => import('../../components/Misc/Equity/Benefit/BenefitTabPaneAll.vue' /* webpackChunkName: "components/misc-equity-benefit-tab-pane-all" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityBenefitTabPaneContent: () => import('../../components/Misc/Equity/Benefit/BenefitTabPaneContent.vue' /* webpackChunkName: "components/misc-equity-benefit-tab-pane-content" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityCoverChartMicroLine: () => import('../../components/Misc/Equity/Cover/ChartMicroLine.vue' /* webpackChunkName: "components/misc-equity-cover-chart-micro-line" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityCoverOther: () => import('../../components/Misc/Equity/Cover/CoverOther.vue' /* webpackChunkName: "components/misc-equity-cover-other" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityCoverWarrant: () => import('../../components/Misc/Equity/Cover/CoverWarrant.vue' /* webpackChunkName: "components/misc-equity-cover-warrant" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityCoverPrice: () => import('../../components/Misc/Equity/Cover/Price.vue' /* webpackChunkName: "components/misc-equity-cover-price" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityCoverVolumn: () => import('../../components/Misc/Equity/Cover/Volumn.vue' /* webpackChunkName: "components/misc-equity-cover-volumn" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityDRMarketCapChart: () => import('../../components/Misc/Equity/DR/MarketCapChart.vue' /* webpackChunkName: "components/misc-equity-d-r-market-cap-chart" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityDROutstandingChart: () => import('../../components/Misc/Equity/DR/OutstandingChart.vue' /* webpackChunkName: "components/misc-equity-d-r-outstanding-chart" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityESGCard: () => import('../../components/Misc/Equity/ESG/Card.vue' /* webpackChunkName: "components/misc-equity-e-s-g-card" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityESGCardItem: () => import('../../components/Misc/Equity/ESG/CardItem.vue' /* webpackChunkName: "components/misc-equity-e-s-g-card-item" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityESGCardItemRateing: () => import('../../components/Misc/Equity/ESG/CardItemRateing.vue' /* webpackChunkName: "components/misc-equity-e-s-g-card-item-rateing" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityESGCardItemReport: () => import('../../components/Misc/Equity/ESG/CardItemReport.vue' /* webpackChunkName: "components/misc-equity-e-s-g-card-item-report" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityESGSwiper: () => import('../../components/Misc/Equity/ESG/Swiper.vue' /* webpackChunkName: "components/misc-equity-e-s-g-swiper" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityESGSwiperSlide: () => import('../../components/Misc/Equity/ESG/SwiperSlide.vue' /* webpackChunkName: "components/misc-equity-e-s-g-swiper-slide" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityESGInvesmentCard: () => import('../../components/Misc/Equity/ESGInvesment/Card.vue' /* webpackChunkName: "components/misc-equity-e-s-g-invesment-card" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityESGInvesmentImageCGR: () => import('../../components/Misc/Equity/ESGInvesment/ImageCGR.vue' /* webpackChunkName: "components/misc-equity-e-s-g-invesment-image-c-g-r" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityESGInvesmentImageCGRM: () => import('../../components/Misc/Equity/ESGInvesment/ImageCGRM.vue' /* webpackChunkName: "components/misc-equity-e-s-g-invesment-image-c-g-r-m" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityESGInvesmentImageCheck: () => import('../../components/Misc/Equity/ESGInvesment/ImageCheck.vue' /* webpackChunkName: "components/misc-equity-e-s-g-invesment-image-check" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityESGInvesmentImageDJSI: () => import('../../components/Misc/Equity/ESGInvesment/ImageDJSI.vue' /* webpackChunkName: "components/misc-equity-e-s-g-invesment-image-d-j-s-i" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityESGInvesmentImageDJSIM: () => import('../../components/Misc/Equity/ESGInvesment/ImageDJSIM.vue' /* webpackChunkName: "components/misc-equity-e-s-g-invesment-image-d-j-s-i-m" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityESGInvesmentImageESG: () => import('../../components/Misc/Equity/ESGInvesment/ImageESG.vue' /* webpackChunkName: "components/misc-equity-e-s-g-invesment-image-e-s-g" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityESGInvesmentImageESGM: () => import('../../components/Misc/Equity/ESGInvesment/ImageESGM.vue' /* webpackChunkName: "components/misc-equity-e-s-g-invesment-image-e-s-g-m" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityESGInvesmentImageLock: () => import('../../components/Misc/Equity/ESGInvesment/ImageLock.vue' /* webpackChunkName: "components/misc-equity-e-s-g-invesment-image-lock" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityESGInvesmentImageSETTHSI: () => import('../../components/Misc/Equity/ESGInvesment/ImageSETTHSI.vue' /* webpackChunkName: "components/misc-equity-e-s-g-invesment-image-s-e-t-t-h-s-i" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityESGInvesmentImageSETTHSIM: () => import('../../components/Misc/Equity/ESGInvesment/ImageSETTHSIM.vue' /* webpackChunkName: "components/misc-equity-e-s-g-invesment-image-s-e-t-t-h-s-i-m" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityESGInvesmentImageTHSI: () => import('../../components/Misc/Equity/ESGInvesment/ImageTHSI.vue' /* webpackChunkName: "components/misc-equity-e-s-g-invesment-image-t-h-s-i" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityESGInvesmentImageTHSIM: () => import('../../components/Misc/Equity/ESGInvesment/ImageTHSIM.vue' /* webpackChunkName: "components/misc-equity-e-s-g-invesment-image-t-h-s-i-m" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityESGInvesmentImageUncheck: () => import('../../components/Misc/Equity/ESGInvesment/ImageUncheck.vue' /* webpackChunkName: "components/misc-equity-e-s-g-invesment-image-uncheck" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityETFImportantStatistics: () => import('../../components/Misc/Equity/ETF/ImportantStatistics.vue' /* webpackChunkName: "components/misc-equity-e-t-f-important-statistics" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityETFNAVDetails: () => import('../../components/Misc/Equity/ETF/NAVDetails.vue' /* webpackChunkName: "components/misc-equity-e-t-f-n-a-v-details" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityFinancialStatementAccountB: () => import('../../components/Misc/Equity/FinancialStatement/AccountB.vue' /* webpackChunkName: "components/misc-equity-financial-statement-account-b" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityFinancialStatementAccountBF: () => import('../../components/Misc/Equity/FinancialStatement/AccountBF.vue' /* webpackChunkName: "components/misc-equity-financial-statement-account-b-f" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityFinancialStatementAccountBFG: () => import('../../components/Misc/Equity/FinancialStatement/AccountBFG.vue' /* webpackChunkName: "components/misc-equity-financial-statement-account-b-f-g" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityFinancialStatementAccountBU: () => import('../../components/Misc/Equity/FinancialStatement/AccountBU.vue' /* webpackChunkName: "components/misc-equity-financial-statement-account-b-u" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityFinancialStatementAccountChart: () => import('../../components/Misc/Equity/FinancialStatement/AccountChart.vue' /* webpackChunkName: "components/misc-equity-financial-statement-account-chart" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityFinancialStatementAccountNormal: () => import('../../components/Misc/Equity/FinancialStatement/AccountNormal.vue' /* webpackChunkName: "components/misc-equity-financial-statement-account-normal" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityFinancialStatementDropdownSearch: () => import('../../components/Misc/Equity/FinancialStatement/DropdownSearch.vue' /* webpackChunkName: "components/misc-equity-financial-statement-dropdown-search" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityFinancialStatementTableHighlightFinancial: () => import('../../components/Misc/Equity/FinancialStatement/TableHighlightFinancial.vue' /* webpackChunkName: "components/misc-equity-financial-statement-table-highlight-financial" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityGetQuote: () => import('../../components/Misc/Equity/GetQuote/GetQuote.vue' /* webpackChunkName: "components/misc-equity-get-quote" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityGetQuoteContainer: () => import('../../components/Misc/Equity/GetQuote/GetQuoteContainer.vue' /* webpackChunkName: "components/misc-equity-get-quote-container" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityHistoricalChartPriceHistory: () => import('../../components/Misc/Equity/Historical/ChartPriceHistory.vue' /* webpackChunkName: "components/misc-equity-historical-chart-price-history" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityHistoricalPriceEquity: () => import('../../components/Misc/Equity/Historical/PriceEquity.vue' /* webpackChunkName: "components/misc-equity-historical-price-equity" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityHistoricalPriceEquityDRX: () => import('../../components/Misc/Equity/Historical/PriceEquityDRX.vue' /* webpackChunkName: "components/misc-equity-historical-price-equity-d-r-x" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityHistoricalPriceEquityFund: () => import('../../components/Misc/Equity/Historical/PriceEquityFund.vue' /* webpackChunkName: "components/misc-equity-historical-price-equity-fund" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityHistoricalPriceEquityStock: () => import('../../components/Misc/Equity/Historical/PriceEquityStock.vue' /* webpackChunkName: "components/misc-equity-historical-price-equity-stock" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOCustomizeCheckBox: () => import('../../components/Misc/Equity/IPO/CustomizeCheckBox.vue' /* webpackChunkName: "components/misc-equity-i-p-o-customize-check-box" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOCustomizeDropdown: () => import('../../components/Misc/Equity/IPO/CustomizeDropdown.vue' /* webpackChunkName: "components/misc-equity-i-p-o-customize-dropdown" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOModalGraphPriceperformance: () => import('../../components/Misc/Equity/IPO/ModalGraphPriceperformance.vue' /* webpackChunkName: "components/misc-equity-i-p-o-modal-graph-priceperformance" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOTabs: () => import('../../components/Misc/Equity/IPO/Tabs.vue' /* webpackChunkName: "components/misc-equity-i-p-o-tabs" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityMarketCardStatus: () => import('../../components/Misc/Equity/Market/CardStatus.vue' /* webpackChunkName: "components/misc-equity-market-card-status" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityMarketInputSelect: () => import('../../components/Misc/Equity/Market/InputSelect.vue' /* webpackChunkName: "components/misc-equity-market-input-select" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityMarketNews: () => import('../../components/Misc/Equity/Market/News.vue' /* webpackChunkName: "components/misc-equity-market-news" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityOverviewBuySellInformation: () => import('../../components/Misc/Equity/Overview/BuySellInformation.vue' /* webpackChunkName: "components/misc-equity-overview-buy-sell-information" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityOverviewCardContent: () => import('../../components/Misc/Equity/Overview/CardContent.vue' /* webpackChunkName: "components/misc-equity-overview-card-content" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityOverviewChartPriceLasted: () => import('../../components/Misc/Equity/Overview/ChartPriceLasted.vue' /* webpackChunkName: "components/misc-equity-overview-chart-price-lasted" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityOverviewChartPriceLasted2: () => import('../../components/Misc/Equity/Overview/ChartPriceLasted2.vue' /* webpackChunkName: "components/misc-equity-overview-chart-price-lasted2" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityOverviewChartPriceLastedDrAndDrx: () => import('../../components/Misc/Equity/Overview/ChartPriceLastedDrAndDrx.vue' /* webpackChunkName: "components/misc-equity-overview-chart-price-lasted-dr-and-drx" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityOverviewCompanyInformation: () => import('../../components/Misc/Equity/Overview/CompanyInformation.vue' /* webpackChunkName: "components/misc-equity-overview-company-information" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityOverviewImportantFinancialInformation: () => import('../../components/Misc/Equity/Overview/ImportantFinancialInformation.vue' /* webpackChunkName: "components/misc-equity-overview-important-financial-information" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityOverviewImportantFinancialInformationShort: () => import('../../components/Misc/Equity/Overview/ImportantFinancialInformationShort.vue' /* webpackChunkName: "components/misc-equity-overview-important-financial-information-short" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityOverviewImportantFinancialInformationWarrant: () => import('../../components/Misc/Equity/Overview/ImportantFinancialInformationWarrant.vue' /* webpackChunkName: "components/misc-equity-overview-important-financial-information-warrant" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityOverviewImportantStatistics: () => import('../../components/Misc/Equity/Overview/ImportantStatistics.vue' /* webpackChunkName: "components/misc-equity-overview-important-statistics" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityOverviewImportantStatisticsShort: () => import('../../components/Misc/Equity/Overview/ImportantStatisticsShort.vue' /* webpackChunkName: "components/misc-equity-overview-important-statistics-short" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityOverviewIndicatorsDataWarrant: () => import('../../components/Misc/Equity/Overview/IndicatorsDataWarrant.vue' /* webpackChunkName: "components/misc-equity-overview-indicators-data-warrant" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityOverviewProductRelate: () => import('../../components/Misc/Equity/Overview/ProductRelate.vue' /* webpackChunkName: "components/misc-equity-overview-product-relate" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityOverviewWarrantInformation: () => import('../../components/Misc/Equity/Overview/WarrantInformation.vue' /* webpackChunkName: "components/misc-equity-overview-warrant-information" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityNewsCard: () => import('../../components/Misc/Equity/News/Card.vue' /* webpackChunkName: "components/misc-equity-news-card" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityNewsMovement: () => import('../../components/Misc/Equity/News/Movement.vue' /* webpackChunkName: "components/misc-equity-news-movement" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityNews: () => import('../../components/Misc/Equity/News/News.vue' /* webpackChunkName: "components/misc-equity-news" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityNewsItem: () => import('../../components/Misc/Equity/News/NewsItem.vue' /* webpackChunkName: "components/misc-equity-news-item" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityNewsSecurities: () => import('../../components/Misc/Equity/News/Securities.vue' /* webpackChunkName: "components/misc-equity-news-securities" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityNewsTagCate: () => import('../../components/Misc/Equity/News/TagCate.vue' /* webpackChunkName: "components/misc-equity-news-tag-cate" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityNewsTagFinancial: () => import('../../components/Misc/Equity/News/TagFinancial.vue' /* webpackChunkName: "components/misc-equity-news-tag-financial" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityNewsTagPin: () => import('../../components/Misc/Equity/News/TagPin.vue' /* webpackChunkName: "components/misc-equity-news-tag-pin" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityNewsTagSymbol: () => import('../../components/Misc/Equity/News/TagSymbol.vue' /* webpackChunkName: "components/misc-equity-news-tag-symbol" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityNewsTagToday: () => import('../../components/Misc/Equity/News/TagToday.vue' /* webpackChunkName: "components/misc-equity-news-tag-today" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityNewsViewAll: () => import('../../components/Misc/Equity/News/ViewAll.vue' /* webpackChunkName: "components/misc-equity-news-view-all" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityRelatedResearch: () => import('../../components/Misc/Equity/RelatedResearch/RelatedResearch.vue' /* webpackChunkName: "components/misc-equity-related-research" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityRelatedResearchCard: () => import('../../components/Misc/Equity/RelatedResearch/RelatedResearchCard.vue' /* webpackChunkName: "components/misc-equity-related-research-card" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityRelatedResearchSwiper: () => import('../../components/Misc/Equity/RelatedResearch/RelatedResearchSwiper.vue' /* webpackChunkName: "components/misc-equity-related-research-swiper" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityResearch: () => import('../../components/Misc/Equity/Research/Index.vue' /* webpackChunkName: "components/misc-equity-research" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityResearchItem: () => import('../../components/Misc/Equity/Research/Item.vue' /* webpackChunkName: "components/misc-equity-research-item" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityResearchTopic: () => import('../../components/Misc/Equity/Research/Topic.vue' /* webpackChunkName: "components/misc-equity-research-topic" */).then(c => wrapFunctional(c.default || c)),
  MiscEquitySectorComparisonCardBanner: () => import('../../components/Misc/Equity/SectorComparison/CardBanner.vue' /* webpackChunkName: "components/misc-equity-sector-comparison-card-banner" */).then(c => wrapFunctional(c.default || c)),
  MiscEquitySectorComparisonFilter: () => import('../../components/Misc/Equity/SectorComparison/Filter.vue' /* webpackChunkName: "components/misc-equity-sector-comparison-filter" */).then(c => wrapFunctional(c.default || c)),
  MiscEquitySectorComparison: () => import('../../components/Misc/Equity/SectorComparison/SectorComparison.vue' /* webpackChunkName: "components/misc-equity-sector-comparison" */).then(c => wrapFunctional(c.default || c)),
  MiscEquitySectorComparisonTable: () => import('../../components/Misc/Equity/SectorComparison/Table.vue' /* webpackChunkName: "components/misc-equity-sector-comparison-table" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityStockScreeningCover: () => import('../../components/Misc/Equity/StockScreening/Cover.vue' /* webpackChunkName: "components/misc-equity-stock-screening-cover" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityStockScreeningDropdownOperator: () => import('../../components/Misc/Equity/StockScreening/DropdownOperator.vue' /* webpackChunkName: "components/misc-equity-stock-screening-dropdown-operator" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityStockScreeningFilter: () => import('../../components/Misc/Equity/StockScreening/Filter.vue' /* webpackChunkName: "components/misc-equity-stock-screening-filter" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityStockScreeningInputCheckBox: () => import('../../components/Misc/Equity/StockScreening/InputCheckBox.vue' /* webpackChunkName: "components/misc-equity-stock-screening-input-check-box" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityStockScreeningInputFilter: () => import('../../components/Misc/Equity/StockScreening/InputFilter.vue' /* webpackChunkName: "components/misc-equity-stock-screening-input-filter" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityStockScreeningInputNumber: () => import('../../components/Misc/Equity/StockScreening/InputNumber.vue' /* webpackChunkName: "components/misc-equity-stock-screening-input-number" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityStockScreeningLoading: () => import('../../components/Misc/Equity/StockScreening/Loading.vue' /* webpackChunkName: "components/misc-equity-stock-screening-loading" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityStockScreeningNoSelectCustom: () => import('../../components/Misc/Equity/StockScreening/NoSelectCustom.vue' /* webpackChunkName: "components/misc-equity-stock-screening-no-select-custom" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityStockScreeningResultTabFilter: () => import('../../components/Misc/Equity/StockScreening/ResultTabFilter.vue' /* webpackChunkName: "components/misc-equity-stock-screening-result-tab-filter" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityStockScreeningTab: () => import('../../components/Misc/Equity/StockScreening/Tab.vue' /* webpackChunkName: "components/misc-equity-stock-screening-tab" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityStockScreeningTableConditionFilter: () => import('../../components/Misc/Equity/StockScreening/TableConditionFilter.vue' /* webpackChunkName: "components/misc-equity-stock-screening-table-condition-filter" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityStockScreeningTableHideData: () => import('../../components/Misc/Equity/StockScreening/TableHideData.vue' /* webpackChunkName: "components/misc-equity-stock-screening-table-hide-data" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityStockScreeningTableOverviewSecurities: () => import('../../components/Misc/Equity/StockScreening/TableOverviewSecurities.vue' /* webpackChunkName: "components/misc-equity-stock-screening-table-overview-securities" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityStockScreeningTablePriceYield: () => import('../../components/Misc/Equity/StockScreening/TablePriceYield.vue' /* webpackChunkName: "components/misc-equity-stock-screening-table-price-yield" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityStockScreeningTopic: () => import('../../components/Misc/Equity/StockScreening/Topic.vue' /* webpackChunkName: "components/misc-equity-stock-screening-topic" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingCalculateResult: () => import('../../components/Misc/Member/Backtesting/CalculateResult.vue' /* webpackChunkName: "components/misc-member-backtesting-calculate-result" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingCardBacktesting: () => import('../../components/Misc/Member/Backtesting/CardBacktesting.vue' /* webpackChunkName: "components/misc-member-backtesting-card-backtesting" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingCardLinkOut: () => import('../../components/Misc/Member/Backtesting/CardLinkOut.vue' /* webpackChunkName: "components/misc-member-backtesting-card-link-out" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingChartPricePerformance: () => import('../../components/Misc/Member/Backtesting/ChartPricePerformance.vue' /* webpackChunkName: "components/misc-member-backtesting-chart-price-performance" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingContent: () => import('../../components/Misc/Member/Backtesting/Content.vue' /* webpackChunkName: "components/misc-member-backtesting-content" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingIconArrowRight: () => import('../../components/Misc/Member/Backtesting/IconArrowRight.vue' /* webpackChunkName: "components/misc-member-backtesting-icon-arrow-right" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingIconBacktesting: () => import('../../components/Misc/Member/Backtesting/IconBacktesting.vue' /* webpackChunkName: "components/misc-member-backtesting-icon-backtesting" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingIconCalculateResult: () => import('../../components/Misc/Member/Backtesting/IconCalculateResult.vue' /* webpackChunkName: "components/misc-member-backtesting-icon-calculate-result" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingIconEmail: () => import('../../components/Misc/Member/Backtesting/IconEmail.vue' /* webpackChunkName: "components/misc-member-backtesting-icon-email" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingIconEmailComplete: () => import('../../components/Misc/Member/Backtesting/IconEmailComplete.vue' /* webpackChunkName: "components/misc-member-backtesting-icon-email-complete" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingIconEmailError: () => import('../../components/Misc/Member/Backtesting/IconEmailError.vue' /* webpackChunkName: "components/misc-member-backtesting-icon-email-error" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingIconFavourite: () => import('../../components/Misc/Member/Backtesting/IconFavourite.vue' /* webpackChunkName: "components/misc-member-backtesting-icon-favourite" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingIconMyFeed: () => import('../../components/Misc/Member/Backtesting/IconMyFeed.vue' /* webpackChunkName: "components/misc-member-backtesting-icon-my-feed" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingIconNotEnough: () => import('../../components/Misc/Member/Backtesting/IconNotEnough.vue' /* webpackChunkName: "components/misc-member-backtesting-icon-not-enough" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingIconProfile: () => import('../../components/Misc/Member/Backtesting/IconProfile.vue' /* webpackChunkName: "components/misc-member-backtesting-icon-profile" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingIconStreamApp: () => import('../../components/Misc/Member/Backtesting/IconStreamApp.vue' /* webpackChunkName: "components/misc-member-backtesting-icon-stream-app" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingIconStreamPlus: () => import('../../components/Misc/Member/Backtesting/IconStreamPlus.vue' /* webpackChunkName: "components/misc-member-backtesting-icon-stream-plus" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingIconVirtualPortfolio: () => import('../../components/Misc/Member/Backtesting/IconVirtualPortfolio.vue' /* webpackChunkName: "components/misc-member-backtesting-icon-virtual-portfolio" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingIconWarning: () => import('../../components/Misc/Member/Backtesting/IconWarning.vue' /* webpackChunkName: "components/misc-member-backtesting-icon-warning" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingInputEmail: () => import('../../components/Misc/Member/Backtesting/InputEmail.vue' /* webpackChunkName: "components/misc-member-backtesting-input-email" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingInputNumber: () => import('../../components/Misc/Member/Backtesting/InputNumber.vue' /* webpackChunkName: "components/misc-member-backtesting-input-number" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingModalCalculateResult: () => import('../../components/Misc/Member/Backtesting/ModalCalculateResult.vue' /* webpackChunkName: "components/misc-member-backtesting-modal-calculate-result" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingModalEmailResult: () => import('../../components/Misc/Member/Backtesting/ModalEmailResult.vue' /* webpackChunkName: "components/misc-member-backtesting-modal-email-result" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingModalMinValue: () => import('../../components/Misc/Member/Backtesting/ModalMinValue.vue' /* webpackChunkName: "components/misc-member-backtesting-modal-min-value" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingTabs: () => import('../../components/Misc/Member/Backtesting/Tabs.vue' /* webpackChunkName: "components/misc-member-backtesting-tabs" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingTemplateMutualFundInvestmentDetails: () => import('../../components/Misc/Member/Backtesting/TemplateMutualFundInvestmentDetails.vue' /* webpackChunkName: "components/misc-member-backtesting-template-mutual-fund-investment-details" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingTemplateStockETFInvestmentDetails: () => import('../../components/Misc/Member/Backtesting/TemplateStockETFInvestmentDetails.vue' /* webpackChunkName: "components/misc-member-backtesting-template-stock-e-t-f-investment-details" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberBacktestingTemplateStockETFStatistic: () => import('../../components/Misc/Member/Backtesting/TemplateStockETFStatistic.vue' /* webpackChunkName: "components/misc-member-backtesting-template-stock-e-t-f-statistic" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyFeedApprovedContent: () => import('../../components/Misc/Member/MyFeed/ApprovedContent.vue' /* webpackChunkName: "components/misc-member-my-feed-approved-content" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyFeedArticleCard: () => import('../../components/Misc/Member/MyFeed/ArticleCard.vue' /* webpackChunkName: "components/misc-member-my-feed-article-card" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyFeedBenefitCard: () => import('../../components/Misc/Member/MyFeed/BenefitCard.vue' /* webpackChunkName: "components/misc-member-my-feed-benefit-card" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyFeedCardNews: () => import('../../components/Misc/Member/MyFeed/CardNews.vue' /* webpackChunkName: "components/misc-member-my-feed-card-news" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyFeedCustomizeSwiper: () => import('../../components/Misc/Member/MyFeed/CustomizeSwiper.vue' /* webpackChunkName: "components/misc-member-my-feed-customize-swiper" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyFeedFavImg: () => import('../../components/Misc/Member/MyFeed/FavImg.vue' /* webpackChunkName: "components/misc-member-my-feed-fav-img" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyFeedModalXType: () => import('../../components/Misc/Member/MyFeed/ModalXType.vue' /* webpackChunkName: "components/misc-member-my-feed-modal-x-type" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyFeedQuoteImg: () => import('../../components/Misc/Member/MyFeed/QuoteImg.vue' /* webpackChunkName: "components/misc-member-my-feed-quote-img" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyFeedSearchImg: () => import('../../components/Misc/Member/MyFeed/SearchImg.vue' /* webpackChunkName: "components/misc-member-my-feed-search-img" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyFeedStarImg: () => import('../../components/Misc/Member/MyFeed/StarImg.vue' /* webpackChunkName: "components/misc-member-my-feed-star-img" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyFeedUpcomingIpoCard: () => import('../../components/Misc/Member/MyFeed/UpcomingIpoCard.vue' /* webpackChunkName: "components/misc-member-my-feed-upcoming-ipo-card" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyFeedXB: () => import('../../components/Misc/Member/MyFeed/XB.vue' /* webpackChunkName: "components/misc-member-my-feed-x-b" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyFeedXD: () => import('../../components/Misc/Member/MyFeed/XD.vue' /* webpackChunkName: "components/misc-member-my-feed-x-d" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyFeedXE: () => import('../../components/Misc/Member/MyFeed/XE.vue' /* webpackChunkName: "components/misc-member-my-feed-x-e" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyFeedXI: () => import('../../components/Misc/Member/MyFeed/XI.vue' /* webpackChunkName: "components/misc-member-my-feed-x-i" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyFeedXM: () => import('../../components/Misc/Member/MyFeed/XM.vue' /* webpackChunkName: "components/misc-member-my-feed-x-m" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyFeedXN: () => import('../../components/Misc/Member/MyFeed/XN.vue' /* webpackChunkName: "components/misc-member-my-feed-x-n" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyFeedXWRT: () => import('../../components/Misc/Member/MyFeed/XWRT.vue' /* webpackChunkName: "components/misc-member-my-feed-x-w-r-t" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyProfileEditProfile: () => import('../../components/Misc/Member/MyProfile/EditProfile.vue' /* webpackChunkName: "components/misc-member-my-profile-edit-profile" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyProfileIconEmailFill: () => import('../../components/Misc/Member/MyProfile/IconEmailFill.vue' /* webpackChunkName: "components/misc-member-my-profile-icon-email-fill" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyProfileIconManager: () => import('../../components/Misc/Member/MyProfile/IconManager.vue' /* webpackChunkName: "components/misc-member-my-profile-icon-manager" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyProfileIconPersonal: () => import('../../components/Misc/Member/MyProfile/IconPersonal.vue' /* webpackChunkName: "components/misc-member-my-profile-icon-personal" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyProfileIconPhoneFill: () => import('../../components/Misc/Member/MyProfile/IconPhoneFill.vue' /* webpackChunkName: "components/misc-member-my-profile-icon-phone-fill" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberMyProfileImageProfile: () => import('../../components/Misc/Member/MyProfile/ImageProfile.vue' /* webpackChunkName: "components/misc-member-my-profile-image-profile" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioActionTypeText: () => import('../../components/Misc/Member/VirtualPortfolio/ActionTypeText.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-action-type-text" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioButtonAddPortfolio: () => import('../../components/Misc/Member/VirtualPortfolio/ButtonAddPortfolio.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-button-add-portfolio" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioButtonBin: () => import('../../components/Misc/Member/VirtualPortfolio/ButtonBin.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-button-bin" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioButtonGroupAction: () => import('../../components/Misc/Member/VirtualPortfolio/ButtonGroupAction.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-button-group-action" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioButtonUserManual: () => import('../../components/Misc/Member/VirtualPortfolio/ButtonUserManual.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-button-user-manual" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioButtonVirtualPortfolioOrder: () => import('../../components/Misc/Member/VirtualPortfolio/ButtonVirtualPortfolioOrder.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-button-virtual-portfolio-order" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioButtonVirtualPortfolioViewMore: () => import('../../components/Misc/Member/VirtualPortfolio/ButtonVirtualPortfolioViewMore.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-button-virtual-portfolio-view-more" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioChartDonut: () => import('../../components/Misc/Member/VirtualPortfolio/ChartDonut.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-chart-donut" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioError: () => import('../../components/Misc/Member/VirtualPortfolio/Error.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-error" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioIconMyPort: () => import('../../components/Misc/Member/VirtualPortfolio/IconMyPort.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-icon-my-port" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioIconPortfolio: () => import('../../components/Misc/Member/VirtualPortfolio/IconPortfolio.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-icon-portfolio" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioIconPortfolioError: () => import('../../components/Misc/Member/VirtualPortfolio/IconPortfolioError.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-icon-portfolio-error" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioInputNumber: () => import('../../components/Misc/Member/VirtualPortfolio/InputNumber.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-input-number" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioInputSymbol: () => import('../../components/Misc/Member/VirtualPortfolio/InputSymbol.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-input-symbol" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioModalDelete: () => import('../../components/Misc/Member/VirtualPortfolio/ModalDelete.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-modal-delete" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioModalOrderDerivative: () => import('../../components/Misc/Member/VirtualPortfolio/ModalOrderDerivative.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-modal-order-derivative" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioModalOrderMutualFund: () => import('../../components/Misc/Member/VirtualPortfolio/ModalOrderMutualFund.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-modal-order-mutual-fund" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioModalOrderStock: () => import('../../components/Misc/Member/VirtualPortfolio/ModalOrderStock.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-modal-order-stock" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioModalPortfolioName: () => import('../../components/Misc/Member/VirtualPortfolio/ModalPortfolioName.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-modal-portfolio-name" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioModalWarningLimitd: () => import('../../components/Misc/Member/VirtualPortfolio/ModalWarningLimitd.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-modal-warning-limitd" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioNoPortfolio: () => import('../../components/Misc/Member/VirtualPortfolio/NoPortfolio.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-no-portfolio" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioNotation: () => import('../../components/Misc/Member/VirtualPortfolio/Notation.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-notation" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioTabButtonGroup: () => import('../../components/Misc/Member/VirtualPortfolio/TabButtonGroup.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-tab-button-group" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioTableSummary: () => import('../../components/Misc/Member/VirtualPortfolio/TableSummary.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-table-summary" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioTitlePortfolio: () => import('../../components/Misc/Member/VirtualPortfolio/TitlePortfolio.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-title-portfolio" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioValidationState: () => import('../../components/Misc/Member/VirtualPortfolio/ValidationState.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-validation-state" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundAssetsAssetAllocation: () => import('../../components/Misc/MutualFund/Assets/AssetAllocation.vue' /* webpackChunkName: "components/misc-mutual-fund-assets-asset-allocation" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundAssetsTop5Holding: () => import('../../components/Misc/MutualFund/Assets/Top5Holding.vue' /* webpackChunkName: "components/misc-mutual-fund-assets-top5-holding" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundComparisonCardFee: () => import('../../components/Misc/MutualFund/Comparison/CardFee.vue' /* webpackChunkName: "components/misc-mutual-fund-comparison-card-fee" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundComparisonCardInformation: () => import('../../components/Misc/MutualFund/Comparison/CardInformation.vue' /* webpackChunkName: "components/misc-mutual-fund-comparison-card-information" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundComparisonCardInvestmentAsset: () => import('../../components/Misc/MutualFund/Comparison/CardInvestmentAsset.vue' /* webpackChunkName: "components/misc-mutual-fund-comparison-card-investment-asset" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundComparisonCardPerformanceInfo: () => import('../../components/Misc/MutualFund/Comparison/CardPerformanceInfo.vue' /* webpackChunkName: "components/misc-mutual-fund-comparison-card-performance-info" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundComparisonCardRisk: () => import('../../components/Misc/MutualFund/Comparison/CardRisk.vue' /* webpackChunkName: "components/misc-mutual-fund-comparison-card-risk" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundComparisonCardTemplate: () => import('../../components/Misc/MutualFund/Comparison/CardTemplate.vue' /* webpackChunkName: "components/misc-mutual-fund-comparison-card-template" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundComparisonChartPerformance: () => import('../../components/Misc/MutualFund/Comparison/ChartPerformance.vue' /* webpackChunkName: "components/misc-mutual-fund-comparison-chart-performance" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundComparisonChartRisk: () => import('../../components/Misc/MutualFund/Comparison/ChartRisk.vue' /* webpackChunkName: "components/misc-mutual-fund-comparison-chart-risk" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundComparisonDropdownSearch: () => import('../../components/Misc/MutualFund/Comparison/DropdownSearch.vue' /* webpackChunkName: "components/misc-mutual-fund-comparison-dropdown-search" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundCoverPrice: () => import('../../components/Misc/MutualFund/Cover/Price.vue' /* webpackChunkName: "components/misc-mutual-fund-cover-price" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundCoverRiskLevel: () => import('../../components/Misc/MutualFund/Cover/RiskLevel.vue' /* webpackChunkName: "components/misc-mutual-fund-cover-risk-level" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundOverviewChartNAV: () => import('../../components/Misc/MutualFund/Overview/ChartNAV.vue' /* webpackChunkName: "components/misc-mutual-fund-overview-chart-n-a-v" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundOverviewDividendHistory: () => import('../../components/Misc/MutualFund/Overview/DividendHistory.vue' /* webpackChunkName: "components/misc-mutual-fund-overview-dividend-history" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundOverviewInformation: () => import('../../components/Misc/MutualFund/Overview/Information.vue' /* webpackChunkName: "components/misc-mutual-fund-overview-information" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundOverviewModalAIMC: () => import('../../components/Misc/MutualFund/Overview/ModalAIMC.vue' /* webpackChunkName: "components/misc-mutual-fund-overview-modal-a-i-m-c" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundOverviewPerformanceInformation: () => import('../../components/Misc/MutualFund/Overview/PerformanceInformation.vue' /* webpackChunkName: "components/misc-mutual-fund-overview-performance-information" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundOverviewRecommend: () => import('../../components/Misc/MutualFund/Overview/Recommend.vue' /* webpackChunkName: "components/misc-mutual-fund-overview-recommend" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundOverviewThematicItem: () => import('../../components/Misc/MutualFund/Overview/ThematicItem.vue' /* webpackChunkName: "components/misc-mutual-fund-overview-thematic-item" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundOverviewViewChartLock: () => import('../../components/Misc/MutualFund/Overview/ViewChartLock.vue' /* webpackChunkName: "components/misc-mutual-fund-overview-view-chart-lock" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundThematicCover: () => import('../../components/Misc/MutualFund/Thematic/Cover.vue' /* webpackChunkName: "components/misc-mutual-fund-thematic-cover" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundThematicMain: () => import('../../components/Misc/MutualFund/Thematic/Main.vue' /* webpackChunkName: "components/misc-mutual-fund-thematic-main" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundThematicMainContainer: () => import('../../components/Misc/MutualFund/Thematic/MainContainer.vue' /* webpackChunkName: "components/misc-mutual-fund-thematic-main-container" */).then(c => wrapFunctional(c.default || c)),
  MiscMutualFundThematicItem: () => import('../../components/Misc/MutualFund/Thematic/ThematicItem.vue' /* webpackChunkName: "components/misc-mutual-fund-thematic-item" */).then(c => wrapFunctional(c.default || c)),
  MiscNewsAndArticlesArticlesIconArticle: () => import('../../components/Misc/NewsAndArticles/Articles/IconArticle.vue' /* webpackChunkName: "components/misc-news-and-articles-articles-icon-article" */).then(c => wrapFunctional(c.default || c)),
  MiscNewsAndArticlesArticlesIconFacebook: () => import('../../components/Misc/NewsAndArticles/Articles/IconFacebook.vue' /* webpackChunkName: "components/misc-news-and-articles-articles-icon-facebook" */).then(c => wrapFunctional(c.default || c)),
  MiscNewsAndArticlesArticlesIconLine: () => import('../../components/Misc/NewsAndArticles/Articles/IconLine.vue' /* webpackChunkName: "components/misc-news-and-articles-articles-icon-line" */).then(c => wrapFunctional(c.default || c)),
  MiscNewsAndArticlesArticlesIconLink: () => import('../../components/Misc/NewsAndArticles/Articles/IconLink.vue' /* webpackChunkName: "components/misc-news-and-articles-articles-icon-link" */).then(c => wrapFunctional(c.default || c)),
  MiscNewsAndArticlesArticlesIconTW: () => import('../../components/Misc/NewsAndArticles/Articles/IconTW.vue' /* webpackChunkName: "components/misc-news-and-articles-articles-icon-t-w" */).then(c => wrapFunctional(c.default || c)),
  MiscNewsAndArticlesArticlesLastUpdateCard: () => import('../../components/Misc/NewsAndArticles/Articles/LastUpdateCard.vue' /* webpackChunkName: "components/misc-news-and-articles-articles-last-update-card" */).then(c => wrapFunctional(c.default || c)),
  MiscNewsAndArticlesArticlesLastUpdateTopCard: () => import('../../components/Misc/NewsAndArticles/Articles/LastUpdateTopCard.vue' /* webpackChunkName: "components/misc-news-and-articles-articles-last-update-top-card" */).then(c => wrapFunctional(c.default || c)),
  MiscNewsAndArticlesNewsCard: () => import('../../components/Misc/NewsAndArticles/News/Card.vue' /* webpackChunkName: "components/misc-news-and-articles-news-card" */).then(c => wrapFunctional(c.default || c)),
  MiscNewsAndArticlesNewsFeeds: () => import('../../components/Misc/NewsAndArticles/News/Feeds.vue' /* webpackChunkName: "components/misc-news-and-articles-news-feeds" */).then(c => wrapFunctional(c.default || c)),
  MiscNewsAndArticlesPopularCard: () => import('../../components/Misc/NewsAndArticles/Popular/Card.vue' /* webpackChunkName: "components/misc-news-and-articles-popular-card" */).then(c => wrapFunctional(c.default || c)),
  MiscNewsAndArticlesPopularTopCard: () => import('../../components/Misc/NewsAndArticles/Popular/TopCard.vue' /* webpackChunkName: "components/misc-news-and-articles-popular-top-card" */).then(c => wrapFunctional(c.default || c)),
  MiscNewsAndArticlesSharedSocialShare: () => import('../../components/Misc/NewsAndArticles/Shared/SocialShare.vue' /* webpackChunkName: "components/misc-news-and-articles-shared-social-share" */).then(c => wrapFunctional(c.default || c)),
  MiscResearchAnalystResearchCardNews: () => import('../../components/Misc/Research/AnalystResearch/CardNews.vue' /* webpackChunkName: "components/misc-research-analyst-research-card-news" */).then(c => wrapFunctional(c.default || c)),
  MiscResearchAnalystResearchPopularCard: () => import('../../components/Misc/Research/AnalystResearch/PopularCard.vue' /* webpackChunkName: "components/misc-research-analyst-research-popular-card" */).then(c => wrapFunctional(c.default || c)),
  MiscResearchAnalystResearchResult: () => import('../../components/Misc/Research/AnalystResearch/Result.vue' /* webpackChunkName: "components/misc-research-analyst-research-result" */).then(c => wrapFunctional(c.default || c)),
  MiscResearchDetailDisclaimer: () => import('../../components/Misc/Research/Detail/Disclaimer.vue' /* webpackChunkName: "components/misc-research-detail-disclaimer" */).then(c => wrapFunctional(c.default || c)),
  MiscResearchDetailIMGViewer: () => import('../../components/Misc/Research/Detail/IMGViewer.vue' /* webpackChunkName: "components/misc-research-detail-i-m-g-viewer" */).then(c => wrapFunctional(c.default || c)),
  MiscResearchDetailModalRating: () => import('../../components/Misc/Research/Detail/ModalRating.vue' /* webpackChunkName: "components/misc-research-detail-modal-rating" */).then(c => wrapFunctional(c.default || c)),
  MiscResearchDetailPDFViewer: () => import('../../components/Misc/Research/Detail/PDFViewer.vue' /* webpackChunkName: "components/misc-research-detail-p-d-f-viewer" */).then(c => wrapFunctional(c.default || c)),
  MiscResearchDetailShared: () => import('../../components/Misc/Research/Detail/Shared.vue' /* webpackChunkName: "components/misc-research-detail-shared" */).then(c => wrapFunctional(c.default || c)),
  MiscResearchIAAConsensusAccordionTable: () => import('../../components/Misc/Research/IAAConsensus/AccordionTable.vue' /* webpackChunkName: "components/misc-research-i-a-a-consensus-accordion-table" */).then(c => wrapFunctional(c.default || c)),
  MiscResearchIAAConsensusAccordionTableWithSummary: () => import('../../components/Misc/Research/IAAConsensus/AccordionTableWithSummary.vue' /* webpackChunkName: "components/misc-research-i-a-a-consensus-accordion-table-with-summary" */).then(c => wrapFunctional(c.default || c)),
  MiscResearchIAAConsensusDisclaimer: () => import('../../components/Misc/Research/IAAConsensus/Disclaimer.vue' /* webpackChunkName: "components/misc-research-i-a-a-consensus-disclaimer" */).then(c => wrapFunctional(c.default || c)),
  MiscResearchIAAConsensusOriginMeaning: () => import('../../components/Misc/Research/IAAConsensus/OriginMeaning.vue' /* webpackChunkName: "components/misc-research-i-a-a-consensus-origin-meaning" */).then(c => wrapFunctional(c.default || c)),
  MiscResearchIAAConsensusRecommendIcon: () => import('../../components/Misc/Research/IAAConsensus/RecommendIcon.vue' /* webpackChunkName: "components/misc-research-i-a-a-consensus-recommend-icon" */).then(c => wrapFunctional(c.default || c)),
  MiscResearchIAAConsensusRecommendType: () => import('../../components/Misc/Research/IAAConsensus/RecommendType.vue' /* webpackChunkName: "components/misc-research-i-a-a-consensus-recommend-type" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityCompanyDirector: () => import('../../components/Misc/Equity/CompanyInformation/BoardDirector/CompanyDirector.vue' /* webpackChunkName: "components/misc-equity-company-director" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityCompanyInformationMajorShareholderList: () => import('../../components/Misc/Equity/CompanyInformation/MajorShareholder/MajorShareholderList.vue' /* webpackChunkName: "components/misc-equity-company-information-major-shareholder-list" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityCompanyInformationMajorShareholderStats: () => import('../../components/Misc/Equity/CompanyInformation/MajorShareholder/MajorShareholderStats.vue' /* webpackChunkName: "components/misc-equity-company-information-major-shareholder-stats" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityCompanyInformationMajorShareholderStatsShort: () => import('../../components/Misc/Equity/CompanyInformation/MajorShareholder/MajorShareholderStatsShort.vue' /* webpackChunkName: "components/misc-equity-company-information-major-shareholder-stats-short" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityCompanyInformationOverviewCapitalDetail: () => import('../../components/Misc/Equity/CompanyInformation/Overview/CapitalDetail.vue' /* webpackChunkName: "components/misc-equity-company-information-overview-capital-detail" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityCompanyInformationOverviewCommonCapitalAndCommonShares: () => import('../../components/Misc/Equity/CompanyInformation/Overview/CommonCapitalAndCommonShares.vue' /* webpackChunkName: "components/misc-equity-company-information-overview-common-capital-and-common-shares" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityCompanyCover: () => import('../../components/Misc/Equity/CompanyInformation/Overview/CompanyCover.vue' /* webpackChunkName: "components/misc-equity-company-cover" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityCompanyDetails: () => import('../../components/Misc/Equity/CompanyInformation/Overview/CompanyDetails.vue' /* webpackChunkName: "components/misc-equity-company-details" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityCompanySnapshot: () => import('../../components/Misc/Equity/CompanyInformation/Overview/CompanySnapshot.vue' /* webpackChunkName: "components/misc-equity-company-snapshot" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityCompanyInformationOverviewNumberShareDetail: () => import('../../components/Misc/Equity/CompanyInformation/Overview/NumberShareDetail.vue' /* webpackChunkName: "components/misc-equity-company-information-overview-number-share-detail" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityCompanyInformationOverviewReport: () => import('../../components/Misc/Equity/CompanyInformation/Overview/Report.vue' /* webpackChunkName: "components/misc-equity-company-information-overview-report" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOIPOPerformanceFinancial: () => import('../../components/Misc/Equity/IPO/IPOPerformance/Financial.vue' /* webpackChunkName: "components/misc-equity-i-p-o-i-p-o-performance-financial" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOIPOPerformanceFinancialAdvisorStatistics: () => import('../../components/Misc/Equity/IPO/IPOPerformance/FinancialAdvisorStatistics.vue' /* webpackChunkName: "components/misc-equity-i-p-o-i-p-o-performance-financial-advisor-statistics" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOIPOPerformanceHighlight: () => import('../../components/Misc/Equity/IPO/IPOPerformance/Highlight.vue' /* webpackChunkName: "components/misc-equity-i-p-o-i-p-o-performance-highlight" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOIPOPerformanceMarketStatistics: () => import('../../components/Misc/Equity/IPO/IPOPerformance/MarketStatistics.vue' /* webpackChunkName: "components/misc-equity-i-p-o-i-p-o-performance-market-statistics" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOIPOPerformancePricePerformance: () => import('../../components/Misc/Equity/IPO/IPOPerformance/PricePerformance.vue' /* webpackChunkName: "components/misc-equity-i-p-o-i-p-o-performance-price-performance" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOIPOPerformanceProfile: () => import('../../components/Misc/Equity/IPO/IPOPerformance/Profile.vue' /* webpackChunkName: "components/misc-equity-i-p-o-i-p-o-performance-profile" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOIPOPerformanceSearchCard: () => import('../../components/Misc/Equity/IPO/IPOPerformance/SearchCard.vue' /* webpackChunkName: "components/misc-equity-i-p-o-i-p-o-performance-search-card" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOIPOPerformanceTabConntent: () => import('../../components/Misc/Equity/IPO/IPOPerformance/TabConntent.vue' /* webpackChunkName: "components/misc-equity-i-p-o-i-p-o-performance-tab-conntent" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOUpcomingIpoApprovedContent: () => import('../../components/Misc/Equity/IPO/UpcomingIpo/ApprovedContent.vue' /* webpackChunkName: "components/misc-equity-i-p-o-upcoming-ipo-approved-content" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOUpcomingIpoBoxContentCard: () => import('../../components/Misc/Equity/IPO/UpcomingIpo/BoxContentCard.vue' /* webpackChunkName: "components/misc-equity-i-p-o-upcoming-ipo-box-content-card" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOUpcomingIpoCompanyContact: () => import('../../components/Misc/Equity/IPO/UpcomingIpo/CompanyContact.vue' /* webpackChunkName: "components/misc-equity-i-p-o-upcoming-ipo-company-contact" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOUpcomingIpoCompanyInfo: () => import('../../components/Misc/Equity/IPO/UpcomingIpo/CompanyInfo.vue' /* webpackChunkName: "components/misc-equity-i-p-o-upcoming-ipo-company-info" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOUpcomingIpoDatePeriods: () => import('../../components/Misc/Equity/IPO/UpcomingIpo/DatePeriods.vue' /* webpackChunkName: "components/misc-equity-i-p-o-upcoming-ipo-date-periods" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOUpcomingIpoFilingContent: () => import('../../components/Misc/Equity/IPO/UpcomingIpo/FilingContent.vue' /* webpackChunkName: "components/misc-equity-i-p-o-upcoming-ipo-filing-content" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOUpcomingIpoModalIpo: () => import('../../components/Misc/Equity/IPO/UpcomingIpo/ModalIpo.vue' /* webpackChunkName: "components/misc-equity-i-p-o-upcoming-ipo-modal-ipo" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOUpcomingIpoTabConntent: () => import('../../components/Misc/Equity/IPO/UpcomingIpo/TabConntent.vue' /* webpackChunkName: "components/misc-equity-i-p-o-upcoming-ipo-tab-conntent" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOUpcomingIpoCard: () => import('../../components/Misc/Equity/IPO/UpcomingIpo/UpcomingIpoCard.vue' /* webpackChunkName: "components/misc-equity-i-p-o-upcoming-ipo-card" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioAssetAllocationTable: () => import('../../components/Misc/Member/VirtualPortfolio/AssetAllocation/Table.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-asset-allocation-table" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioInvestmentAssetPerformance: () => import('../../components/Misc/Member/VirtualPortfolio/InvestmentAsset/Performance.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-investment-asset-performance" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioInvestmentAssetTablePerformance: () => import('../../components/Misc/Member/VirtualPortfolio/InvestmentAsset/TablePerformance.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-investment-asset-table-performance" */).then(c => wrapFunctional(c.default || c)),
  MiscMemberVirtualPortfolioInvestmentAssetTabs: () => import('../../components/Misc/Member/VirtualPortfolio/InvestmentAsset/Tabs.vue' /* webpackChunkName: "components/misc-member-virtual-portfolio-investment-asset-tabs" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityETFFundDetails: () => import('../../components/Misc/Equity/ETF/FundProfile/Overview/FundDetails.vue' /* webpackChunkName: "components/misc-equity-e-t-f-fund-details" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityETFFundProfileOverviewModalRisk: () => import('../../components/Misc/Equity/ETF/FundProfile/Overview/ModalRisk.vue' /* webpackChunkName: "components/misc-equity-e-t-f-fund-profile-overview-modal-risk" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOUpcomingIpoProductDropdownItemIPO: () => import('../../components/Misc/Equity/IPO/UpcomingIpo/Product/DropdownItemIPO.vue' /* webpackChunkName: "components/misc-equity-i-p-o-upcoming-ipo-product-dropdown-item-i-p-o" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOUpcomingIpoProductIFF: () => import('../../components/Misc/Equity/IPO/UpcomingIpo/Product/IFF.vue' /* webpackChunkName: "components/misc-equity-i-p-o-upcoming-ipo-product-i-f-f" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOUpcomingIpoProductIFT: () => import('../../components/Misc/Equity/IPO/UpcomingIpo/Product/IFT.vue' /* webpackChunkName: "components/misc-equity-i-p-o-upcoming-ipo-product-i-f-t" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOUpcomingIpoProductREIT: () => import('../../components/Misc/Equity/IPO/UpcomingIpo/Product/REIT.vue' /* webpackChunkName: "components/misc-equity-i-p-o-upcoming-ipo-product-r-e-i-t" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOUpcomingIpoProductStockApproved: () => import('../../components/Misc/Equity/IPO/UpcomingIpo/Product/StockApproved.vue' /* webpackChunkName: "components/misc-equity-i-p-o-upcoming-ipo-product-stock-approved" */).then(c => wrapFunctional(c.default || c)),
  MiscEquityIPOUpcomingIpoProductStockEffective: () => import('../../components/Misc/Equity/IPO/UpcomingIpo/Product/StockEffective.vue' /* webpackChunkName: "components/misc-equity-i-p-o-upcoming-ipo-product-stock-effective" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
